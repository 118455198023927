/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// datepicker and date locale
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
// masked input and validation
import MaskedInput, { conformToMask } from 'react-text-mask';
import { patterns, masks } from '../../constants/input';
// styles
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';
// utils
import { formatDate } from '../../utils/date';

class Kazanplova extends Component {
  constructor(props) {
    super(props);

    const currentDate = new Date();
    registerLocale('ru', ru);
    setDefaultLocale('ru');

    this.state = {
      showTimePicker: false,
      isDisabled: true,
      phoneError: false,
      currentDate,
      deliveryDate: currentDate,
      deliveryTime: '11:00',
      phone: '',
      products: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user !== prevState.user) {
      return { user: nextProps.user };
    }
    return null;
  }

  componentDidMount() {
    // load products
    this.props.getManualProducts();
    // check isDisabled initially
    if (this.state.phoneError) {
      const newState = {
        ...newState,
        isDisabled:
          (this.state.phoneError || !this.state.phone) &&
          (!this.state.products || this.state.products.length === 0),
      };
      return this.handleStateUpdate(newState);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      let maskedPhoneNumber = {};
      maskedPhoneNumber = conformToMask(this.props.user.phone, masks.phone, {
        guide: false,
      });
      const newState = {
        phone: maskedPhoneNumber.conformedValue || '',
        isDisabled: !maskedPhoneNumber.conformedValue,
      };
      return this.handleStateUpdate(newState);
    }
  }

  handleStateUpdate = newData => {
    if (newData) {
      this.setState(state => ({
        ...state,
        ...newData,
      }));
    }
  };

  handleDateChange = date => {
    this.setState({
      deliveryDate: date,
    });
  };

  validatePhone = () => {
    if (this.state.phone && !patterns.phone.test(this.state.phone)) {
      this.setState({
        ...this.state,
        phoneError: true,
      });
    } else {
      this.setState({
        ...this.state,
        phoneError: false,
      });
    }
    return true;
  };

  handlePhoneChange = e => {
    const { value } = e.target;
    if (value.replace(/[^0-9]/g, '').length === 11) {
      this.setState({
        phone: value,
        isDisabled: false,
        phoneError: false,
      });
    } else {
      this.setState({
        phone: value,
        isDisabled: true,
        phoneError: false,
      });
    }
  };

  handleTimeChange = time => {
    this.setState({
      deliveryTime: formatDate(time, 'hh:mm'),
      showTimePicker: false,
    });
  };

  toggleTimePicker = () => {
    this.setState(prevState => ({
      showTimePicker: !prevState.showTimePicker,
    }));
  };

  submitForm = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isDisabled: true,
    });
    const body = {};
    const { products, deliveryDate, deliveryTime, phone } = this.state;
    const { manualProducts, sendProductRequest } = this.props;
    const productsLength = manualProducts.length;
    const newProducts = [];
    for (let i = 0; i < productsLength; i++) {
      newProducts[i] = products[i]
        ? products[i]
        : { amount: 0, id: manualProducts[i].id };
    }
    body.products = newProducts;
    body.deliveryTime = `${formatDate(deliveryDate, 'dd-MM-yyyy')} ${deliveryTime}`;
    body.phone = phone;
    sendProductRequest(body);
  };

  changeAmount = (operator, item, index) => {
    const { products } = this.state;
    let amount = products[index] ? products[index].amount : 0;
    if (operator === '+') {
      amount += 1;
    } else {
      amount -= 1;
    }
    products[index] = {
      id: item.id,
      amount: amount < 0 ? 0 : amount,
    };
    if (products && products.length > 0) {
      // const index = products.findIndex(
      //   obj => obj && obj.amount === 0
      // );
      // const inactiveItems = index + 1;
      // const newState = {
      //   isDisabled: inactiveItems >= this.state.products.length,
      // };
      // this.handleStateUpdate({ products, ...newState });
      this.handleStateUpdate({ products });
    }
  };

  renderTextDeclension = (int, i) => {
    switch (true) {
      case i === 0:
        return 'казанов';
      case int === 1 || i === 1:
        return 'казан';
      case int > 20 && i > 1 && i < 5:
        return 'казана';
      case int < 5 && i > 1 && i < 5:
        return 'казана';
      default:
        return 'казанов';
    }
  };

  renderProductsInput = manualProducts => {
    const { products } = this.state;
    const result = [];
    if (manualProducts) {
      manualProducts
        .filter(item => item.active === true)
        .map((item, index) => {
          const amount = products[index] ? products[index].amount : 0;
          result.push(
            <div className="form_row" key={index}>
              <em className="form-input-title">{item.title}</em>
              <div className="amount-input">
                <input
                  id={item.id}
                  name={item.id}
                  readOnly="readonly"
                  type="text"
                  className="ng-pristine ng-untouched ng-valid ng-not-empty"
                  disabled="disabled"
                  value={amount}
                />
                <button
                  type="button"
                  className="amount-input-p"
                  onClick={() => this.changeAmount('+', item, index)}
                >
                  +
                </button>
                <button
                  type="button"
                  className="amount-input-m"
                  onClick={() => this.changeAmount('-', item, index)}
                >
                  −
                </button>
              </div>
              <span className="amount-units">
                {this.renderTextDeclension(
                  amount,
                  amount && amount > 0 ? amount.toString().slice(-1) : amount
                )}
              </span>
            </div>
          );
          return item;
        });
      return result;
    }
    return null;
  };

  renderForm = manualProducts => {
    const {
      currentDate,
      deliveryDate,
      deliveryTime,
      showTimePicker,
      phoneError,
      phone,
      isDisabled,
    } = this.state;

    const { kazanOrderResult } = this.props;

    const inputProps = {};
    inputProps.placeholder = '+7 (___) ___-__-__';
    inputProps.type = 'tel';
    inputProps.name = 'userPhone';
    inputProps.autoComplete = 'off';

    const minTime = currentDate.setHours(11, 0);
    const maxTime = currentDate.setHours(21, 0);

    return (
      <form className="form" name="orderForm">
        <div className="form_row">
          <div className="form_cell">
            {this.renderProductsInput(manualProducts)}
          </div>
          <div className="form_cell">
            <div className="form_row">
              <div className="form_cell cell-m">
                <em className="form-input-title">Дата</em>
                <DatePicker
                  selected={deliveryDate}
                  className="input-date"
                  minDate={currentDate}
                  dateFormat="dd/MM/yyyy"
                  onChange={this.handleDateChange}
                />
              </div>
            </div>
            <div className="form_row">
              <div className="form_cell cell-m">
                <em className="form-input-title">Время</em>
                <div className="select-time">
                  <div
                    className="timelist__titleitem"
                    onClick={this.toggleTimePicker}
                  >
                    {deliveryTime}
                  </div>
                  <div className="timelist" />
                  {showTimePicker && (
                    <DatePicker
                      showTimeSelect
                      inline
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      minTime={minTime}
                      maxTime={maxTime}
                      dateFormat="LLL"
                      onChange={this.handleTimeChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="form_row">
              <div
                className={`form_cell cell-m ${phoneError ? 'is-invalid' : ''}`}
              >
                <em className="form-input-title">Телефон</em>
                <MaskedInput
                  {...inputProps}
                  value={phone}
                  className="form-control"
                  mask={masks.phone}
                  guide={false}
                  onChange={this.handlePhoneChange}
                  onBlur={this.validatePhone}
                />
                <span className="is-invalid-text">Укажите номер телефона</span>
              </div>
            </div>
          </div>
        </div>
        <div className="form_row">
          <div className="form_cell">
            {kazanOrderResult ? (
              <p className="text-success">
                Заказ принят, спасибо!
                <br />
                Сумма: {kazanOrderResult.amount}&nbsp;рублей.
              </p>
            ) : (
              <button
                className="form-submit btn"
                type="submit"
                disabled={isDisabled}
                onClick={e => this.submitForm(e)}
              >
                Отправить заявку
              </button>
            )}
          </div>
        </div>
        {/* <div className="form_row">
          {this.props.kazanOrderResult
            ? `Заказ принят, сумма ${this.props.kazanOrderResult.amount} рублей.`
            : null}
        </div> */}
      </form>
    );
  };

  renderProducts = manualProducts => {
    const result = [];
    if (manualProducts) {
      manualProducts
        .filter(item => item.active === true)
        .map(item => {
          result.push(
            <div className="card" key={item.id}>
              <div className="card-item">
                <div className="card-item_media">
                  <img
                    alt={item.title}
                    className="card-item_media-img"
                    height="260"
                    width="260"
                    src={`/i/products/${item.img}`}
                  />
                </div>
                <div className="card-item_content">
                  <div className="card-item_content-title">{item.title}</div>
                  <div className="card-item_content-text">{item.text}</div>
                  <div className="card-item_content-controls">
                    <span className="card-item_control-price">
                      {item.price}
                      <span className="rub">руб.</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
          return item;
        });
      return result;
    }
    return null;
  };

  render() {
    const { manualProducts } = this.props;
    const title = 'Заказ плова в казане';
    return (
      <div className="lyt-view_ kp">
        <Helmet>
          <title>{`${title} – BARAШEK`}</title>
          <meta content={`${title} – BARAШEK`} name="title" />
          <meta content={title} property="og:title" />
        </Helmet>
        <div className="lyt-hero">
          <div className="lyt-hero-wrap">
            <div className="container">
              <div className="lyt-hero-title">
                Настоящий <br />
                плов в&nbsp;казане <br />
                для вашего праздника
              </div>
              <div className="lyt-hero-subtitle">
                <ins className="icon icon_marker" />
                Доставка по Йошкар-Оле
              </div>
            </div>
          </div>
        </div>

        <div className="kp-delivery">
          <h1 className="h-tac">
            День рождения,&nbsp;корпоратив,&nbsp;встреча друзей?
          </h1>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <img
                  className="kazan-photo"
                  src="/i/layout/kazanplova_delivery.png"
                  alt={title}
                />
              </div>
              <div className="col-sm-12 col-md-8">
                <div className="kazan-icon">
                  <div className="kazan-icon__text">
                    В&nbsp;одном казане&nbsp;3,5&nbsp;кг.
                  </div>
                  <svg
                    className="kazan-icon__inner"
                    height="26px"
                    version="1.1"
                    viewBox="0 0 47 26"
                    width="47px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs />
                    <g
                      fill="#c1b6ad"
                      id="kazan1"
                      transform="translate(-1193.000000, -67.000000)"
                    >
                      <path
                        d="M1239.33734,74.3805292 L1235.29062,74.3805292 C1231.2376,71.7158373 1224.63222,69.9457938 1217.11383,69.8533768 L1217.11383,68.8175355 L1217.42278,68.296406 L1218.21977,68.296406 L1218.21977,68.2835703 C1218.56908,68.2835703 1218.8503,67.9960506 1218.8503,67.6417852 C1218.8503,67.2875197 1218.56908,67 1218.21977,67 L1214.74809,67 C1214.40004,67 1214.11757,67.2875197 1214.11757,67.6417852 C1214.11757,67.9960506 1214.40004,68.2835703 1214.74809,68.2835703 L1214.74809,68.296406 L1215.54634,68.296406 L1215.85278,68.8175355 L1215.85278,69.8533768 C1208.33565,69.9457938 1201.73026,71.7158373 1197.67724,74.3805292 L1193.63053,74.3805292 C1193.28248,74.3805292 1193,74.668049 1193,75.0223144 C1193,75.3765798 1193.28248,75.6640995 1193.63053,75.6640995 L1197.51709,75.6640995 C1198.01394,83.2641193 1203.50078,89.5664494 1210.99521,91.6740719 L1210.99521,92.3582148 C1210.99521,92.7124803 1211.27768,93 1211.62573,93 L1221.34213,93 C1221.69144,93 1221.97266,92.7124803 1221.97266,92.3582148 L1221.97266,91.6740719 C1229.46709,89.5664494 1234.95392,83.2641193 1235.45078,75.6640995 L1239.33734,75.6640995 C1239.68539,75.6640995 1239.96786,75.3765798 1239.96786,75.0223144 C1239.96786,74.668049 1239.68539,74.3805292 1239.33734,74.3805292"
                        id="kazan2"
                      />
                    </g>
                  </svg>
                </div>
                <div className="kp-delivery__text">
                  Закажите казан плова и ваше событие станет еще и
                  гастрономическим праздником, а казан украшением стола. Вкус
                  настоящего плова не оставит равнодушным никого, а некоторые
                  изменят представление о блюде. Одного казана хватит на
                  компанию из 10-15 голодных гостей.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row products">
            <div className="col">
              {/* <h2 className="h-tac">Выберите плов</h2> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card-group">
                      {this.renderProducts(manualProducts)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row kp-how">
            <div className="col">
              <h2 className="h-tac">Как сделать заказ</h2>
              <ul className="kp-how__text">
                <li>
                  Заказ необходимо оформить за день до вашего торжества.
                  Оставьте заявку на сайте, указав телефонный номер, на который
                  вам перезвонит оператор для уточнения деталей.
                </li>
                <li>
                  Исходя из количества участников мероприятия, укажите нужное
                  вам количество казанов.
                </li>
                <li>За казан берется залог 2000 рублей.</li>
                <li>
                  Если вам понравился наш казан, вы можете оставить его себе.
                  Если же нет - мы вернёмся за ним, когда вам будет удобно.
                </li>
              </ul>
            </div>
          </div>

          <div className="row kp-order">
            <div className="col">
              <h2 className="h-tac">Отправьте заявку</h2>
              <h4 className="content-subtitle">
                Укажите количество казанов, которое вы&nbsp;желаете заказать,{' '}
                <br />
                дату мероприятия и&nbsp;телефон, чтобы мы&nbsp;вам перезвонили
                <br />
                и&nbsp;обсудили все детали заказа
              </h4>
              {this.renderForm(manualProducts)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.rootReducer.profileReducer,
  kazanOrderResult: state.rootReducer.productsReducer.kazanOrderResult,
  manualProducts: state.rootReducer.productsReducer.manualProducts,
});
const mapDispatchToProps = dispatch => ({
  getManualProducts: () => {
    dispatch({ type: 'GET_MANUAL_PRODUCTS' });
  },
  sendProductRequest: body => {
    dispatch({ type: 'ORDER_KAZAN', payload: body });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Kazanplova);
