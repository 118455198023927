/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  show: PropTypes.bool,
};
const defaultProps = {
  show: true,
};

const AppLoader = ({ show }) => {
  if (show) {
    return (
      <div className="loader">
        <div className="loader-container">
          <div className="loader-icon"></div>
        </div>
        <div className="load-text">Загрузка...</div>
      </div>
    );
  }

  return null;
};

AppLoader.propTypes = propTypes;
AppLoader.defaultProps = defaultProps;

export default AppLoader;
