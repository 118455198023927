import PropTypes from 'prop-types';

export const Notifications = PropTypes.array;

export const User = {
  streets: PropTypes.array,
  addresses: PropTypes.array,
  error: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
};

export const Basket = {
  sum: PropTypes.number,
  amount: PropTypes.number,
  products: PropTypes.shape({}),
};

export const Settings = {
  show_blocking_popup: PropTypes.bool,
  enable_iiko: PropTypes.bool,
  shipment_cost: PropTypes.number,
  shipment_threshold: PropTypes.number,
  enable_sms_order_alert: PropTypes.bool,
  promo_requests_count: PropTypes.number,
  work_start_time: PropTypes.string,
  work_finish_time: PropTypes.string,
  version: PropTypes.number,
  current_time: PropTypes.string,
};

export default {
  User,
  Basket,
  Settings,
};
