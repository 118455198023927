/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react';

const Context = createContext();

const Provider = props => {
  const initialState = {
    isLoading: true,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (
      props.value.user &&
      props.value.settings &&
      props.value.basket &&
      props.value.products
    ) {
      setState({
        ...state,
        isLoading: false,
        user: props.value.user,
        settings: props.value.settings,
        basket: props.value.basket,
        products: props.value.products,
      });
    } else {
      setState({
        ...state,
        isLoading: true,
      });
    }
  }, [
    props.value.basket,
    props.value.settings,
    props.value.user,
    props.value.products,
  ]);

  return (
    <Context.Provider
      value={{
        ...state,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

const { Consumer } = Context;

export { Provider, Consumer, Context };
