export const requiredFields = {
  name: {
    value: 'Ваше имя',
    location: ['name'],
    errorLocation: ['nameError'],
  },
  phone: {
    value: 'номер телефона',
    location: ['phone'],
    errorLocation: ['phoneError'],
  },
  address_street: {
    value: 'адрес доставки',
    location: ['newAddress', 'address_street', 'value'],
    errorLocation: ['address_streetError'],
  },
  address: {
    value: 'номер дома',
    location: ['newAddress', 'address'],
    errorLocation: ['addressError'],
  },
};

export const requiredFieldsSelf = {
  name: {
    value: 'Ваше имя',
    location: ['name'],
    errorLocation: ['nameError'],
  },
  phone: {
    value: 'номер телефона',
    location: ['phone'],
    errorLocation: ['phoneError'],
  },
};
