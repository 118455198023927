import React from 'react';
import { Helmet } from 'react-helmet';

const Contacts = () => {
  const title = 'Контактная информация';
  return (
    <div className="container contacts">
      <Helmet>
        <title>{`${title} – BARAШEK`}</title>
        <meta content={`${title} – BARAШEK`} name="title" />
        <meta content={title} property="og:title" />
      </Helmet>

      <h1 className="h-tac">Контакты</h1>
      <div className="row">
        <div className="col">
          <h3 className="content-title">Адреса и телефоны</h3>
          <ul className="content-list">
            <li>
              <strong className="content-subtitle">
                Поддержка пользователей:
              </strong>{' '}
              +7&nbsp; (8362)&nbsp;36&nbsp;99&nbsp;99
            </li>
            <li>
              <strong className="content-subtitle">
                По всем вопросам пишите:
              </strong>
              info@barashek.ru
            </li>
          </ul>
        </div>
        <div className="col">
          <h3 className="content-title">Реквизиты</h3>
          <p className="content-para">
            Общество с ограниченной ответственностью «Вкусные технологии»
          </p>
          <p className="content-para">
            Юридический адрес: 424038, Республика Марий Эл, г. Йошкар-Ола, ул.
            Кирова, д. 3, корпус А
          </p>
          <p className="content-para">
            Фактический адрес: 424033, Республика Марий Эл, г. Йошкар-Ола, б-р
            Чавайна, д 23А, 2 этаж
          </p>
          <p className="content-para">
            ИНН 1215210507, КПП 121501001 ОГРН 1161215055946
          </p>
          <p className="content-para">
            Р/с 40702810711090005244&nbsp;в Филиале №&nbsp;6318&nbsp;ВТБ
            24&nbsp;(ПАО), г. Самара К/с 30101810700000000955&nbsp;БИК&nbsp;043602955
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
