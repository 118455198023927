/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Fragment, Component } from 'react';
import './styles.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as actions from '../../actions';
import ShippingCard from '../../components/shippingCard';
import RenderBuyInfo from '../../components/shippingCard/renderBuyInfo';
import PayanywayModal from '../../components/payanywayModal';
import SmsConfirmModal from '../../components/smsConfirmModal';
import {
  requiredFields,
  requiredFieldsSelf,
} from '../../components/shippingCard/shipmentUtils';
import { isEmpty } from '../../utils/find';
import { storageAvailable } from '../../utils/storageCheck';

class Basket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSending: false,
      isShowPromo: !isEmpty(this.props.promo),
      promoCodeName: !isEmpty(this.props.promo) ? this.props.promo.promocode : '',
      errorText: '',
      formErrors: {},
      tablewareCount: parseInt(storageAvailable('localStorage') && localStorage.getItem('tablewareCount') || 1)
    };
  }

  componentDidMount() {
    // check promo
    if (
      this.props.promo &&
      isEmpty(this.props.promo.products) && // 2do: change 'products' to 'isFixedDiscount'
      this.state.promoCodeName &&
      this.state.promoCodeName !== '' &&
      this.props.basket &&
      this.props.basket.sum > 0
    ) {
      this.handleCheckPromo();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // recheck promo
    if (
      this.props.promo &&
      !isEmpty(this.props.promo) &&
      isEmpty(this.props.promo.products) && // 2do: change 'products' to 'isFixedDiscount'
      // this.props.promo === prevProps.promo &&
      this.state.promoCodeName !== '' &&
      this.props.basket &&
      this.props.basket.sum !== prevProps.basket.sum
      // || this.state.addressId !== prevState.addressId
    ) {
      this.handleCheckPromo();
    }

    // clear & hide promo code
    if (
      this.props.promo
      && isEmpty(this.props.promo)
      && this.promoCodeName === prevState.promoCodeName
    ) {
      this.setState({
        isShowPromo: false,
        promoCodeName: '',
      });
      this.handleCheckPromo(null, true);
    }
  }

  componentWillUnmount() {
    this.clearNotification();
  }

  handleParentUpdate = updates => {
    if (updates) {
      this.setState(state => ({
        ...state,
        ...updates,
      }));
    }
  };

  toggleIsSending = value => {
    this.setState({
      isSending: value,
    });
  };

  handlePromo = e => {
    e.preventDefault();
    const promoFixed = e.target.value.replace(/[^0-9a-zA-Z]/g, '');
    this.setState({
      promoCodeName: promoFixed,
    });
  };

  handleCheckPromo = (e, isClear = false) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const data = {};
    data.products = [];
    data.promoCode = '';
    // fetch promo code
    if (!isClear) {
      this.props.basket.products.forEach(value => {
        data.products.push(value);
      });
      data.promoCode = this.state.promoCodeName;
      data.isSelfService = this.state.addressId === 'self';
    }
    // show/hide promo code input
    this.setState({
      isShowPromo: data.promoCode !== '',
    });
    // server-check promo code (even empty)
    this.props.checkPromo(data);
  };

  applyBonus = data => {
    const bonusValue =
      data <= this.props.user.bonus_balance
        ? data
        : this.props.user.bonus_balance;
    this.props.applyBonus(bonusValue);
  };

  sendOrder = data => {
    this.props.makeOrder(data);

    const orderBlockTimer = () =>
      new Promise(resolve => {
        setTimeout(() => resolve(this.toggleIsSending(false)), 10000);
      });

    (async () => {
      await orderBlockTimer();
    })();
  };

  clearNotification = data => {
    this.props.clearNotification(data);
  };

  sendNotification = data => {
    this.props.sendNotification(data);
  };

  savePersonalData = data => {
    this.props.savePersonalData(data);
  };

  // Check required fields
  checkRequired = requiredData => {
    let message = '';

    const getDescendantProp = (obj, desc) => {
      const arr = desc.split('.');
      // eslint-disable-next-line no-cond-assign
      while (arr.length && (obj = obj[arr.shift()]));
      return obj;
    };

    const errorsList = Object.keys(requiredData)
      .filter(key => {
        const data = requiredData[key].location
          .join('.')
          .replace(/(.*)\.?/, matched => matched);
        const dataError = requiredData[key].errorLocation
          .join('.')
          .replace(/(.*)\.?/, matched => matched);
        const stateVal = getDescendantProp(this.state, data);
        const stateValError = getDescendantProp(this.state, dataError);
        return (
          stateValError === true ||
          typeof stateVal === 'undefined' ||
          stateVal === '' ||
          stateVal === 'undefined'
        );
      })
      .reduce((obj, key) => {
        obj[`${key}Error`] = true;
        return obj;
      }, {});

    if (Object.keys(errorsList).length > 0) {
      const errorsMessage = 'Укажите, пожалуйста, ';
      const errorsData = [];
      Object.keys(errorsList).forEach(item => {
        errorsData.push(requiredData[item.replace(/Error/, '')].value);
      });
      message = `${errorsMessage} ${errorsData
        .join(', ')
        .replace(/, ([^,]*)$/, ' и $1')}`;
    }

    this.setState({
      // ...errorsList,
      formErrors: errorsList,
      errorText: message,
    });
  };

  // submit shipment form
  handleSubmitData = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // set isSending
    this.toggleIsSending(true);

    const { user } = this.props;
    const address = {};

    const aCityStreet =
      this.state.newAddress.address_street &&
      this.state.newAddress.address_street.value
        ? this.state.newAddress.address_street.value.split(',')
        : [];
    address.address_city = aCityStreet ? aCityStreet[0] : '';
    address.address_street = aCityStreet
      ? aCityStreet
          .slice(1)
          .join()
          .trim()
      : '';
    address.address = this.state.newAddress.address || null;
    address.address_other = this.state.newAddress.address_other || null;
    address.delivery_comment = this.state.newAddress.delivery_comment || null;
    address.name = this.state.newAddress.name || null;

    if (this.state.isSaveNewAddress) {
      user.addresses.push(address);
    } else if (this.state.addressId !== 'newAddress' && this.state.addressId !== 'self') {
      user.addresses[this.state.addressId] = address;
    }

    if (this.state.isLoggedIn && this.props.user.account_type !== 'operator') {
      this.props.savePersonalData({ user });
    }

    const data = {};

    data.addressId = this.state.addressId || '';

    data.isSelfService = this.state.addressId === 'self';

    data.name = this.state.name || '';

    data.phone = this.state.phone || '';

    data.paymentType = this.state.paymentId;

    data.products = [];
    this.props.basket.products.forEach(value => {
      data.products.push(value);
    });

    data.tablewareCount = this.state.tablewareCount;

    if (this.state.shipTimeId === 1) {
      data.deliveryTime = this.state.deliveryTime;
    }

    data.promoCode = this.props.promo ? this.props.promo.promocode : '';

    data.addressCity = address.address_city || '';
    data.addressStreet = address.address_street || '';
    data.addressHome = address.address || '';
    data.addressOther = address.address_other || '';
    data.deliveryComment = address.delivery_comment || '';

    if (this.state.bonusesToUse) {
      data.bonusesToUse =
        this.state.bonusesToUse <= this.props.user.bonus_balance
          ? this.state.bonusesToUse
          : this.props.user.bonus_balance;
    }

    this.clearNotification('make_order_error');
    const fields = this.state.addressId === 'self' ? requiredFieldsSelf : requiredFields;
    this.checkRequired(fields);

    let hasValidAddress = false;
    switch (this.state.addressId) {
      case 'self':
        hasValidAddress = true;
        break;
      default:
        hasValidAddress =
          data.addressCity !== '' &&
          data.addressStreet !== '' &&
          data.addressHome !== '';
    }

    if (
      !this.state.nameError &&
      data.name !== '' &&
      !this.state.phoneError &&
      data.phone !== '' &&
      !this.state.addressError &&
      !this.state.address_streetError &&
      hasValidAddress
      // && props.isSending
    ) {
      this.clearNotification('make_order_error');
      this.sendOrder(data);
      if (window.ga) {
        window.ga('send', 'event', 'basket', 'submit'); // uncomment
      }
      if(storageAvailable('localStorage')) {
        localStorage.removeItem('tablewareCount');
      }
    } else {
      this.toggleIsSending(false);
      this.checkRequired(fields);
    }
  };

  addTableware = () => {
    var personCount = 0;
    this.props.basket.products.forEach(value => {
      personCount += value.amount;
    });
    if (this.state.tablewareCount < personCount) {
      var newCount = this.state.tablewareCount + 1;
      this.setState({
        tablewareCount: newCount
      });
      if (storageAvailable('localStorage')) {
        localStorage.setItem('tablewareCount', newCount);
      }
    }
  };

  subTableware = () => {
    if (this.state.tablewareCount > 0) {
      var newCount = this.state.tablewareCount - 1;
      this.setState({
        tablewareCount: newCount
      });
      if (storageAvailable('localStorage')) {
        localStorage.setItem('tablewareCount', newCount);
      }
    }
  };

  renderEmptyBasket = () => (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="orderform">
            <h3 className="h-subtitle">Корзина</h3>
            <div>
              <h3 className="content-subtitle">В корзине пока пусто </h3>
              <p>
                Выберите что-нибудь <Link to="/">в меню</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  renderProducts = () => {
    const items = [];
    this.props.basket.products.forEach((value, key) => {
      items.push(
        <div className="orderlist_row" key={key}>
          <div className="orderlist_cell is-description">
            <img alt="" className="orderlist_item-img" src={value.img} />
            <h4 className="orderlist_item-title">
              {value.title}
              {value.portion_name && <em className="orderlist_item-subtitle">{value.portion_name} порция</em>}
            </h4>
            <p
              className="orderlist_item-text"
              dangerouslySetInnerHTML={{ __html: value.text }}
            ></p>
          </div>
          <div className="orderlist_cell is-amount">
            <label className="amount-input">
              <input
                name="item.id"
                value={value.amount}
                readOnly="readonly"
                type="text"
                disabled="disabled"
              />
              <span
                className="amount-input-p"
                onClick={() => this.props.productsActions.addToBasket(value)}
              >
                +
              </span>
              <span
                className="amount-input-m"
                onClick={() => this.props.productsActions.subFromBasket(value)}
              >
                −
              </span>
            </label>
          </div>
          <div className="orderlist_cell is-price">
            <span className="orderlist_item-price">
              {value.price * value.amount}
              <span className="rub">руб.</span>
              <span
                className="btn-remove"
                onClick={() => this.props.productsActions.removeFromBasket(value)}
              >
                ×
              </span>
            </span>
          </div>
        </div>
      );
    });
    items.push(
        <div className="orderlist_row">
          <div className="orderlist_cell is-description">
            <img alt="" className="orderlist_item-img" src="/i/products/pribory_360.png" />
            <h4 className="orderlist_item-title">
              Набор приборов
            </h4>
            <p
              className="orderlist_item-text"
            ></p>
          </div>
          <div className="orderlist_cell is-amount">
            <label className="amount-input">
              <input
                name="item.id"
                value={this.state.tablewareCount}
                readOnly="readonly"
                type="text"
                disabled="disabled"
              />
              <span
                className="amount-input-p"
                onClick={() => this.addTableware()}
              >
                +
              </span>
              <span
                className="amount-input-m"
                onClick={() => this.subTableware()}
              >
                −
              </span>
            </label>
          </div>
          <div className="orderlist_cell is-price">
            <span className="orderlist_item-price">
            </span>
          </div>
        </div>
      );
    return items;
  };

  renderBasket = () => {
    const { user, basket, settings, promo, bonus } = this.props;

    let sum =
      promo && !promo.promo_code_invalid && promo.discount_size
        ? basket.sum - promo.discount_size
        : basket.sum;
    if (sum < 0) {
      sum = 0;
    }

    let diff = settings.shipment_threshold - sum;
    if (promo && promo.free_shipment || this.state.addressId === 'self') {
      diff = 0;
    }
    sum = diff > 0 ? Math.floor(sum + settings.shipment_cost) : Math.floor(sum);
    // const isError = promo ? promo.promo_code_invalid : false;
    sum = bonus && bonus > 0 ? sum - bonus : sum;
    if (sum < 0) {
      sum = 0;
    }
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="h-subtitle">Корзина</h3>
            <div className="orderlist">
              {this.renderProducts()}
              {diff > 0 && (
                <div className="orderlist_row animated fadeIn">
                  <div className="info">
                    Для бесплатной доставки не хватает блюд на {diff}
                    <span className="rub">руб.</span>&nbsp;&nbsp;
                    <Link to="/" title="Выбрать меню">
                      В меню
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <RenderBuyInfo
          settings={this.props.settings}
          basket={this.props.basket}
          bonus={this.props.bonus}
          promo={this.props.promo}
          addressId={this.state.addressId}
        />
      </div>
    );
  };

  renderShippingCard = () => (
    <ShippingCard
      // notifications
      clearNotification={this.clearNotification}
      sendNotification={this.sendNotification}
      savePersonalData={this.savePersonalData}
      // bonuses
      applyBonus={this.applyBonus}
      bonus={this.props.bonus}
      // promo code
      promo={this.props.promo}
      isShowPromo={this.state.isShowPromo}
      promoCodeName={this.state.promoCodeName}
      handlePromo={this.handlePromo}
      handleCheckPromo={this.handleCheckPromo}
      // form errors
      formErrors={this.state.formErrors}
      errorText={this.state.errorText}
      // form submit related
      handleSubmitData={this.handleSubmitData}
      sendOrder={this.sendOrder}
      toggleIsSending={this.toggleIsSending}
      // other
      handleParentUpdate={this.handleParentUpdate}
      fetchTime={this.props.fetchTime}
      isNoop={this.state.isNoop}
      addressId={this.state.addressId}
    />
  );

  render() {
    const {
      basket,
      user,
      payanywayModal,
      togglePayanywayModal,
      isSmsConfirmModalShow,
      toggleSmsConfirmModal,
      verifyCode,
      resendCode,
      payanywayResult,
    } = this.props;
    const title = 'Ваша корзина';
    return (
      <Fragment>
        <Helmet>
          <title>{`${title} – BARAШEK`}</title>
          <meta content={`${title} – BARAШEK`} name="title" />
          <meta content={title} property="og:title" />
        </Helmet>
        {payanywayModal && payanywayModal.isShow && (
          <PayanywayModal
            handleClose={togglePayanywayModal}
            payanywayResult={payanywayResult}
            iframeUrl={payanywayModal.iframeUrl}
          />
        )}
        {isSmsConfirmModalShow && isSmsConfirmModalShow.isShow && (
          <SmsConfirmModal
            handleClose={toggleSmsConfirmModal}
            verifyCode={verifyCode}
            resendCode={resendCode}
            displayPhone={isSmsConfirmModalShow.displayPhone}
            orderId={isSmsConfirmModalShow.orderId}
            isError={isSmsConfirmModalShow.isError}
            updateCode={isSmsConfirmModalShow.updateCode}
          />
        )}
        {basket && basket.products && basket.products.size > 0 && this.props.products ? (
          <>
            {this.renderBasket()}
            {this.renderShippingCard()}
          </>
        ) : (
          this.renderEmptyBasket()
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: state.rootReducer.productsReducer.products,
  basket: state.rootReducer.basketReducer.basket,
  promo: state.rootReducer.basketReducer.promo || JSON.parse(localStorage.getItem('promo')),
  bonus: state.rootReducer.basketReducer.bonus || '',
  payanywayModal: state.rootReducer.paymentReducer.payanywayModal,
  isSmsConfirmModalShow: state.rootReducer.paymentReducer.isSmsConfirmModalShow,
  user: state.rootReducer.profileReducer,
  settings: state.rootReducer.settingsReducer.settings || {},
  notifications: state.rootReducer.notificationsReducer.notifications || {},
});
const mapDispatchToProps = dispatch => ({
  productsActions: bindActionCreators(actions, dispatch),
  savePersonalData: data => {
    dispatch({ type: 'SEND_PERSONAL_DATA', payload: data });
  },
  fetchTime: () => {
    dispatch({ type: 'FETCH_TIME' });
  },
  makeOrder: data => {
    dispatch({ type: 'MAKE_ORDER', payload: data });
  },
  togglePayanywayModal: data => {
    dispatch({ type: 'TOGGLE_PAYANYWAY_MODAL', payload: data });
  },
  toggleSmsConfirmModal: data => {
    dispatch({ type: 'TOGGLE_SMS_CONFIRM_MODAL', payload: data });
  },
  verifyCode: (orderId, code) => {
    dispatch({ type: 'VERIFY_CODE', payload: { orderId, code } });
  },
  resendCode: orderId => {
    dispatch({ type: 'RESEND_CODE', payload: { orderId } });
  },
  payanywayResult: isOk => {
    dispatch({ type: 'PAYANYWAY_RESULT', payload: isOk });
  },
  checkPromo: promo => {
    dispatch({ type: 'CHECK_PROMO', payload: promo });
  },
  applyBonus: data => {
    dispatch({ type: 'APPLY_BONUS', payload: data });
  },
  sendNotification: data => {
    dispatch({ type: 'SEND_NOTIFICATION', payload: data });
  },
  clearNotification: id => {
    dispatch({ type: 'CLEAR_NOTIFICATION', payload: id });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
