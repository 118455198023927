/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
// import { combineReducers } from 'redux';
import * as actions from '../actions';
import { storageAvailable } from '../utils/storageCheck';

function products(state = {}, action) {
  switch (action.type) {
    case actions.RECEIVE_PRODUCTS:
      return action.products;
    default:
      return state;
  }
}

function validatedBasket(productsBasket, productsOriginal) {
  const aProducts = productsOriginal;
  const stateItems = productsBasket;
  const validItems = new Map();
  let validSum = 0;
  let validAmount = 0;

  if (stateItems && stateItems.size) {
    stateItems.forEach(basketItem => {
      aProducts.forEach(item => {
        item.categories.forEach(subitem => {
          const product = subitem.products.find(x => x.id === basketItem.id);
          if (product) {
            // update sum
            validSum += basketItem.amount * product.price;
            // update amount
            validAmount += basketItem.amount;
            // add valid product
            const validProduct = {
              ...basketItem,
              ...product,
              xxx: basketItem.price,
            };
            validItems.set(basketItem.id, validProduct);
          }
        });
      });
    });
  }
  return {
    sum: validSum,
    amount: validAmount,
    products: validItems,
  };
}

function addToBasket(state, action) {
  let product = {};
  const newBasket =
    state.basket && Object.keys(state.basket).length > 0
      ? { ...state.basket }
      : { sum: 0, amount: 0, products: new Map() };
  // if product in basket increase it amount else add to basket
  if (
    state.basket &&
    state.basket.products &&
    state.basket.products.has(action.payload.id)
  ) {
    product = {
      ...state.basket.products.get(action.payload.id),
      amount: state.basket.products.get(action.payload.id).amount + 1,
    };
  } else {
    product = { ...action.payload, amount: 1 };
  }
  newBasket.products.set(action.payload.id, product);
  return {
    ...newBasket,
    sum: newBasket.sum + product.price,
    amount: newBasket.amount + 1,
  };
}

function subFromBasket(state, action) {
  if (
    !(state.basket.products && state.basket.products.has(action.payload.id))
  ) {
    return state;
  }
  const prevValue = state.basket.products.get(action.payload.id);
  const newBasket = { ...state.basket };
  if (prevValue.amount === 1) {
    newBasket.products.delete(action.payload.id);
  } else {
    prevValue.amount -= 1;
    newBasket.products.set(action.payload.id, prevValue);
  }
  newBasket.sum -= action.payload.price;
  newBasket.amount -= 1;
  return newBasket;
}

function removeFromBasket(state, action) {
  if (!(state.basket && state.basket.products.has(action.payload.id))) {
    return state;
  }
  const prevValue = state.basket.products.get(action.payload.id);
  const newBasket = { ...state.basket };
  newBasket.products.delete(action.payload.id);
  newBasket.sum -= prevValue.amount * action.payload.price;
  newBasket.amount -= prevValue.amount;
  return newBasket;
}

function profile(state = {}, action) {
  if (action.payload) {
    if (!action.payload.errors) {
      action.payload.errors = [];
    }
  }
  return action.payload;
}

function settingsReducer(state = {}, action) {
  switch (action.type) {
    case actions.RECEIVE_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case actions.RECEIVE_TIME:
      return {
        ...state,
        settings: {
          ...state.settings,
          current_time: action.payload.current_time,
        },
      };
    default:
      return state;
  }
}

function notificationsReducer(state = {}, action) {
  switch (action.type) {
    case actions.SEND_NOTIFICATION:
      let newNotifications = state.notifications || [];
      newNotifications.push(action.payload);
      return { ...state, notifications: newNotifications };
    case actions.CLEAR_NOTIFICATION:
      const id = action.payload;
      if (state.notifications) {
        if (id) {
          const index = state.notifications.findIndex(
            obj => obj.data.message_id === id
          );
          newNotifications = [
            ...state.notifications.slice(0, index),
            ...state.notifications.slice(index + 1),
          ];
        } else {
          newNotifications = [];
        }
      }
      return { ...state, notifications: newNotifications || [] };
    default:
      return state;
  }
}

function productsReducer(state = {}, action) {
  switch (action.type) {
    case actions.RECEIVE_PRODUCTS:
      return { ...state, ...products(state, action) };
    case actions.RECEIVE_MANUAL_PRODUCTS:
      return { ...state, manualProducts: action.products.products };
    case actions.RECEIVE_KAZAN_ORDER_RESULT:
      return { ...state, kazanOrderResult: action.payload };
    default:
      return state;
  }
}

function profileReducer(state = {}, action) {
  switch (action.type) {
    case actions.CURRENT_USER:
      return { ...state, ...action.payload };
    case actions.SHOW_PAYMENT_ERROR:
      return { ...state, paymentError: true };
    case actions.RECEIVE_ADDRESS:
      return { ...state, streets: action.payload };
    case actions.SAVE_PERSONAL_DATA:
      return { ...state, ...profile(state, action), isSaved: true };
    case actions.SAVE_ADDRESSES:
      return { ...state, ...profile(state, action) };
    case actions.TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        isLogInModalOpen: action.payload,
        error: false,
        errorText: '',
      };
    case actions.FORGOT_PASSWORD:
      return { ...state, isLogInModalOpen: false, error: false };
    case actions.SIGN_IN:
      return { ...state, ...action.payload, isLogInModalOpen: false };
    case actions.LOG_IN_ERROR:
      return {
        ...state,
        error: action.payload.isError || false,
        errorText: action.payload.errors ? action.payload.errors : '',
      };
    case actions.SIGN_UP:
      return { ...state, ...action.payload, isLogInModalOpen: false };
    case actions.SIGN_OUT:
      return {};
    default:
      return state;
  }
}

function basketReducer(state = {}, action) {
  switch (action.type) {
    // add item to basket
    case actions.ADD_TO_BASKET:
      const updatedBasket_add = addToBasket(state, action);

      // set local storage basket
      const { sum, amount } = updatedBasket_add;
      const products = JSON.stringify([
        ...updatedBasket_add.products.entries(),
      ]);
      if (storageAvailable('localStorage')) {
        const sBasket = JSON.stringify({ sum, amount, products });
        localStorage.setItem('basket', sBasket);

        // set basket in state
        const oLSBasket = JSON.parse(localStorage.getItem('basket'));
        const oProducts = JSON.parse(products).reduce(
          (oLSBasket, [key, val]) => oLSBasket.set(key, val),
          new Map()
        );
        const oBasket = {
          sum: oLSBasket.sum,
          amount: oLSBasket.amount,
          products: oProducts,
        };
      }

      return { ...state, basket: updatedBasket_add };

    case actions.SUB_FROM_BASKET:
      const updatedBasket_sub = subFromBasket(state, action);

      // set local storage basket
      const sBasket_sub = JSON.stringify({
        sum: updatedBasket_sub.sum,
        amount: updatedBasket_sub.amount,
        products: JSON.stringify([...updatedBasket_sub.products.entries()]),
      });
      if (storageAvailable('localStorage')) {
        localStorage.setItem('basket', sBasket_sub);
      }

      // remove local storage basket if state.basket is empty
      if (updatedBasket_sub.amount === 0 && storageAvailable('localStorage')) {
        localStorage.removeItem('basket');
        localStorage.removeItem('promo');
      }

      const updatedBonus_sub = updatedBasket_sub.amount === 0 ? 0 : state.bonus;

      // set basket in state
      return { ...state, basket: updatedBasket_sub, bonus: updatedBonus_sub };

    case actions.REMOVE_FROM_BASKET:
      const updatedBasket_rem = removeFromBasket(state, action);

      // set local storage basket
      const sBasket_rem = JSON.stringify({
        sum: updatedBasket_rem.sum,
        amount: updatedBasket_rem.amount,
        products: JSON.stringify([...updatedBasket_rem.products.entries()]),
      });
      if (storageAvailable('localStorage')) {
        localStorage.setItem('basket', sBasket_rem);
      }

      // remove local storage basket if state.basket is empty
      if (updatedBasket_rem.amount === 0 && storageAvailable('localStorage')) {
        localStorage.removeItem('basket');
        localStorage.removeItem('promo');
      }

      const updatedBonus = updatedBasket_rem.amount === 0 ? 0 : state.bonus;

      // set basket in state
      return { ...state, basket: updatedBasket_rem, bonus: updatedBonus };

    case actions.CLEAR_BASKET:
      // remove basket from local storage
      if (storageAvailable('localStorage')) {
        localStorage.removeItem('basket');
        localStorage.removeItem('promo');
      }

      // empty basket in state
      return { ...state, basket: {}, promo: {}, bonus: 0 };

    case actions.RECEIVE_PROMO:
      if (action.payload !== undefined && storageAvailable('localStorage')) {
        const sPromo = JSON.stringify(action.payload);
        localStorage.setItem('promo', sPromo);
      } else {
        localStorage.removeItem('promo');
      }
      return { ...state, promo: action.payload };

    case actions.APPLY_BONUS:
      return { ...state, bonus: action.payload };

    case actions.SIGN_OUT:
      return { ...state, bonus: '' };

    default:
      // load basket from local storage if found
      // else load default state
      if (storageAvailable('localStorage')) {
        const LS_Basket = localStorage.getItem('basket');
        if (LS_Basket && LS_Basket.amount !== 0) {
          const oLSBasketDefault = JSON.parse(LS_Basket);
          const oProducts = JSON.parse(oLSBasketDefault.products).reduce(
            (oLSBasketDefault, [key, val]) => oLSBasketDefault.set(key, val),
            new Map()
          );
          let updatedBasket = {};
          if (action.productsData && action.productsData.products) {
            // validate ls basket
            updatedBasket = validatedBasket(oProducts, action.productsData.products);
          } else {
            // unvalidated ls basket
            updatedBasket = {
              sum: oLSBasketDefault.sum,
              amount: oLSBasketDefault.amount,
              products: oProducts,
            };
          }
          // update state basket
          return {
            ...state,
            basket: { ...updatedBasket },
          };
        }
        localStorage.removeItem('basket');
        localStorage.removeItem('promo');
      }
      // return state;
      return {
        ...state,
      };
  }
}

function paymentReducer(state = {}, action) {
  switch (action.type) {
    case actions.TOGGLE_SMS_CONFIRM_MODAL:
      return { ...state, isSmsConfirmModalShow: action.payload };
    case actions.TOGGLE_PAYANYWAY_MODAL:
      return { ...state, payanywayModal: action.payload };
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   settingsReducer,
//   notificationsReducer,
//   productsReducer,
//   profileReducer,
//   paymentReducer,
//   basketReducer,
// });

// custom combine reducers
const rootReducer = (state = {}, action) => {
  const productsData = state.productsReducer;
  return {
    settingsReducer: settingsReducer(state.settingsReducer, action),
    notificationsReducer: notificationsReducer(state.notificationsReducer, action),
    productsReducer: productsReducer(state.productsReducer, action),
    profileReducer: profileReducer(state.profileReducer, action),
    paymentReducer: paymentReducer(state.paymentReducer, action),
    basketReducer: basketReducer(state.basketReducer, {...action, productsData})
  };
};

export default rootReducer;
