import React, { Component } from 'react';
import declOfNum from '../../utils/DeclOfNum';
import './styles.css';

const SECONDS = ['секунда', 'секунды', 'секунд'];

class SmsConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.timer = 0;
    this.state = {
      phoneCode: '',
      isValid: true,
      focused: false,
      seconds: 30,
      orderId: this.props.orderId || '',
    };
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isError) {
      this.setState({
        phoneCode: '',
        isValid: false,
      });
    }
    if (nextProps.updateCode) {
      this.setState({
        phoneCode: '',
        isValid: true,
        seconds: 30,
      });
      this.timer = 0;
      this.startTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = 0;
  }

  handlePhoneCode = e => {
    e.preventDefault();
    const { value } = e.target;
    if (this.state.focused || value.length === 5) {
      this.setState({
        phoneCode: value,
        isValid: true,
      });
    } else {
      this.setState({
        phoneCode: value,
        isValid: false,
      });
    }
  };

  toggleFocus = flag => {
    this.setState({
      focused: flag,
    });
  };

  resendCode = e => {
    e.preventDefault();
    this.props.resendCode(this.state.orderId);
  };

  verifyCode = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.verifyCode(this.state.orderId, this.state.phoneCode);
  };

  startTimer = () => {
    if (this.timer === 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    const seconds = this.state.seconds - 1;
    this.setState({
      seconds,
    });

    if (seconds === 0) {
      clearInterval(this.timer);
      // this.timer = 0;
    }
  };

  render() {
    const { displayPhone, handleClose } = this.props;
    const { phoneCode, seconds, isValid, focused } = this.state;
    return (
      <div className="modal">
        <div className="modal_wrap">
          <div className="modal_close" onClick={() => handleClose(false)}>
            &times;
          </div>
          <div className="sms-confirm-modal">
            <div className="modal_section">
              <div className="sms-confirm h-tac">
                <h3>Подтверждение заказа</h3>
                <span className="sms-confirm_title">
                  Введите код подтверждения из&nbsp;SMS, присланный
                  на&nbsp;мобильный телефон
                  <span className="sms-confirm_phone">{displayPhone}</span>
                </span>
                <form
                  className="sms-confirm_form"
                  name="smsConfirmForm"
                  noValidate=""
                >
                  <fieldset>
                    <div className={`form_row${isValid ? '' : ' is-invalid'}`}>
                      <div className="form_cell">
                        <label htmlFor="sms_input">
                          <span className="label-text">Код из SMS</span>
                        </label>
                        <input
                          name="sms_input"
                          type="text"
                          placeholder="5 символов"
                          maxLength="5"
                          required
                          value={phoneCode}
                          onChange={e => this.handlePhoneCode(e)}
                          onFocus={e => this.toggleFocus(true)}
                          onBlur={e => this.toggleFocus(false)}
                        />
                        <span className="is-invalid-text">Неверный код</span>
                      </div>
                      <div className="form_cell">
                        <button
                          className="btn"
                          type="submit"
                          disabled={!(isValid && phoneCode.length === 5)}
                          onClick={e => this.verifyCode(e)}
                        >
                          Подтвердить
                        </button>
                      </div>
                    </div>
                  </fieldset>
                </form>
                {seconds !== 0 ? (
                  <p className="sms-confirm-refresh">
                    Если SMS не пришла, вы можете повторить попытку
                    подтверждения через {declOfNum.format(seconds, SECONDS)}
                  </p>
                ) : (
                  <p className="sms-confirm-refresh">
                    <a
                      href="#"
                      className="pseudo"
                      onClick={e => this.resendCode(e)}
                    >
                      Выслать код повторно
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SmsConfirmModal;
