/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { masks } from '../../constants/input';
import * as methods from './form_methods';
import { User } from '../../shapes/data';

const AuthForm = props => {
  const [state, setState] = useState({
    formType: props.formType || 'signup',
    isLoggedIn: !!props.user.id,
    isError: false,
    errorText: '',
    name: '',
    phone: '',
    password: '',
    phoneError: false,
    phoneErrorText: 'Укажите номер телефона',
    passwordError: false,
    passwordErrorText: 'Введите пароль',
  });

  // Run once
  useEffect(() => {
    setState({
      ...state,
      formType: props.formType,
    });
  }, []);

  // change formType from parent
  useEffect(() => {
    if (props.formType !== state.formType) {
      setState({
        ...state,
        formType: props.formType,
      });
    }
  }, [props.formType]);

  useEffect(() => {
    setState({
      ...state,
      isError: props.user.error || false,
      errorText: props.user.errorText || '',
    });
  }, [props.user.error, props.user.errorText]);

  const handleSetState = data => {
    if (data) {
      setState({
        ...state,
        ...data,
      });
    }
  };

  const renderFields = () => {
    const inputProps = {};
    inputProps.placeholder = 'Номер телефона';
    inputProps.type = 'tel';
    inputProps.name = 'phone';
    inputProps.autoComplete = 'off';
    const phoneMasked = state.phone || '';

    const { isError } = state;
    const errorText =
      state.errorText || 'Проверьте правильность данных и попробуйте снова';
    const errors = Array.isArray(errorText)
      ? errorText.map(error => `<p>${error}</p>`).join('')
      : `<span>${errorText.toString()}</span>`;

    const result = [
      <div className={`form_row ${isError ? ' is-invalid' : ''}`} key="login">
        {isError ? (
          <div
            className="is-invalid-text"
            dangerouslySetInnerHTML={{ __html: errors }}
          />
        ) : null}
        <div
          className={`form_cell cell-l ${state.phoneError ? 'is-invalid' : ''}`}
        >
          {state.phoneError ? (
            <div className="is-invalid-text">{state.phoneErrorText}</div>
          ) : null}
          <MaskedInput
            {...inputProps}
            value={phoneMasked}
            required="required"
            mask={masks.phone}
            guide={false}
            className="form-control"
            onChange={e =>
              methods.handleFieldChange(e, 'phone', handleSetState)
            }
            onBlur={e =>
              methods.validateField(
                e.target.value,
                'phone',
                undefined,
                handleSetState
              )
            }
          />
        </div>
      </div>,
    ];
    switch (state.formType) {
      case 'signin':
        result.push(
          <div
            className={`form_row${isError ? ' is-invalid' : ''}`}
            key={`${state.formType}_password`}
          >
            <div
              className={`form_cell cell-l ${
                state.passwordError ? 'is-invalid' : ''
              }`}
            >
              {state.passwordError ? (
                <div className="is-invalid-text">{state.passwordErrorText}</div>
              ) : null}
              <input
                placeholder="Пароль"
                type="password"
                name="password"
                required="required"
                className="form-control"
                onChange={e =>
                  methods.handleFieldChange(e, 'password', handleSetState)
                }
                onBlur={e =>
                  methods.validateField(
                    e.target.value,
                    'password',
                    undefined,
                    handleSetState
                  )
                }
              />
            </div>
          </div>
        );
        break;
      case 'signup':
        result.push(
          <div
            className={`form_row${isError ? ' is-invalid' : ''}`}
            key={`${state.formType}_password`}
          >
            <div
              className={`form_cell cell-l ${
                state.passwordError ? 'is-invalid' : ''
              }`}
            >
              <div className="is-invalid-text">
                {state.passwordError ? state.passwordErrorText : null}
              </div>
              <input
                placeholder="Пароль"
                type="password"
                name="password"
                required="required"
                className="form-control"
                onChange={e =>
                  methods.handleFieldChange(e, 'password', handleSetState)
                }
                onBlur={e =>
                  methods.validateField(
                    e.target.value,
                    'password',
                    undefined,
                    handleSetState
                  )
                }
              />
            </div>
          </div>
        );
        break;
      default:
        break;
    }
    return result;
  };

  const renderButton = () => {
    switch (state.formType) {
      case 'signin':
        return (
          <button
            className="btn"
            onClick={e =>
              methods.handleFormSubmit(
                e,
                'signin',
                props,
                handleSetState,
                state
              )
            }
            type="submit"
          >
            Войти
          </button>
        );
      case 'signup':
        return (
          <button
            className="btn"
            onClick={e =>
              methods.handleFormSubmit(
                e,
                'signup',
                props,
                handleSetState,
                state
              )
            }
            type="submit"
          >
            Продолжить
          </button>
        );
      case 'forgot':
        return (
          <button
            className="btn"
            onClick={e =>
              methods.handleFormSubmit(
                e,
                'forgot',
                props,
                handleSetState,
                state
              )
            }
            type="submit"
          >
            Отправить новый пароль
          </button>
        );
      default:
        return null;
    }
  };

  const renderForgotLink = () => {
    switch (state.formType) {
      case 'signin':
        return (
          <div className="form_cell text-center">
            <button
              type="button"
              className="link"
              onClick={e => methods.goTo(e, 'forgot', props, handleSetState)}
            >
              Забыли пароль?
            </button>
          </div>
        );
      case 'signup':
        return (
          <div className="form_cell text-center">
            Нажимая кнопку «Продолжить»
            <br />
            вы&nbsp;принимаете{' '}
            <Link to="/terms" onClick={e => methods.readTerms(e, props)}>
              Условия&nbsp;соглашения
            </Link>
          </div>
        );
      case 'forgot':
        return null;
      default:
        return null;
    }
  };

  return (
    <form
      className={`form ${props.className}`}
      name={`${state.formType}Form`}
      noValidate=""
    >
      {renderFields()}
      <div className="form_row">
        <div className="form_cell text-center">{renderButton()}</div>
      </div>
      <div className="form_row">{renderForgotLink()}</div>
    </form>
  );
};

const mapStateToProps = state => ({
  user: state.rootReducer.profileReducer,
});

const mapDispatchToProps = dispatch => ({
  setLoginError: data => {
    dispatch({ type: 'LOG_IN_ERROR', payload: data });
  },
  signIn: body => {
    dispatch({ type: 'CALL_SIGN_IN', payload: body });
  },
  signUp: body => {
    dispatch({ type: 'CALL_SIGN_UP', payload: body });
  },
  forgotPassword: body => {
    dispatch({ type: 'CALL_FORGOT_PASSWORD', payload: body });
  },
});

AuthForm.propTypes = {
  formType: PropTypes.string,
  className: PropTypes.string,
  user: PropTypes.shape(User).isRequired,
  setLoginError: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  handleModal: PropTypes.func,
};

AuthForm.defaultProps = {
  formType: 'signup',
  className: '',
  handleModal: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthForm);
