/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import RouteLayout from './routeLayout';

const WrapRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <RouteLayout>
        <Component {...props} />
      </RouteLayout>
    )}
  />
);

WrapRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.func]).isRequired,
};

export default WrapRoute;
