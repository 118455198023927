/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Link as LinkScroll,
  // DirectLink,
  // Element,
  // Events,
  animateScroll as scroll,
  // scrollSpy,
  // scroller,
} from 'react-scroll';
import { history } from '../../store';
import StickyHeader from '../../utils/stickyHeader';

import { Timetable } from '../timetable';
import Modal from '../modal';
import WarningModal from '../warningModal';
import Tooltip from '../tooltip';
import { ToastProvider } from '../notification/notification-core';
import { Notifications } from '../notification';
import './styles.css';

import { Context } from '../../context/providers';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      modalType: '',
      showTooltip: false,
    };
  }

  componentDidMount() {
    StickyHeader();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (
      this.context.basket &&
      nextState.basket &&
      this.context.basket.amount !== nextState.basket.amount
    ) {
      const elem = document.getElementsByClassName('basket_content-img')[0];
      elem.classList.add('is-updated');
      setTimeout(() => {
        elem.classList.remove('is-updated');
      }, 700);
    }
  }

  handleToggle = () => {
    // const rootEl = document.getElementsByTagName('html')[0];
    this.setState(
      (prevState) => ({ open: !prevState.open })
      // ,() => {
      //   if (!this.state.open) {
      //     rootEl.classList.remove('is-noscroll');
      //   } else {
      //     rootEl.classList.add('is-noscroll');
      //   }
      // }
    );
  };

  hideToggleMenu = () => {
    this.setState({ open: false });
  };

  handleMobileMenuClick = e => {
    e.preventDefault();
    const { link } = e.currentTarget.dataset;
    this.setState({ open: false }, () => {
      if (history) {
        if (link) history.push(link);
      }
    });
  };

  handleSignIn = (e, type = 'signup') => {
    e.persist();
    e.preventDefault();
    this.setState({ open: false }, () => {
      this.handleModal(e, true, type);
    });
  };

  handleSignOut = (e, path) => {
    e.preventDefault();
    this.setState({ open: false }, () => {
      this.props.signOut({ 'path': path });
    });
  };

  handleTooltip = (e, isShow = false) => {
    e.persist();
    e.preventDefault();
    const target = e.currentTarget;
    const text = target.dataset.tooltip || '';
    this.setState(
      prevState => ({ 
        showTooltip: isShow || !prevState.showTooltip,
        TooltipText: text,
      }),
      () => {
        // console.log('hide tooltip on timeout');
      }
    );
  };

  handleTooltipClose = () => {
    this.setState({
      showTooltip: false,
    });
  };

  renderTooltip = (text, handleTooltipClose) => (
    <Tooltip
      className="toggle-on-hover"
      text={text}
      handleTooltipClose={handleTooltipClose}
    />
  );

  handleModal = (e, isOpen, modalType = '') => {
    if (e) {
      e.preventDefault();
    }
    this.props.toggleLogInModal(isOpen);
    this.setState({
      modalType,
    });
  };

  renderModal = (settings, user) => {
    const modals = [];
    if (
      settings &&
      settings.show_blocking_popup &&
      settings.closeable
    ) {
      modals.push(
        <WarningModal settings={settings} key="modal-warning" />
      );
    }
    if (user && user.isLogInModalOpen) {
      modals.push(
        <Modal
          modalType={this.state.modalType}
          handleModal={this.handleModal}
          key="modal-auth"
        />
      );
    }
    return modals;
  };

  renderSiteMenu = (path) => (
    <>
      <span className="header_menu">
        <a href="https://barashek.ru" className="header_menu-link">
          Главная
        </a>
        <Link
          to="/"
          className={`header_menu-link ${
            this.isActivePath(path, '/') ? 'is-active' : ''
          }`}
        >
          Меню
        </Link>
        <Link
          to="/payment"
          className={`header_menu-link ${
            this.isActivePath(path, '/payment') ? 'is-active' : ''
          }`}
        >
          Оплата и доставка
        </Link>
        <Link
          to="/contacts"
          className={`header_menu-link ${
            this.isActivePath(path, '/contacts') ? 'is-active' : ''
          }`}
        >
          Контакты
        </Link>
        <Link
          to="/kazanplova"
          className={`header_menu-link ${
            this.isActivePath(path, '/kazanplova') ? 'is-active' : ''
          }`}
        >
          Плов в казане
        </Link>
      </span>
      <span className="header_submenu">
        {this.props.products && this.renderProductLinks('header_submenu-link')}
      </span>
    </>
  );

  renderBasket = basket => (
    <span className="header_basket">
      {basket && basket.amount ? (
        <Link to="/basket" className="basket" title="Корзина">
          <span
            className={
              `basket_content is-x${basket.amount > 5 ? 5 : basket.amount}`
            }
          >
            <span className="basket_content-img">
              <img
                alt=" "
                className="basket_content-img"
                src="/i/layout/basket.svg"
              />
            </span>
            <span className="basket_content-count">{basket.amount}</span>
          </span>
          <span className="basket_text">
            <span className="basket_title is-hidden-mob">Корзина</span>
            <span className="basket_sum">
              {basket.sum}
              <span className="rub">руб.</span>
            </span>
          </span>
        </Link>
      ) : (
        <Link to="/basket" className="basket" title="Корзина">
          <span className="basket_content is-x0">
            <span className="basket_content-img">
              <img
                alt=" "
                className="basket_content-img"
                src="/i/layout/basket.svg"
              />
            </span>
            <span className="basket_content-count">0</span>
          </span>
          <span className="basket_text">
            <span className="basket_title is-hidden-mob">Корзина</span>
            <span className="basket_sum">
              0
              <span className="rub">руб.</span>
            </span>
          </span>
        </Link>
      )}
    </span>
  );

  buildNotificationsList = () => {
    const aResult = [];
    const settingsText =
      this.state.settings &&
      this.state.settings.text &&
      this.state.settings.show_blocking_popup &&
      this.state.settings.closeable
        ? {
            data: {
              message_data: this.state.settings.text,
              message_type: 'error',
            },
          }
        : null;

    if (settingsText) {
      aResult.push(settingsText);
    }
    if (this.props.notifications) {
      this.props.notifications.forEach(item => {
        aResult.push(item);
      });
    }

    return aResult;
  };

  renderBanner = () => (
    <ToastProvider>
      {(this.state.settings || this.props.notifications) && 
        <Notifications list={this.buildNotificationsList()} />
      }
    </ToastProvider>
  );

  renderProductLinks = menuClass =>
    this.props.products
      .filter(item => !item.invisible)
      .map((item, index) => (
        // <a
        //   className={`header_nav-link ${menuClass}`}
        //   href={`/#product_${item.name}`}
        //   key={item.name}
        // >
        //   {item.name}
        // </a>
        <LinkScroll
          key={item.name}
          className={`header_nav-link ${menuClass}`}
          activeClass="is-active"
          to={`product_${item.name}`}
          spy
          hashSpy
          offset={-140}
          isDynamic
          smooth
          onSetActive={this.hideToggleMenu}
          onSetInactive={this.hideToggleMenu}
        >
          {item.name}
        </LinkScroll>
      ));

  isActivePath = (path, item) => path === item;

  render() {
    const { user, settings, basket } = this.context;
    const path = window.location.pathname;
    const isLoginPage = !!path.match(/login/);
    const isRegisterPage = !!path.match(/register/);

    return (
      <div className="lyt-header" id="header">
        {this.renderModal(settings, user)}
        <div className={`header${this.state.open ? ' is-opened' : ''}`}>
          <div className={`header_nav${this.state.open ? ' is-opened' : ''}`}>
            <div className="container">
              <div className="row">
                <span className="header_nav-trig" onClick={this.handleToggle} />
                <div className="header_nav-content container">
                  <div className="header_nav-center">
                    <div className="header_nav-helptext">
                      <a className="phone-link" href="tel:+78362369999">
                        +7&nbsp;(8362)&nbsp;36&nbsp;99&nbsp;99
                      </a>
                      Прием заказов сегодня:&nbsp;<Timetable today />
                    </div>
                  </div>
                  <div className="header_nav-right is-hidden-mob">
                    {user && user.phone ? (
                      <div className="loginblock">
                        <div className="header_nav-helptext">
                          {user.bonus_balance >= 0 ? (
                            <>
                              <span
                                className="nav-item header_nav-tooltip"
                                data-tooltip="<p>Совершая заказ по&nbsp;телефону, через сайт или в&nbsp;ресторане, вы&nbsp;накапливаете бонусы, которые сможете использовать при следующем заказе.</p><p>1&nbsp;бонус = 1&nbsp;рубль</p>"
                                onClick={e => this.handleTooltip(e)}
                                onMouseOver={e => this.handleTooltip(e, true)}
                                onMouseOut={e => this.handleTooltip(e, false)}
                              >
                                Бонусы:{' '}
                                <span className="highlighted">
                                  {user.bonus_balance}
                                </span>
                              </span>
                              {this.state.showTooltip
                                ? this.renderTooltip(
                                    this.state.TooltipText,
                                    this.handleTooltipClose
                                  )
                                : null}
                            </>
                          ) : null}
                          <Link
                            className="nav-item header_nav-logintext is-hidden-mob"
                            to="/profile"
                          >
                            <i className="icon icon_userpic" />
                            <span>Кабинет</span>
                          </Link>
                          <a
                            className="nav-item header_nav-link"
                            href="#"
                            onClick={e => this.handleSignOut(e, path)}
                          >
                            Выйти
                          </a>
                        </div>
                      </div>
                    ) : (
                      <div className="loginblock is-hidden-mob">
                        <div className="header_nav-helptext">
                          {!isLoginPage ? (
                            <a
                              className="nav-item header_nav-loginlink"
                              href="#"
                              onClick={e => this.handleModal(e, true, 'signin')}
                            >
                              Войти
                            </a>
                          ) : null}
                          {!isRegisterPage ? (
                            <a
                              className="nav-item header_nav-loginlink"
                              href="#"
                              onClick={e => this.handleModal(e, true, 'signup')}
                            >
                              Зарегистрироваться
                            </a>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="header_nav-menu">
                    <a href="https://barashek.ru" className="header_nav-link">
                      Главная
                    </a>
                    <a
                      className={`header_nav-link ${
                        this.isActivePath(path, '/') ? 'is-active' : ''
                      }`}
                      href="/"
                      data-link="/"
                      onClick={e => this.handleMobileMenuClick(e)}
                    >
                      Меню
                    </a>
                    {this.props.products && this.renderProductLinks('link-product')}
                    <a
                      className={`header_nav-link ${
                        this.isActivePath(path, '/payment') ? 'is-active' : ''
                      }`}
                      href="/payment"
                      data-link="/payment"
                      onClick={e => this.handleMobileMenuClick(e)}
                    >
                      Оплата и доставка
                    </a>
                    <a
                      className={`header_nav-link ${
                        this.isActivePath(path, '/contacts') ? 'is-active' : ''
                      }`}
                      href="/contacts"
                      data-link="/contacts"
                      onClick={e => this.handleMobileMenuClick(e)}
                    >
                      Контакты
                    </a>
                    <a
                      className={`header_nav-link ${
                        this.isActivePath(path, '/kazanplova') ? 'is-active' : ''
                      }`}
                      href="/kazanplova"
                      data-link="/kazanplova"
                      onClick={e => this.handleMobileMenuClick(e)}
                    >
                      Плов в казане
                    </a>
                    <a
                      className={`header_nav-link ${
                        this.isActivePath(path, '/basket') ? 'is-active' : ''
                      }`}
                      href="/basket"
                      data-link="/basket"
                      onClick={e => this.handleMobileMenuClick(e)}
                    >
                      Корзинa
                    </a>
                    {user && user.phone ? (
                      <div className="header_nav-menu_cols">
                        <a
                          className={`header_nav-link ${
                            this.isActivePath(path, '/profile') ? 'is-active' : ''
                          }`}
                          href="/profile"
                          data-link="/profile"
                          onClick={e => this.handleMobileMenuClick(e)}
                        >
                          <i className="icon icon_userpic" />
                          <span>Кабинет</span>
                          {user.bonus_balance ? (
                            <span className="bonuses">
                              <span className="bonuses-title">Бонусы:</span>
                              <span className="bonuses-value">
                                {user.bonus_balance}
                              </span>
                            </span>
                          ) : null}
                        </a>
                        <a
                          className="header_nav-link"
                          href="#"
                          onClick={e => this.handleSignOut(e, path)}
                        >
                          Выйти
                        </a>
                      </div>
                    ) : (
                      <div className="header_nav-menu_cols">
                        {!isLoginPage ? (
                          <a
                            className="header_nav-link"
                            href="#"
                            onClick={e => this.handleSignIn(e, 'signin')}
                          >
                            Вход
                          </a>
                        ) : null}
                        {!isRegisterPage ? (
                          <a
                            className="header_nav-link"
                            href="#"
                            onClick={e => this.handleSignIn(e, 'signup')}
                          >
                            Регистрация
                          </a>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="header_content">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-auto header_content-mid">
                  <Link
                    to="/"
                    className="header_logo"
                    title="Доставка блюд узбекской кухни"
                  />
                </div>
                <div className="col header_content-left flex-column">
                  {this.renderSiteMenu(path)}
                </div>
                <div className="col-auto header_content-right">
                  {this.renderBasket(basket)}
                </div>
              </div>
            </div>
          </div>

        </div>
        {this.renderBanner()}
      </div>
    );
  }
}

Header.contextType = Context;

const mapStateToProps = state => ({
  products: state.rootReducer.productsReducer.products,
  notifications: state.rootReducer.notificationsReducer.notifications || [],
});

const mapDispatchToProps = dispatch => ({
  toggleLogInModal: body => {
    dispatch({ type: 'TOGGLE_LOGIN_MODAL', payload: body });
  },
  signOut: data => {
    dispatch({ type: 'CALL_SIGN_OUT', payload: data });
  },
});

Header.propTypes = {
  notifications: PropTypes.instanceOf(Array),
  products: PropTypes.instanceOf(Array),
  signOut: PropTypes.func.isRequired,
  toggleLogInModal: PropTypes.func.isRequired,
};

Header.defaultProps = {
  notifications: [],
  products: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
