/* eslint-disable prefer-destructuring */
const env = process.env;
/* eslint-enable prefer-destructuring */
export const LOCAL_URL = env.LOCAL_URL || '';
export const IS_DEV = env.NODE_ENV === 'development';
export const IS_TEST = env.NODE_ENV === 'test';
export const IS_PROD = env.NODE_ENV === 'production';
export const FETCH_TIMEOUT = env.REACT_APP_FETCH_TIMEOUT || 30000;
export const SERVER_URL = env.REACT_APP_SERVER_URL || '';
export const PUBLIC_URL = env.PUBLIC_URL || '';

export default {
  IS_DEV,
  IS_TEST,
  IS_PROD,
  PUBLIC_URL,
  SERVER_URL,
  LOCAL_URL,
  FETCH_TIMEOUT,
};
