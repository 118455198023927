import { PUBLIC_URL } from './app';

export const LOGIN_PAGE = `${PUBLIC_URL}/login`;
export const REGISTER_PAGE = `${PUBLIC_URL}/register`;
export const RESTORE_PWD_PAGE = `${PUBLIC_URL}/restore`;
export const HOME_PAGE = `${PUBLIC_URL}/`;
export const USER_AGREEMENT = '/terms';
export const CONTACTS = `${PUBLIC_URL}/contacts`;
export const PAYMENT = `${PUBLIC_URL}/payment`;
export const AGREEMENTS = `${PUBLIC_URL}/agreements`;
export const KAZAN = `${PUBLIC_URL}/kazanplova`;
export const BASKET = `${PUBLIC_URL}/basket`;
export const PROFILE = `${PUBLIC_URL}/profile`;

export const authPaths = {
  LOGIN_PAGE,
  REGISTER_PAGE,
  RESTORE_PWD_PAGE,
  PROFILE,
};

export default {
  ...authPaths,

  HOME_PAGE,
  USER_AGREEMENT,
  BASKET,
  KAZAN,
  PAYMENT,
  AGREEMENTS,
  CONTACTS,
};
