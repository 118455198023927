/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Helmet } from 'react-helmet';
import AuthForm from '../../components/forms/authform';

const Login = () => {
  const title = 'Вход на сайт';
  return (
    <>
      <Helmet>
        <title>{`${title} – BARAШEK`}</title>
        <meta content={`${title} – BARAШEK`} name="title" />
        <meta content={title} property="og:title" />
      </Helmet>
      <div className="container">
        <h2 className="h-tac">{title}</h2>
        <AuthForm formType="signin" className="form standalone" />
      </div>
    </>
  );
};

export default Login;
