import React, { Component } from 'react';
import './styles.css';

class WarningModal extends Component {
    state = {
        open: true
    };

    handleClose = (e) => {
        if(e) {
            e.preventDefault();
        }
        if (this.props.settings.closeable) {
            this.setState({
                open: false
            });
        }
    };

    render() {
        return this.props.settings && this.state.open ? (
            <div className="modal">
                <div className="modal_wrap">
                    { this.props.settings.closeable &&
                        (<div
                            className="modal_close"
                            onClick={(e) => this.handleClose(e)}
                        >
                            &times;
                        </div>)
                    }
                    <div className="warning-block">
                        <h4 dangerouslySetInnerHTML={{ __html: this.props.settings.text }}></h4>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

export default WarningModal
