/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import * as routes from '../../constants/routes';

import IsAuthenticated from './isAuthenticated';
import RouteLayout from './routeLayout';
import { User } from '../../shapes/data';

import AppLoader from '../loader';
import { useInterval } from '../../hooks/useInterval';

const PrivateRoute = props => {
  const { component: Component, exact, path, currentUser, user } = props;

  const [state, setState] = useState({
    user: {},
    isLoading: true,
    isAuthenticated: undefined,
  });

  const intervalRef = useInterval(() => {
    if (!state.user.id) {
      setState({
        ...state,
        isLoading: !state.user.streets ? true : false,
        isAuthenticated: !state.user.streets ? undefined : false,
      });
    } else {
      window.clearInterval(intervalRef.current);
      setState({
        ...state,
        isLoading: false,
        isAuthenticated: true,
      });
    }
  }, 1000);

  useEffect(() => {
    currentUser();
  }, []);

  useEffect(() => {
    if (Object.entries(user).length !== 0) {
      if (user.id) {
        setState({
          ...state,
          user,
        });
      } else if (!user.id && user.streets) {
        setState({
          ...state,
          user,
        });
      } else {
        setState({
          ...state,
        });
      }
    }
  }, [user]);

  if (state.isLoading) {
    return <AppLoader />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={() => (
        <IsAuthenticated
          isAuthenticated={state.isAuthenticated}
          redirectTo={routes.LOGIN_PAGE}
        >
          <RouteLayout>
            <Component />
          </RouteLayout>
        </IsAuthenticated>
      )}
    />
  );
};

const mapStateToProps = state => ({
  user: state.rootReducer.profileReducer,
});

const mapDispatchToProps = dispatch => ({
  currentUser: () => {
    dispatch({ type: 'CALL_CURRENT_USER' });
  },
});

PrivateRoute.propTypes = {
  user: PropTypes.shape(User),
  currentUser: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
};
PrivateRoute.defaultProps = {
  user: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
