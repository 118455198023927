import React from 'react';
import { Helmet } from 'react-helmet';

const Payment = () => {
  const title = 'Положение о персональных данных';

  return (
    <div className="container">
      <Helmet>
        <title>{`${title} – BARAШEK`}</title>
        <meta content={`${title} – BARAШEK`} name="title" />
        <meta content={title} property="og:title" />
      </Helmet>
      <h1 className="h-tac">{title}</h1>
      <div className="content-index">
        <section className="content-section">
          <em>Основные понятия, термины и определения</em>
          <div className="box">
            <p>
              В настоящем Положении используются следующие понятия, термины и
              определения:
            </p>
            <p>
              Персональные данные – любая информация, относящаяся к прямо или
              косвенно определенному или определяемому физическому лицу
              (субъекту персональных данных);
            </p>
            <p>
              Общество – юридическое лицо (ООО «Вкусные технологии»),
              самостоятельно или совместно с другими лицами организующее и (или)
              осуществляющее обработку персональных данных, а также определяющее
              цели обработки персональных данных, состав персональных данных,
              подлежащих обработке, действия (операции), совершаемые с
              персональными данными;
            </p>
            <p>
              Субъекты персональных данных – работники Общества, клиенты
              Общества и другие лица, персональные данные которых обрабатывает
              Общество:
              <ul>
                <li>
                  работник – физическое лицо (субъект персональных данных),
                  состоящее или состоявшее в трудовых отношениях с Обществом, в
                  том числе работники предоставляемые партнерами по внешним
                  договорам оказания услуг;
                </li>
                <li>
                  клиент – физическое лицо (субъект персональных данных),
                  которому Обществом оказывается услуга или которое обратилось в
                  Общество за оказанием услуги;
                </li>
                <li>
                  другие лица – физические лица (субъекты персональных данных),
                  законные представители физических лиц, не относящиеся к
                  категории работников и клиентов, персональные данные которых
                  обрабатывает Общество (кандидаты на замещение вакантных
                  должностей, граждане, обращающиеся с заявлениями, жалобами,
                  предложениями; близкие родственники работников, законные
                  представители работников, законные представители клиентов);
                </li>
              </ul>
            </p>
            <p>
              Обработка персональных данных – любое действие (операция) или
              совокупность действий (операций), совершаемых Обществом с
              использованием средств автоматизации или без использования таких
              средств с персональными данными, включая сбор, запись,
              систематизацию, накопление, хранение, уточнение (обновление,
              изменение), извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных;
            </p>
            <p>
              Автоматизированная обработка персональных данных – обработка
              персональных данных с помощью средств вычислительной техники;
            </p>
            <p>
              Распространение персональных данных – действия, направленные на
              раскрытие персональных данных неопределенному кругу лиц;
            </p>
            <p>
              Предоставление персональных данных – действия, направленные на
              раскрытие персональных данных определенному лицу или определенному
              кругу лиц;
            </p>
            <p>
              Доступ к информации – возможность получения информации, содержащей
              персональные данные и ее использования;
            </p>
            <p>
              Блокирование персональных данных – временное прекращение обработки
              персональных данных (за исключением случаев, если обработка
              необходима для уточнения персональных данных);
            </p>
            <p>
              Уничтожение персональных данных – действия, в результате которых
              становится невозможным восстановить содержание персональных данных
              в информационной системе персональных данных и (или) в результате
              которых уничтожаются материальные носители персональных данных;
            </p>
            <p>
              Обезличивание персональных данных – действия, в результате которых
              становится невозможным без использования дополнительной информации
              определить принадлежность персональных данных конкретному субъекту
              персональных данных;
            </p>
            <p>
              Общедоступные персональные данные – персональные данные, доступ
              неограниченного круга лиц к которым предоставлен с согласия
              субъекта персональных данных или на которые в соответствии с
              федеральными законами не распространяется требование соблюдения
              конфиденциальности;
            </p>
            <p>
              Трансграничная передача персональных данных – передача
              персональных данных на территорию иностранного государства органу
              власти иностранного государства, иностранному физическому лицу или
              иностранному юридическому лицу;
            </p>
            <p>
              Конфиденциальность персональных данных – обязательное для
              соблюдения работниками Общества, иными получившим доступ к
              персональным данным лицами требование не допускать их
              распространения без согласия субъекта персональных данных или
              наличия иного законного основания;
            </p>
            <p>
              Информационная система персональных данных – совокупность
              содержащихся в базах данных персональных данных и обеспечивающих
              их обработку информационных технологий и технических средств;
            </p>
            <p>
              Угроза безопасности персональных данных - совокупность условий и
              факторов, создающих опасность несанкционированного, в том числе
              случайного, доступа к персональным данным, результатом которого
              могут стать уничтожение, изменение, блокирование, копирование,
              предоставление, распространение персональных данных, а также иные
              неправомерные действия при их обработке в информационной системе
              персональных данных;
            </p>
            <p>
              Уровень защищенности персональных данных - комплексный показатель,
              характеризующий требования, исполнение которых обеспечивает
              нейтрализацию определенных угроз безопасности персональных данных
              при их обработке в информационных системах персональных данных;
            </p>
            <p>
              Несанкционированный доступ - доступ к информации или действия с
              информацией, осуществляемые с нарушением установленных прав и
              (или) правил доступа к информации или действий с ней с применением
              штатных средств информационной системы или средств, аналогичных им
              по своим функциональному предназначению и техническим
              характеристикам;
            </p>
            <p>
              Информационно-телекоммуникационная сеть – технологическая система,
              предназначенная для передачи по линиям связи информации, доступ к
              которой осуществляется с использованием средств вычислительной
              техники.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ОБЩИЕ ПОЛОЖЕНИЯ</em>
          <div className="box">
            <p>
              Настоящее Положение определяет порядок обработки персональных
              данных работников, клиентов и других лиц Общества, а также порядок
              обеспечения конфиденциальности обрабатываемых персональных данных.
            </p>
            <p>
              Настоящее Положение разработано на основании требований:
              Конституции Российской Федерации, Гражданского кодекса Российской
              Федерации, Трудового кодекса Российской Федерации, Федерального
              закона от 19 декабря 2005 года № 160-ФЗ «О ратификации Конвенции
              Совета Европы о защите физических лиц при автоматизированной
              обработке персональных данных», Федерального закона от 27 июля
              2006 года № 152-ФЗ «О персональных данных», Постановления
              Правительства Российской Федерации от 1 ноября 2012 года № 1119
              «Об утверждении требований к защите персональных данных при их
              обработке в информационных системах персональных данных»,
              Постановления Правительства Российской Федерации от 15 сентября
              2008 года № 687 «Об утверждении Положения об особенностях
              обработки персональных данных, осуществляемой без использования
              средств автоматизации», приказа Федеральной службы по техническому
              и экспортному контролю от 18 февраля 2013 г. № 21 «Об утверждении
              Состава и содержания организационных и технических мер по
              обеспечению безопасности персональных данных при их обработке в
              информационных системах персональных данных» и иных нормативных
              правовых актов Российской Федерации.
            </p>
            <p>
              Требования настоящего Положения распространяются на работников,
              клиентов и других лиц (субъектов персональных данных),
              персональные данные которых обрабатывает Общество.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ЦЕЛИ И ЗАДАЧИ ПОЛОЖЕНИЯ</em>
          <div className="box">
            <p>
              Целями настоящего Положения выступают:
              <ul>
                <li>
                  обеспечение соответствия обработки персональных данных
                  работников Общества, клиентов Общества и других лиц
                  требованиям законодательства Российской Федерации;
                </li>
                <li>
                  обеспечение защиты персональных данных работников Общества,
                  клиентов Общества и других лиц от несанкционированного
                  доступа, утраты, неправомерного их использования или
                  распространения.
                </li>
              </ul>
            </p>

            <p>
              Задачами настоящего Положения являются:
              <ul>
                <li>
                  определение целей, принципов и условий обработки персональных
                  данных;
                </li>
                <li>
                  определение порядка обработки Обществом персональных данных;
                </li>
                <li>
                  определение категорий персональных данных, категорий субъектов
                  персональных данных, обрабатываемых Обществом;
                </li>
                <li>
                  определение применяемых Обществом способов защиты персональных
                  данных;
                </li>
                <li>
                  определение прав и обязанностей Общества и субъектов
                  персональных данных при обработке персональных данных.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ЦЕЛИ, ПРИНЦИПЫ И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</em>
          <div className="box">
            <p>
              4.1. Целями обработки персональных данных субъектов персональных
              данных являются:
              <ul>
                <li>
                  обеспечение соблюдения Конституции Российской Федерации,
                  федеральных законов, иных нормативных правовых актов
                  Российской Федерации;
                </li>
                <li>реализация уставных задач Общества.</li>
              </ul>
            </p>
            <p>
              4.2. Принципами обработки Обществом персональных данных субъектов
              персональных данных являются:
              <ul>
                <li>
                  законность целей и способов обработки персональных данных;
                </li>
                <li>
                  соответствие целей обработки персональных данных целям,
                  заранее определенным и заявленным при сборе персональных
                  данных, а также полномочиям Общества;
                </li>
                <li>
                  соответствие объема и характера обрабатываемых персональных
                  данных, способов обработки персональных данных целям обработки
                  персональных данных;
                </li>
                <li>
                  точность обрабатываемых персональных данных, их достаточность,
                  а в необходимых случаях и актуальность по отношению к целям
                  обработки, недопустимость обработки Обществом персональных
                  данных, избыточных по отношению к целям, заявленным при сборе
                  персональных данных;
                </li>
                <li>
                  недопустимость объединения Обществом баз данных, содержащих
                  персональные данные, обработка которых осуществляется в целях,
                  несовместимых между собой.
                </li>
              </ul>
            </p>
            <p>4.3. Условия обработки персональных данных.</p>
            <p>
              4.3.1. Обработка персональных данных осуществляется только
              должностными лицами Общества, непосредственно использующими их в
              служебных целях.
            </p>
            <p>
              Лица, уполномоченные на обработку персональных данных Обществом,
              имеют право получать только те персональные данные, которые
              необходимы им для выполнения своих должностных обязанностей. Все
              остальные работники Общества имеют право на полную информацию,
              касающуюся только собственных персональных данных.
            </p>
            <p>
              4.3.2. Обработка персональных данных субъектов персональных данных
              осуществляется Обществом в следующих случаях:
              <ul>
                <li>
                  обработка персональных данных осуществляется с согласия
                  субъекта персональных данных на обработку его персональных
                  данных;
                </li>
                <li>
                  обработка персональных данных необходима для достижения целей,
                  предусмотренных международным договором Российской Федерации
                  или законом, для осуществления и выполнения, возложенных
                  законодательством Российской Федерации на Общество функций,
                  полномочий и обязанностей;
                </li>
                <li>
                  обработка персональных данных осуществляется в связи с
                  участием лица в конституционном, гражданском,
                  административном, уголовном судопроизводстве, судопроизводстве
                  в арбитражных судах;
                </li>
                <li>
                  обработка персональных данных необходима для исполнения
                  судебного акта, акта другого органа или должностного лица,
                  подлежащих исполнению в соответствии с законодательством
                  Российской Федерации об исполнительном производстве;
                </li>
                <li>
                  обработка персональных данных необходима для исполнения
                  договора, стороной которого либо выгодоприобретателем или
                  поручителем по которому является субъект персональных данных,
                  а также для заключения договора по инициативе субъекта
                  персональных данных или договора, по которому субъект
                  персональных данных будет являться выгодоприобретателем или
                  поручителем;
                </li>
                <li>
                  обработка персональных данных необходима для защиты жизни,
                  здоровья или иных жизненно важных интересов субъекта
                  персональных данных, если получение согласия субъекта
                  персональных данных невозможно;
                </li>
                обработка персональных данных необходима для осуществления прав
                и законных интересов Общества или третьих лиц либо для
                достижения общественно значимых целей при условии, что при этом
                не нарушаются права и свободы субъекта персональных данных;
                <li>
                  обработка персональных данных необходима для осуществления
                  профессиональной деятельности журналиста и (или) законной
                  деятельности средства массовой информации либо научной,
                  литературной или иной творческой деятельности при условии, что
                  при этом не нарушаются права и законные интересы субъекта
                  персональных данных;
                </li>
                <li>
                  осуществляется обработка персональных данных, доступ
                  неограниченного круга лиц к которым предоставлен субъектом
                  персональных данных либо по его просьбе (далее - персональные
                  данные, сделанные общедоступными субъектом персональных
                  данных);
                </li>
                <li>
                  осуществляется обработка персональных данных, подлежащих
                  опубликованию или обязательному раскрытию в соответствии с
                  федеральным законом.
                </li>
              </ul>
            </p>
            <p>
              4.3.3. Специальные категории персональных данных, касающихся
              расовой, национальной принадлежности, политических взглядов,
              религиозных или философских убеждений, состояния здоровья,
              интимной жизни Обществом не обрабатываются, но могут
              обрабатываться в следующих случаях:
              <ul>
                <li>
                  субъект персональных данных дал согласие в письменной форме на
                  обработку своих персональных данных;
                </li>
                <li>
                  персональные данные сделаны общедоступными субъектом
                  персональных данных;
                </li>
                <li>
                  обработка персональных данных осуществляется в соответствии с
                  Федеральным законом от 25 января 2002 года N 8-ФЗ «О
                  Всероссийской переписи населения»;
                </li>
                <li>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством о государственной социальной помощи,
                  трудовым законодательством, пенсионным законодательством
                  Российской Федерации;
                </li>
                обработка персональных данных необходима для защиты жизни,
                здоровья или иных жизненно важных интересов субъекта
                персональных данных либо жизни, здоровья или иных жизненно
                важных интересов других лиц и получение согласия субъекта
                персональных данных невозможно;
                <li>
                  обработка персональных данных необходима для установления или
                  осуществления прав субъекта персональных данных или третьих
                  лиц, а равно и в связи с осуществлением правосудия;
                </li>
                <li>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством Российской Федерации об обороне, о
                  безопасности, о противодействии терроризму, о транспортной
                  безопасности, о противодействии коррупции, об
                  оперативно-розыскной деятельности, об исполнительном
                  производстве, уголовно-исполнительным законодательством
                  Российской Федерации;
                </li>
                обработка полученных в установленных законодательством
                Российской Федерации случаях персональных данных осуществляется
                органами прокуратуры в связи с осуществлением ими прокурорского
                надзора;
                <li>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством об обязательных видах страхования, со
                  страховым законодательством;
                </li>
                <li>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством Российской Федерации о гражданстве
                  Российской Федерации.
                </li>
              </ul>
            </p>
            <p>
              4.3.4. Обществом может осуществляться обработка персональных
              данных о судимости в случаях и в порядке, которые определяются в
              соответствии с законодательством Российской Федерации.
            </p>
            <p>
              4.3.5. Обработка специальных категорий персональных данных,
              осуществлявшаяся в случаях, предусмотренных пунктами 4.3.3. и
              4.3.4., незамедлительно прекращается Обществом, если устранены
              причины, вследствие которых осуществлялась обработка, если иное не
              установлено федеральным законом.
            </p>
            <p>
              4.3.6. Сведения, которые характеризуют физиологические и
              биологические особенности человека, на основании которых можно
              установить его личность (биометрические персональные данные)
              Обществом не обрабатываются
            </p>
            <p>
              4.3.7. При принятии решений, затрагивающих интересы субъекта
              персональных данных, Общество не имеет права основываться на
              персональных данных, полученных исключительно в результате их
              автоматизированной обработки.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ПРАВОВОЕ ОСНОВАНИЕ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</em>
          <div className="box">
            <p>
              Правовым основанием обработки персональных данных работников,
              клиентов и других лиц являются требования:
              <ul>
                <li>Конституции Российской Федерации;</li>
                <li>Трудового кодекса Российской Федерации;</li>
                <li>Гражданского кодекса Российской Федерации;</li>
                <li>Налогового кодекса Российской Федерации;</li>
                <li>
                  Кодекса Российской Федерации об административных
                  правонарушениях;
                </li>
                <li>
                  Федерального закона от 01 апреля 1996 года № 27-ФЗ «Об
                  индивидуальном (персонифицированном) учете в системе
                  обязательного пенсионного страхования»;
                </li>
                <li>
                  Федерального закона от 22 октября 2004 года № 125-ФЗ «Об
                  архивном деле в Российской Федерации»;
                </li>
                <li>
                  Федерального закона от 19 декабря 2005 года № 160-ФЗ «О
                  ратификации Конвенции Совета Европы о защите физических лиц
                  при автоматизированной обработке персональных данных»;
                </li>
                <li>
                  Федерального закона от 27 июля 2006 года № 152-ФЗ «О
                  персональных данных»;
                </li>
                <li>
                  Федерального закона от 27 июля 2006 года № 149-ФЗ «Об
                  информации, информационных технологиях и о защите информации»;
                </li>
                <li>Устава Общества;</li>
                <li>
                  Других нормативных и правовых актов Российской Федерации;
                </li>
                <li>Других локальных нормативных и правовых актов Общества.</li>
              </ul>
            </p>
            <p>
              Правовое основание обработки персональных данных каждой категории
              субъектов персональных данных, а также сроки хранения документов,
              содержащих персональные данные, определяются отдельным приказом
              Директора Общества.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ПОНЯТИЕ И СОСТАВ ПЕРСОНАЛЬНЫХ ДАННЫХ</em>
          <div className="box">
            <p>
              6.1. Персональные данные включают в себя:
              <ul>
                <li>Фамилия, имя, отчество;</li>
                <li>Пол;</li>
                <li>Дата рождения (число/месяц/год);</li>
                <li>Место рождения;</li>
                <li>Адрес регистрации;</li>
                <li>Адрес места жительства;</li>
                <li>
                  Контактная информация (номер телефона, электронный адрес);
                </li>
                <li>Гражданство;</li>
                <li>Состояние в браке;</li>
                <li>Состав семьи;</li>
                <li>Статус семьи;</li>
                <li>Фотографическое изображение;</li>
                <li>Паспортные данные (серия/номер/дата/кем выдан);</li>
                <li>Идентификационный номер налогоплательщика;</li>
                <li>
                  Страховой номер индивидуального лицевого счета застрахованного
                  лица;
                </li>
                <li>
                  Сведения об образовании (уровень образования, наименование
                  учебного заведения, год окончания, номер диплома,
                  специальность/квалификация по диплому, форма профессионального
                  послевузовского образования и т.д.);
                </li>
                <li>
                  Ученая степень и ученое звание, даты присвоения
                  степени/звания;
                </li>
                <li>Сведения о медицинской книжке;</li>
                <li>Сведения о квалификационной категории;</li>
                <li>Сведения о сертификате специалиста;</li>
                <li>Сведения о повышении квалификации;</li>
                <li>Общий трудовой стаж;</li>
                <li>Данные о предыдущих местах работы;</li>
                <li>Дата и причина увольнения с последнего места работы;</li>
                <li>Сведения о социальных льготах;</li>
                <li>Сведения о поощрениях и наградах;</li>
                <li>Сведения о временной нетрудоспособности;</li>
                <li>Место работы;</li>
                <li>Должность работника;</li>
                <li>Табельный номер работника;</li>
                <li>Сведения об испытательном сроке работника;</li>
                <li>Данные по отпускам;</li>
                <li>Статус военнообязанного;</li>
                <li>Воинское звание;</li>
                <li>Сведения об инвалидности;</li>
                <li>Сведения о несчастных случаях;</li>
                <li>Сведения о судимости;</li>
                <li>Сведения о знании иностранного языка;</li>
                <li>Данные свидетельства о смерти;</li>
                <li>
                  Данные о начисленных суммах (тарифная ставка (оклад),
                  надбавки, премии, доплаты, вознаграждения и т.д.);
                </li>
                <li>Тип и сумма налогового вычета;</li>
                <li>Данные о суммах удержаний из заработной платы;</li>
                <li>Сведения о командировках;</li>
                <li>Сведения о выданных подотчетных суммах;</li>
                <li>Реквизиты лицевого счета;</li>
                <li>
                  Информация об оказанных услугах, в т.ч. стоимости оказанных
                  услуг.
                </li>
              </ul>
            </p>
            <p>
              6.2. Общество осуществляет обработку персональных данных следующих
              категорий субъектов:
              <ul>
                <li>работников Общества;</li>
                <li>клиентов Общества;</li>
                <li>других лиц.</li>
              </ul>
            </p>
            <p>
              6.3. Информация о персональных данных может содержаться:
              <ul>
                <li>на бумажных носителях;</li>
                <li>на электронных носителях;</li>
                <li>в информационных системах персональных данных;</li>
                <li>
                  в информационно-телекоммуникационных сетях и иных
                  информационных системах.
                </li>
              </ul>
            </p>
            <p>
              6.4. Общество самостоятельно устанавливает способы обработки
              персональных данных в зависимости от целей такой обработки и
              материально-технических возможностей Общества.
            </p>
            <p>
              6.5. При обработке персональных данных с использованием средств
              вычислительной техники должностные лица Общества, осуществляющие
              такую обработку, должны быть ознакомлены под роспись с основными
              локальными нормативными актами Общества.
            </p>
            <p>
              6.6. Персональные данные работников Общества содержатся в
              следующих документах (копиях указанных документов):
              <ul>
                <li>анкеты работников;</li>
                <li>
                  обращения (заявления, жалобы, предложения, служебные записки);
                </li>
                <li>личные карточки работника (форма Т-2);</li>
                <li>
                  копии страховых свидетельств обязательного пенсионного
                  страхования;
                </li>
                <li>
                  копии свидетельств о постановке на учет в налоговом органе;
                </li>
                <li>
                  документы об образовании и повышении квалификации (копии);
                </li>
                <li>
                  трудовые договоры (в том числе дополнительные соглашения к
                  трудовым договорам, журнал регистрации трудовых договоров);
                </li>
                <li>
                  трудовые книжки (в том числе книга учета движения трудовых
                  книжек, приходно-расходная книга по учету бланков трудовых
                  книжек и вкладышей к ним);
                </li>
                <li>журналы регистрации по охране труда;</li>
                <li>приказы; </li>
                <li>доверенности;</li>
                <li>
                  графики отпусков (в том числе журналы учета приказов по
                  отпускам);
                </li>
                <li>листки нетрудоспособности;</li>
                <li>табели учета рабочего времени;</li>
                <li>расчетные листки;</li>
                <li>
                  сведения о гражданине, подлежащем воинскому учету, и принятии
                  его на работу (увольнении его с работы);
                </li>
                <li>
                  обязательства о неразглашении сведений конфиденциального
                  характера;
                </li>
                <li>согласия работника на обработку персональных данных;</li>
                <li>служебные задания для направления в командировку;</li>
                <li>авансовые отчеты;</li>
                <li>расчетные ведомости по заработной плате;</li>
                <li>
                  реестры сведений о начисленных и уплаченных страховых взносах
                  на обязательное пенсионное страхование застрахованных лиц
                  (форма СЗВ-6-2);
                </li>
                <li>
                  справки о сумме заработной платы, иных выплат и
                  вознаграждений, на которую были начислены страховые взносы на
                  обязательное социальное страхование на случай временной
                  нетрудоспособности и в связи с материнством, за два
                  календарных года, предшествующих году прекращения работы
                  (службы, иной деятельности) или году обращения за справкой, и
                  текущий календарный год;
                </li>
                <li>справки о доходах физического лица (форма 2-НДФЛ);</li>
                <li>военные билеты (копии);</li>
                <li>другие документы, содержащие персональные данные.</li>
              </ul>
            </p>
            <p>
              6.7. Персональные данные клиентов могут содержаться в электронном
              виде информационной системы обработки заказов:
              <ul>
                <li>web-приложение оформления заказов;</li>
                <li>система автоматизации ресторанного бизнеса.</li>
              </ul>
            </p>
            <p>
              6.8. Персональные данные других лиц могут содержаться в следующих
              документах (копиях указанных документов):
              <ul>
                <li>резюме кандидатов на работу;</li>
                <li>анкеты кандидатов на работу;</li>
                <li>доверенности;</li>
                <li>
                  обращения (заявления, жалобы, предложения) физических лиц;
                </li>
                <li>другие документы, содержащие персональные данные.</li>
              </ul>
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</em>
          <div className="box">
            <p>7.1. Действия (операции) с персональными данными.</p>
            <p>
              Обществом совершаются следующие действия (операции) или
              совокупность действий (операций) с персональными данными субъектов
              персональных данных: сбор, запись, систематизацию, накопление,
              хранение, уточнение (обновление, изменение), извлечение,
              использование, передачу (распространение, предоставление, доступ),
              обезличивание, блокирование, удаление, уничтожение персональных
              данных.
            </p>
            <p>
              Общество не распространяет персональные данные работников
              Общества, клиентов Общества и других лиц, то есть не осуществляет
              действия, направленные на раскрытие персональных данных
              неопределенному кругу лиц.
            </p>
            <p>
              7.2. Общество использует следующие способы обработки персональных
              данных:
              <ul>
                <li>
                  >с использованием средств вычислительной техники
                  (автоматизированная обработка);
                </li>
                <li>
                  >без использования средств вычислительной техники
                  (неавтоматизированная обработка).
                </li>
              </ul>
            </p>
            <p>7.3. Создание персональных данных.</p>
            <p>
              7.3.1. Документы, в том числе и в электронном виде, содержащие
              персональные данные, создаются путем:
              <ul>
                <li>получения оригиналов необходимых документов;</li>
                <li>копирования оригиналов документов;</li>
                <li>
                  внесения сведений в учётные формы (на бумажных и электронных
                  носителях), в том числе в электронные формы.
                </li>
              </ul>
            </p>
            <p>
              7.3.2. Общество получает персональные данные субъектов
              персональных данных:
              <ul>
                <li>непосредственно от самих субъектов персональных данных;</li>
                <li>
                  от третьих лиц (например, федеральных органов исполнительной
                  власти, муниципальных органов, судов общей юрисдикции;
                  органов, осуществляющих контрольные функции по взысканию
                  денежных средств; органов уголовно-исполнительной системы);
                </li>
                <li>
                  от контрагентов Общества оказывающих услуги по переводу
                  денежных средств и прочих контрагентов, оказывающих
                  информационно-технологическое взаимодействие в процессе
                  перевода денежных средств.
                </li>
              </ul>
            </p>
            <p>
              7.3.3. При сборе персональных данных Общество предоставляет
              субъекту персональных данных по его просьбе следующую информацию:
              <ul>
                <li>
                  подтверждение факта обработки персональных данных Обществом;
                </li>
                <li>
                  правовые основания и цели обработки персональных данных;
                </li>
                <li>
                  цели и применяемые Обществом способы обработки персональных
                  данных;
                </li>
                <li>
                  наименование и местонахождение Общества, сведения о лицах (за
                  исключением работников Общества), которые имеют доступ к
                  персональным данным или которым могут быть раскрыты
                  персональные данные на основании договора с Обществом или на
                  основании федерального закона;
                </li>
                <li>
                  обрабатываемые персональные данные, относящиеся к субъекту
                  персональных данных, источник их получения, если иной порядок
                  представления таких данных не предусмотрен федеральным
                  законом;
                </li>
                <li>
                  сроки обработки персональных данных, в том числе сроки их
                  хранения;
                </li>
                <li>
                  порядок осуществления субъектом персональных данных прав,
                  предусмотренных Федеральным законом от 27 июля 2006 года №
                  152-ФЗ «О персональных данных»;
                </li>
                <li>
                  информацию об осуществленной или о предполагаемой
                  трансграничной передаче данных;
                </li>
                <li>
                  наименование или фамилию, имя, отчество и адрес лица,
                  осуществляющего обработку персональных данных по поручению
                  Общества, если обработка поручена или будет поручена такому
                  лицу;
                </li>
                <li>
                  иные сведения, предусмотренные федеральными законами
                  Российской Федерации.
                </li>
              </ul>
            </p>
            <p>
              7.3.4. Общество вправе ограничить право субъекта персональных
              данных на доступ к его персональным данным в соответствии с
              федеральными законами, в том числе, если:
              <ul>
                <li>
                  обработка персональных данных, включая персональные данные,
                  полученные в результате оперативно-розыскной,
                  контрразведывательной и разведывательной деятельности,
                  осуществляется в целях обороны страны, безопасности
                  государства и охраны правопорядка;
                </li>
                <li>
                  обработка персональных данных осуществляется органами,
                  осуществившими задержание субъекта персональных данных по
                  подозрению в совершении преступления, либо предъявившими
                  субъекту персональных данных обвинение по уголовному делу,
                  либо применившими к субъекту персональных данных меру
                  пресечения до предъявления обвинения, за исключением
                  предусмотренных уголовно-процессуальным законодательством
                  Российской Федерации случаев, если допускается ознакомление
                  подозреваемого или обвиняемого с такими персональными данными;
                </li>
                <li>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством о противодействии легализации (отмыванию)
                  доходов, полученных преступным путем, и финансированию
                  терроризма;
                </li>
                <li>
                  доступ субъекта персональных данных к его персональным данным
                  нарушает права и законные интересы третьих лиц;
                </li>
                <li>
                  обработка персональных данных осуществляется в случаях,
                  предусмотренных законодательством Российской Федерации о
                  транспортной безопасности, в целях обеспечения устойчивого и
                  безопасного функционирования транспортного комплекса, защиты
                  интересов личности, общества и государства в сфере
                  транспортного комплекса от актов незаконного вмешательства.
                </li>
              </ul>
            </p>
            <p>
              7.3.5. Если персональные данные получены не от субъекта
              персональных данных, Общество, до начала обработки таких
              персональных данных предоставляет субъекту персональных данных
              следующую информацию:
              <ul>
                <li>наименование и адрес Общества;</li>
                <li>
                  цель обработки персональных данных и ее правовое основание;
                </li>
                <li>предполагаемые пользователи персональных данных;</li>
                <li>права субъекта персональных данных;</li>
                <li>источник получения персональных данных.</li>
              </ul>
            </p>
            <p>
              Общество освобождается от обязанности предоставить субъекту
              персональных данных указанные сведения в случаях, если:
              <ul>
                <li>
                  субъект персональных данных уведомлен об осуществлении
                  обработки его персональных данных Обществом;
                </li>
                <li>
                  персональные данные получены Обществом на основании
                  федерального закона или в связи с исполнением договора,
                  стороной которого либо выгодоприобретателем или поручителем по
                  которому является субъект персональных данных;
                </li>
                <li>
                  персональные данные сделаны общедоступными субъектом
                  персональных данных или получены из общедоступного источника;
                </li>
                <li>
                  предоставление субъекту персональных данных сведений нарушает
                  права и законные интересы третьих лиц.
                </li>
              </ul>
            </p>
            <p>
              7.4. Общество предоставляет персональные данные субъектов
              персональных данных:
              <ul>
                <li>непосредственно самим субъектам персональных данных;</li>
                <li>
                  кредитным организациям, открывающим и обслуживающим платежные
                  карты для начисления заработной платы; предоставление
                  персональных данных субъектов в кредитные организации
                  осуществляется только при наличии письменного согласия
                  субъекта на передачу его персональных данных в банковское
                  учреждение;
                </li>
                <li>
                  контрагентам Общества, оказывающим услуги по переводу денежных
                  средств и прочим контрагентам, оказывающим
                  информационно-технологическое взаимодействие в процессе
                  перевода денежных средств;
                </li>
                <li>
                  контрагентам Общества, оказывающим услуги по доставке заказов
                  клиенту;
                </li>
                <li>
                  контрагентам Общества, оказывающим
                  информационно-технологическое взаимодействие на основании
                  договоров, заключенных между Обществом и контрагентами;
                </li>
                <li>
                  третьим лицам (например, в инспекцию ФНС России, Управление ПФ
                  РФ, военный комиссариат, другие контролирующие органы).
                </li>
              </ul>
            </p>
            <p>
              7.5. В целях информационного обеспечения Общество может включать
              персональные данные работников (фамилию, имя, отчество, должность,
              контактные телефоны (рабочие)) в справочники и списки Общества.
              Персональные данные работников Общества включаются в справочники и
              списки Общества только с письменного согласия работников.
            </p>
            <p>7.6. Трансграничная передача персональных данных.</p>
            <p>
              Трансграничная передача обрабатываемых персональных данных
              субъектов персональных данных, Обществом не осуществляется.
            </p>
            <p>7.7. Сроки обработки персональных данных.</p>
            <p>
              7.7.1. Общий срок обработки персональных данных определяется
              периодом времени, в течение которого Общество осуществляет
              действия (операции) в отношении персональных данных, обусловленные
              заявленными целями их обработки, в том числе хранение персональных
              данных.
            </p>
            <p>
              7.7.2. Обработка персональных данных начинается с момента их
              получения Обществом и заканчивается:
              <ul>
                <li>
                  по достижении конкретных, заранее определенных и законных
                  целей;
                </li>
                <li>
                  по факту утраты необходимости в достижении заранее заявленных
                  целей
                </li>
              </ul>
            </p>
            <p>
              7.7.3. Общество осуществляет хранение персональных данных в форме,
              позволяющей определить субъекта персональных данных, не дольше,
              чем этого требуют цели обработки персональных данных, если срок
              хранения персональных данных не установлен федеральным законом,
              договором, стороной которого, выгодоприобретателем или поручителем
              по которому является субъект персональных данных.
            </p>
            <p>
              7.7.4. Сроки хранения персональных данных Обществом определяются с
              учетом требований действующих нормативных и правовых актов
              Российской Федерации и локальных документов Общества.
            </p>
            <p>
              7.8. При сборе персональных данных, в том числе посредством
              информационно-телекоммуникационной сети «Интернет», Общество
              обеспечивает запись, систематизацию, накопление, хранение,
              уточнение (обновление, изменение), извлечение персональных данных
              граждан Российской Федерации с использованием баз данных,
              находящихся на территории Российской Федерации.
            </p>
            <p>
              7.9. Обработка персональных данных в целях продвижения товаров,
              работ, услуг Обществом не осуществляется.{' '}
            </p>
            <p>
              7.10. Согласие субъекта персональных данных на обработку его
              персональных данных.
            </p>
            <p>
              7.10.1. Субъект персональных данных принимает решение о
              предоставлении его персональных данных и дает согласие Обществу на
              их обработку свободно, своей волей и в своем интересе. Согласие на
              обработку персональных данных должно быть конкретным,
              информированным и сознательным. Согласие на обработку персональных
              данных может быть дано субъектом персональных данных или его
              представителем в любой позволяющей подтвердить факт его получения
              форме, если иное не установлено федеральным законом. В случае
              получения согласия на обработку персональных данных от
              представителя субъекта персональных данных, полномочия данного
              представителя на дачу согласия от имени субъекта персональных
              данных проверяются Обществом.
            </p>
            <p>
              7.10.2. Согласие на обработку персональных данных может быть
              отозвано субъектом персональных данных. В случае отзыва субъектом
              персональных данных согласия на обработку персональных данных,
              Общество вправе продолжить обработку персональных данных без
              согласия субъекта персональных данных при наличии соответствующих
              оснований, предусмотренных действующим законодательством в области
              персональных данных.
            </p>
            <p>
              7.10.3. Обязанность предоставить доказательство получения согласия
              субъекта персональных данных на обработку его персональных данных
              или доказательство наличия оснований, предусмотренных действующим
              законодательством в области персональных данных, возлагается на
              Общество.
            </p>
            <p>
              7.10.4. Обработка персональных данных клиентов осуществляется
              только с согласия субъекта персональных данных, выраженном на
              сайте Общества.
            </p>
            <p>
              В случаях, предусмотренных федеральным законом, обработка
              персональных данных работников осуществляется только с согласия в
              письменной форме субъекта персональных данных. Равнозначным
              содержащему собственноручную подпись субъекта персональных данных
              согласию в письменной форме на бумажном носителе признается
              согласие в форме электронного документа, подписанного в
              соответствии с федеральным законом от 06 апреля 2011 года № 63-ФЗ
              «Об электронной подписи» электронной подписью. Согласие субъекта
              персональных данных на обработку его персональных данных должно
              включать в себя, в частности:
              <ul>
                <li>
                  фамилию, имя, отчество, адрес субъекта персональных данных,
                  номер основного документа, удостоверяющего его личность,
                  сведения о дате выдачи указанного документа и выдавшем его
                  органе;
                </li>
                <li>
                  фамилию, имя, отчество, адрес представителя субъекта
                  персональных данных, номер основного документа,
                  удостоверяющего его личность, сведения о дате выдачи
                  указанного документа и выдавшем его органе, реквизиты
                  доверенности или иного документа, подтверждающего полномочия
                  этого представителя (при получении согласия от представителя
                  субъекта персональных данных);
                </li>

                <li>
                  наименование и адрес Общества, получающего согласие субъекта
                  персональных данных;
                </li>
                <li>цель обработки персональных данных;</li>
                <li>
                  перечень персональных данных, на обработку которых дается
                  согласие субъекта персональных данных;
                </li>
                <li>
                  наименование или фамилию, имя, отчество и адрес лица,
                  осуществляющего обработку персональных данных по поручению
                  Общества, если обработка будет поручена такому лицу;
                </li>
                <li>
                  перечень действий с персональными данными, на совершение
                  которых дается согласие, общее описание используемых Обществом
                  способов обработки персональных данных;
                </li>
                <li>
                  срок, в течение которого действует согласие субъекта
                  персональных данных, а также способ его отзыва, если иное не
                  установлено федеральным законом;
                </li>
                <li>подпись субъекта персональных данных.</li>
              </ul>
            </p>
            <p>
              7.10.5. В случае недееспособности субъекта персональных данных
              согласие на обработку его персональных данных дает законный
              представитель субъекта персональных данных.
            </p>
            <p>
              7.10.6. В случае смерти субъекта персональных данных согласие на
              обработку его персональных данных дают наследники субъекта
              персональных данных, если такое согласие не было дано субъектом
              персональных данных при его жизни.
            </p>
            <p>
              7.10.7. Персональные данные могут быть получены Обществом от лица,
              не являющегося субъектом персональных данных, при условии
              предоставления Обществу подтверждения наличия соответствующих
              оснований, предусмотренных действующим законодательством в области
              персональных данных.
            </p>
            <p>7.11. Доступ к персональным данным.</p>
            <p>
              7.11.1. Доступ к персональным данным работников и клиентов
              Общества, а также других лиц, имеют должностные лица Общества,
              непосредственно использующие эти данные в целях и объемах, которые
              необходимы для выполнения своих должностных обязанностей.
            </p>
            <p>
              7.11.2. Доступ к обрабатываемым Обществом персональным данным
              разрешается другим лицам и третьим лицам только при наличии
              официального заявления запросившего лица с указанием перечня
              необходимой информации, целей для которых она будет использована,
              с согласия субъекта персональных данных, персональные данные
              которого затребованы.
            </p>
            <p>
              7.11.3. Сообщение сведений о персональных данных субъекта
              персональных данных его родственникам, членам семьи, иным близким
              ему людям также производится Обществом только при наличии согласия
              субъекта персональных данных.
            </p>
            <p>
              7.11.4. При передаче персональных данных другим лицам, третьим
              лицам, в том числе представителям субъектов персональных данных, в
              порядке, установленном нормативными правовыми актами Российской
              Федерации и настоящим Положением, передаваемая информация
              ограничивается только теми персональными данными, которые
              необходимы для выполнения другими лицами и третьими лицами их
              функций.
            </p>
            <p>
              7.11.5. Субъект персональных данных, о котором запрашиваются
              сведения, относящиеся к персональным данным, должен быть уведомлён
              о передаче его персональных данных другим лицам и третьим лицам.
            </p>
            <p>
              7.11.6. Запрещается передача персональных данных, в том числе их
              распространение, предоставление и доступ к ним в коммерческих
              целях без согласия субъекта персональных данных, а также иное
              использование персональных данных в неслужебных целях.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ПРАВА СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</em>
          <div className="box">
            <p>
              Субъекты персональных данных имеют право:
              <ul>
                <li>
                  получать информацию, касающуюся обработки его персональных
                  данных;
                </li>
                <li>
                  обратиться повторно к Обществу или направить ему повторный
                  запрос в целях получения информации, касающейся обработки его
                  персональных данных, и ознакомления с такими персональными
                  данными не ранее чем через тридцать дней после первоначального
                  обращения или направления первоначального запроса, если более
                  короткий срок не установлен федеральным законом, принятым в
                  соответствии с ним нормативным правовым актом или договором,
                  стороной которого либо выгодоприобретателем или поручителем по
                  которому является субъект персональных данных;
                </li>
                <li>
                  требовать от Общества осуществить блокирование неправомерно
                  обрабатываемых персональных данных, относящихся к этому
                  субъекту персональных данных, или обеспечить их блокирование
                  (если обработка персональных данных осуществляется другим
                  лицом, действующим по поручению Общества);
                </li>
                <li>
                  ознакомиться со сведениями, содержащими свои персональные
                  данные, включая право получать копии любой записи, содержащей
                  персональные данные, за исключением случаев, предусмотренных
                  федеральным законом;
                </li>
                <li>
                  требовать от Общества, в случае подтверждения факта неточности
                  персональных данных, уточнения персональных данных;
                </li>
                <li>
                  требовать от Общества, в случае достижения цели обработки
                  персональных данных, прекращения обработки персональных
                  данных;
                </li>
                <li>
                  требовать от Общества, в случае отзыва согласия на обработку
                  персональных данных, прекращения их обработки или обеспечения
                  прекращения такой обработки;
                </li>
                <li>
                  обжаловать в уполномоченный орган по защите прав субъектов
                  персональных данных или в суд любые неправомерные действия или
                  бездействие Общества при обработке его персональных данных.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>УСЛОВИЯ ОБЕСПЕЧЕНИЯ КОНФИДЕНЦИАЛЬНОСТИ ИНФОРМАЦИИ</em>
          <div className="box">
            <p>
              9.1. Должностные лица Общества, получившие доступ к персональным
              данным, обязаны не раскрывать третьим лицам и не распространять
              персональные данные без согласия субъекта персональных данных,
              если иное не предусмотрено действующим федеральным
              законодательством.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>
            МЕРЫ, ПРИНИМАЕМЫЕ ОБЩЕСТВОМ, ДЛЯ ВЫПОЛНЕНИЯ ТРЕБОВАНИЙ
            ЗАКОНОДАТЕЛЬСТВА В ОБЛАСТИ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </em>
          <div className="box">
            <p>
              10.1. Общество самостоятельно определяет состав и перечень мер,
              необходимых и достаточных для обеспечения выполнения обязанностей,
              предусмотренных действующим законодательством в области
              персональных данных.
            </p>
            <p>
              10.2. Общество при обработке персональных данных принимает
              следующие меры необходимые и достаточные для обеспечения
              выполнения своих обязанностей, предусмотренных действующим
              законодательством в области персональных данных:
              <ul>
                <li>
                  назначение ответственного лица за организацию обработки
                  персональных данных;
                </li>
                <li>
                  издание документов, определяющих политику Общества в отношении
                  обработки персональных данных, локальных актов по вопросам
                  обработки персональных данных, а также локальных актов,
                  устанавливающих процедуры, направленные на предотвращение и
                  выявление нарушений законодательства Российской Федерации,
                  устранение последствий таких нарушений;
                </li>
                <li>
                  применение правовых, организационных и технических мер по
                  обеспечению безопасности персональных данных;
                </li>
                <li>
                  осуществление внутреннего контроля и (или) аудита соответствия
                  обработки персональных данных действующему законодательству в
                  области персональных данных, требованиям к защите персональных
                  данных, политике Общества в отношении обработки персональных
                  данных, локальным актам Общества;
                </li>
                <li>
                  оценка вреда, который может быть причинен субъектам
                  персональных данных в случае нарушения действующего
                  законодательства в области персональных данных, соотношение
                  указанного вреда и принимаемых Обществом мер, направленных на
                  обеспечение выполнения своих обязанностей;
                </li>
                <li>
                  ознакомление работников Общества, непосредственно
                  осуществляющих обработку персональных данных, с положениями
                  законодательства Российской Федерации о персональных данных, в
                  том числе требованиями к защите персональных данных,
                  документами, определяющими политику Общества в отношении
                  обработки персональных данных, локальными актами по вопросам
                  обработки персональных данных, и (или) обучение указанных
                  работников.
                </li>
              </ul>
            </p>
            <p>
              10.3. Общество при обработке персональных данных принимает
              необходимые правовые, организационные и технические меры или
              обеспечивает их принятие для защиты персональных данных от
              неправомерного или случайного доступа к ним, уничтожения,
              изменения, блокирования, копирования, предоставления,
              распространения персональных данных, а также от иных неправомерных
              действий в отношении персональных данных.
            </p>
            <p>
              10.4. Общество, при обработке персональных данных, осуществляемой
              без использования средств автоматизации, принимает следующие меры
              по обеспечению безопасности:
              <ul>
                <li>
                  определен перечень лиц, осуществляющих обработку персональных
                  данных либо имеющих доступ к ним;
                </li>
                <li>
                  в отношении каждой категории персональных данных определены
                  места хранения персональных данных (материальных носителей)
                  утвержденных приказом Директора Общества;
                </li>
                <li>
                  персональные данные, содержащиеся на бумажных носителях,
                  хранятся в запираемых несгораемых шкафах или в запираемых
                  металлических сейфах;
                </li>
                <li>
                  выдача ключей от шкафов, сейфов производится Директором
                  Общества, а на период его временного отсутствия – болезнь,
                  отпуск и т.п. – лицом, исполняющим его обязанности. Сдача
                  ключа осуществляется лично Директору;
                </li>
                <li>
                  хранение бумажных носителей, содержащих персональные данные,
                  осуществляется в течение установленных нормативными актами
                  сроков хранения. По истечении установленных сроков хранения,
                  бумажные носители, содержащие персональные данные сдаются в
                  архив либо подлежат уничтожению в порядке, предусмотренном
                  приказами по архивному делу;
                </li>
                <li>
                  персональные данные, содержащиеся на электронных носителях
                  информации, хранятся на жестких дисках серверов. Доступ к
                  серверам строго ограничен кругом лиц, ответственных за
                  обработку персональных данных.
                </li>
              </ul>
            </p>
            <p>
              10.5. Общество, при обработке персональных данных, осуществляемой
              с использованием средств автоматизации, принимает следующие меры
              по обеспечению безопасности:
              <ul>
                <li>
                  определение угроз безопасности персональных данных при их
                  обработке в информационных системах персональных данных;
                </li>
                <li>
                  определение уровня защищенности персональных данных при их
                  обработке в информационных системах персональных данных;
                </li>
                <li>
                  применение организационных и технических мер по обеспечению
                  безопасности персональных данных при их обработке в
                  информационных системах персональных данных, необходимых для
                  выполнения требований к защите персональных данных, исполнение
                  которых обеспечивает установленные Правительством Российской
                  Федерации уровни защищенности персональных данных;
                </li>
                <li>
                  применение прошедших в установленном порядке процедуру оценки
                  соответствия средств защиты информации;
                </li>
                <li>
                  оценку эффективности принимаемых мер по обеспечению
                  безопасности персональных данных до ввода в эксплуатацию
                  информационной системы персональных данных;
                </li>
                <li>учет машинных носителей персональных данных;</li>
                <li>
                  обнаружение фактов несанкционированного доступа к персональным
                  данным и принятие мер;
                </li>
                <li>
                  восстановление персональных данных, модифицированных или
                  уничтоженных вследствие несанкционированного доступа к ним;
                </li>
                <li>
                  установление правил доступа к персональным данным,
                  обрабатываемым в информационной системе персональных данных, а
                  также обеспечение регистрации и учета всех действий,
                  совершаемых с персональными данными в информационной системе
                  персональных данных;
                </li>
                <li>
                  контроль за принимаемыми мерами по обеспечению безопасности
                  персональных данных и уровня защищенности информационных
                  систем персональных данных.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>
            ПОРЯДОК РЕАГИРОВАНИЯ НА ОБРАЩЕНИЯ И ЗАПРОСЫ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ
            ДАННЫХ, ИХ ЗАКОННЫХ ПРЕДСТАВИТЕЛЕЙ И ЗАПРОСЫ НАДЗОРНЫХ ОРГАНОВ,
            ОСУЩЕСТВЛЯЮЩИХ КОНТРОЛЬ И НАДЗОР В ОБЛАСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </em>
          <div className="box">
            <p>
              11.1. Общество сообщает субъекту персональных данных (его
              представителю) информацию о наличии персональных данных,
              относящихся к соответствующему субъекту персональных данных, а
              также предоставляет возможность ознакомления с этими персональными
              данными при обращении субъекта персональных данных или его
              представителя либо в течение тридцати дней с даты получения
              запроса субъекта персональных данных или его представителя, в том
              числе содержащую:
              <ul>
                <li>
                  подтверждение факта обработки Обществом персональных данных;
                </li>
                <li>
                  правовые основания и цели обработки персональных данных;
                </li>
                <li>
                  цели и применяемые Обществом способы обработки персональных
                  данных;
                </li>
                <li>
                  наименование и место нахождения Общества, сведения о лицах (за
                  исключением работников Общества), которые имеют доступ к
                  персональным данным или которым могут быть раскрыты
                  персональные данные на основании договора с Обществом или на
                  основании федерального закона;
                </li>
                <li>
                  обрабатываемые персональные данные, относящиеся к
                  соответствующему субъекту персональных данных, источник их
                  получения, если иной порядок представления таких данных не
                  предусмотрен федеральным законом;
                </li>
                <li>
                  сроки обработки персональных данных, в том числе сроки их
                  хранения;
                </li>
                <li>
                  порядок осуществления субъектом персональных данных прав,
                  предусмотренных Федеральным законом от 27 июля 2006 года №
                  152-ФЗ «О персональных данных»;
                </li>
                <li>
                  информацию об осуществленной или о предполагаемой
                  трансграничной передаче данных;
                </li>
                <li>
                  наименование или фамилию, имя, отчество и адрес лица,
                  осуществляющего обработку персональных данных по поручению
                  Общества, если обработка поручена или будет поручена такому
                  лицу;
                </li>
                <li>
                  иные сведения, предусмотренные Федеральным законом от 27 июля
                  2006 года № 152-ФЗ «О персональных данных» или другими
                  федеральными законами.
                </li>
              </ul>
            </p>
            <p>
              11.2. Сведения, касающиеся обработки персональных данных,
              предоставляются Обществом субъекту персональных данных или его
              законному представителю при обращении указанных лиц либо получении
              от них запроса. Запрос субъекта персональных данных должен
              содержать номер основного документа, удостоверяющего личность
              субъекта персональных данных или его представителя, сведения о
              дате выдачи указанного документа и выдавшем его органе, сведения,
              подтверждающие участие субъекта персональных данных в отношениях с
              Обществом (номер договора, дату заключения договора, условное
              словесное обозначение и (или) иные сведения), либо сведения, иным
              образом подтверждающие факт обработки персональных данных
              Обществом, подпись субъекта персональных данных или его
              представителя. Запрос может быть направлен в форме электронного
              документа и подписан электронной подписью в соответствии с
              законодательством Российской Федерации.
            </p>
            <p>
              11.3. Сведения, касающиеся обработки персональных данных,
              предоставляются Обществом субъекту персональных данных (его
              представителю) в доступной форме, и в них не должны содержаться
              персональные данные, относящиеся к другим субъектам персональных
              данных, за исключением случаев, если имеются законные основания
              для раскрытия таких персональных данных.
            </p>
            <p>
              11.4. Общество вправе отказать субъекту персональных данных в
              доступе к его персональным данным в соответствии с федеральными
              законами, в том числе если:
              <ul>
                <li>
                  обработка персональных данных, включая персональные данные,
                  полученные в результате оперативно-розыскной,
                  контрразведывательной и разведывательной деятельности,
                  осуществляется в целях обороны страны, безопасности
                  государства и охраны правопорядка;
                </li>
                <li>
                  обработка персональных данных осуществляется органами,
                  осуществившими задержание субъекта персональных данных по
                  подозрению в совершении преступления, либо предъявившими
                  субъекту персональных данных обвинение по уголовному делу,
                  либо применившими к субъекту персональных данных меру
                  пресечения до предъявления обвинения, за исключением
                  предусмотренных уголовно-процессуальным законодательством
                  Российской Федерации случаев, если допускается ознакомление
                  подозреваемого или обвиняемого с такими персональными данными;
                </li>
                <li>
                  обработка персональных данных осуществляется в соответствии с
                  законодательством о противодействии легализации (отмыванию)
                  доходов, полученных преступным путем, и финансированию
                  терроризма;
                </li>
                <li>
                  доступ субъекта персональных данных к его персональным данным
                  нарушает права и законные интересы третьих лиц;
                </li>
                <li>
                  обработка персональных данных осуществляется в случаях,
                  предусмотренных законодательством Российской Федерации о
                  транспортной безопасности, в целях обеспечения устойчивого и
                  безопасного функционирования транспортного комплекса, защиты
                  интересов личности, общества и государства в сфере
                  транспортного комплекса от актов незаконного вмешательства.
                </li>
              </ul>
            </p>
            <p>
              11.5. В случае отказа в предоставлении информации о наличии
              персональных данных о соответствующем субъекте персональных данных
              или персональных данных субъекту персональных данных или его
              представителю при их обращении либо при получении запроса субъекта
              персональных данных или его представителя, Общество дает в
              письменной форме мотивированный ответ, содержащий ссылку на
              положение части 8 статьи 14 Федерального закона от 27 июля 2006
              года № 152-ФЗ «О персональных данных» или иного федерального
              закона, являющееся основанием для такого отказа, в срок, не
              превышающий тридцати дней со дня обращения субъекта персональных
              данных или его представителя либо с даты получения запроса
              субъекта персональных данных или его представителя.
            </p>
            <p>
              11.6. Обязанности по организации приема и обработки обращений и
              запросов субъектов персональных данных или их представителей и
              (или) осуществление контроля за приемом и обработкой таких
              обращений и запросов возлагаются на ответственного за организацию
              обработки персональных данных в Обществе.
            </p>
            <p>
              11.7. По запросу Уполномоченного органа по защите прав субъектов
              персональных данных Общество представляет информацию, необходимую
              для реализации Уполномоченным органом своих полномочий, в том
              числе документы и локальные акты по вопросам обработки
              персональных данных, и (или) иным образом подтверждает принятие
              мер, направленных на обеспечение выполнения обязанностей,
              предусмотренных Федеральным законом от 27 июля 2006 года № 152-ФЗ
              «О персональных данных» и принятыми в соответствии с ним
              нормативными правовыми актами.
            </p>
            <p>
              11.8. Поступившие Обществу запросы Уполномоченного органа по
              защите прав субъектов персональных данных и иных надзорных
              органов, осуществляющих контроль и надзор в области персональных
              данных, передаются для рассмотрения и подготовки проекта ответа
              Директору Общества. В случае, если для подготовки проекта ответа
              требуется участие иных работников Общества, ответственный за
              организацию обработки персональных данных Общества вправе
              привлекать к работе по составлению указанного документа
              соответствующих работников. Ответ на запрос с соответствующими
              обосновывающими материалами подписывается Директором Общества.
              Ответ на запрос Уполномоченного органа по защите прав субъектов
              персональных данных (иного надзорного органа) направляется в
              течение тридцати дней с даты получения запроса.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>
            ОБЯЗАННОСТИ ОБЩЕСТВА ПО УСТРАНЕНИЮ НАРУШЕНИЙ ЗАКОНОДАТЕЛЬСТВА,
            ДОПУЩЕННЫХ ПРИ ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ ДАННЫХ, ПО УТОЧНЕНИЮ,
            БЛОКИРОВАНИЮ И УНИЧТОЖЕНИЮ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </em>
          <div className="box">
            <p>
              12.1. В случае выявления неправомерной обработки персональных
              данных при обращении субъекта персональных данных или его
              представителя либо по запросу субъекта персональных данных или его
              представителя либо Уполномоченного органа по защите прав субъектов
              персональных данных Общество осуществляет блокирование
              неправомерно обрабатываемых персональных данных, относящихся к
              этому субъекту персональных данных, или обеспечивает их
              блокирование (если обработка персональных данных осуществляется
              другим лицом, действующим по поручению Общества) с момента такого
              обращения или получения указанного запроса на период проверки. В
              случае выявления неточных персональных данных при обращении
              субъекта персональных данных или его представителя либо по их
              запросу или по запросу Уполномоченного органа по защите прав
              субъектов персональных данных Общество осуществляет блокирование
              персональных данных, относящихся к этому субъекту персональных
              данных, или обеспечивает их блокирование (если обработка
              персональных данных осуществляется другим лицом, действующим по
              поручению Общества) с момента такого обращения или получения
              указанного запроса на период проверки, если блокирование
              персональных данных не нарушает права и законные интересы субъекта
              персональных данных или третьих лиц.
            </p>
            <p>
              12.2. В случае подтверждения факта неточности персональных данных,
              Общество, на основании сведений, представленных субъектом
              персональных данных или его представителем либо Уполномоченным
              органом по защите прав субъектов персональных данных, или иных
              необходимых документов уточняет персональные данные либо
              обеспечивает их уточнение (если обработка персональных данных
              осуществляется другим лицом, действующим по поручению Общества) в
              течение семи рабочих дней со дня представления таких сведений и
              снимает блокирование персональных данных.
            </p>
            <p>
              12.3. В случае выявления неправомерной обработки персональных
              данных, Общество в срок, не превышающий трех рабочих дней с даты
              этого выявления, прекращает неправомерную обработку персональных
              данных или обеспечивает прекращение неправомерной обработки
              персональных данных (если обработка персональных данных
              осуществляется другим лицом, действующим по поручению Общества). В
              случае, если обеспечить правомерность обработки персональных
              данных невозможно, Общество в срок, не превышающий десяти рабочих
              дней с даты выявления неправомерной обработки персональных данных,
              уничтожает такие персональные данные или обеспечивает их
              уничтожение (если обработка персональных данных осуществляется
              другим лицом, действующим по поручению Общества). Об устранении
              допущенных нарушений или об уничтожении персональных данных
              Общество уведомляет субъекта персональных данных или его
              представителя, а в случае, если обращение субъекта персональных
              данных или его представителя либо запрос уполномоченного органа по
              защите прав субъектов персональных данных были направлены
              уполномоченным органом по защите прав субъектов персональных
              данных, также указанный орган.
            </p>
            <p>
              12.4. В случае достижения цели обработки персональных данных
              Общество прекращает обработку персональных данных или обеспечивает
              ее прекращение (если обработка персональных данных осуществляется
              другим лицом, действующим по поручению Общества) и уничтожает
              персональные данные или обеспечивает их уничтожение (если
              обработка персональных данных осуществляется другим лицом,
              действующим по поручению Общества) в срок, не превышающий тридцати
              дней с даты достижения цели обработки персональных данных, если
              иное не предусмотрено договором, стороной которого,
              выгодоприобретателем или поручителем по которому является субъект
              персональных данных, иным соглашением между Обществом и субъектом
              персональных данных либо если Общество не вправе осуществлять
              обработку персональных данных без согласия субъекта персональных
              данных на основаниях, предусмотренных Федеральным законом от 27
              июля 2006 года № 152-ФЗ «О персональных данных» или другими
              федеральными законами.
            </p>
            <p>
              12.5. В случае отзыва субъектом персональных данных согласия на
              обработку его персональных данных Общество прекращает их обработку
              или обеспечивает прекращение такой обработки (если обработка
              персональных данных осуществляется другим лицом, действующим по
              поручению Общества) и в случае, если сохранение персональных
              данных более не требуется для целей обработки персональных данных,
              уничтожает персональные данные или обеспечивает их уничтожение
              (если обработка персональных данных осуществляется другим лицом,
              действующим по поручению Общества) в срок, не превышающий тридцати
              дней с даты поступления указанного отзыва, если иное не
              предусмотрено договором, стороной которого, выгодоприобретателем
              или поручителем по которому является субъект персональных данных,
              иным соглашением между Обществом и субъектом персональных данных
              либо если Общество не вправе осуществлять обработку персональных
              данных без согласия субъекта персональных данных на основаниях,
              предусмотренных Федеральным законом от 27 июля 2006 года № 152-ФЗ
              «О персональных данных» или другими федеральными законами.
            </p>
            <p>
              12.6. В случае отсутствия возможности уничтожения персональных
              данных в течение срока, указанного в частях 12.3 – 12.5, Общество
              осуществляет блокирование таких персональных данных или
              обеспечивает их блокирование (если обработка персональных данных
              осуществляется другим лицом, действующим по поручению Общества) и
              обеспечивает уничтожение персональных данных в срок не более чем
              шесть месяцев, если иной срок не установлен федеральными законами.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ОТВЕТСТВЕННОСТЬ</em>
          <div className="box">
            <p>
              13.1. Лица, виновные в нарушении норм, регулирующих обработку и
              защиту персональных данных, несут предусмотренную
              законодательством Российской Федерации ответственность.
            </p>
            <p>
              13.2. Моральный вред, причиненный субъекту персональных данных
              вследствие нарушения его прав, нарушения правил обработки
              персональных данных, установленных Федеральным законом от 27 июля
              2006 года № 152-ФЗ «О персональных данных», а также требований к
              защите персональных данных, установленных в соответствии с
              Федеральным законом от 27 июля 2006 года № 152-ФЗ «О персональных
              данных», подлежит возмещению в соответствии с законодательством
              Российской Федерации. Возмещение морального вреда осуществляется
              независимо от возмещения имущественного вреда и понесенных
              субъектом персональных данных убытков.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</em>
          <div className="box">
            <p>
              14.1. Настоящее Положение вступает в силу с момента его
              утверждения Директором Общества и действует бессрочно, до замены
              его новым Положением.
            </p>
            <p>
              14.2. Настоящее Положение действует в отношении всех субъектов
              персональных данных, чьи персональные данные обрабатывает
              Общество.
            </p>
            <p>
              14.3. Настоящее Положение является публичным, равнодоступным
              документом и предоставляется для ознакомления неограниченному
              кругу лиц на сайте Ресторана восточной кухни «BARAШЕК»
              принадлежащего компании ООО «Вкусные технологии»
              https://barashek.ru.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Payment;
