/* eslint-disable arrow-parens */
/* eslint-disable no-underscore-dangle */
import * as actions from '../actions/index';

// GTM container name
const DATA_LAYER_CONTAINER = 'dataLayer';
const GTM_ID = process.env.REACT_APP_GTM_ID;
const _gtm = window.google_tag_manager ? window.google_tag_manager[GTM_ID] : null;
let _dataLayer = window[DATA_LAYER_CONTAINER] || [];

export default ({ getState }) => (next) => (action) => {
  const returnValue = next(action);
  let oData = {};

  switch (action.type) {
    case actions.ADD_TO_BASKET:
      oData = {
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products: [{
              name: action.payload.title,
              id: action.payload.id,
              price: action.payload.price,
              category: action.payload.category,
              quantity: 1,
            }],
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
      };
      _dataLayer.push(oData);
      // console.log(' ');
      // console.log('GTM > ADD_TO_BASKET');
      // console.log(oData);
      return returnValue;

    case actions.SUB_FROM_BASKET:
    case actions.REMOVE_FROM_BASKET:
      oData = {
        ecommerce: {
          currencyCode: 'RUB',
          remove: {
            products: [{
              name: action.payload.title,
              id: action.payload.id,
              price: action.payload.price,
              category: action.payload.category,
              quantity: (action.type === actions.REMOVE_FROM_BASKET) ? action.payload.amount : 1,
            }],
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Removing a Product from a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
      };
      _dataLayer.push(oData);
      // console.log(' ');
      // console.log('GTM > SUB_FROM_BASKET');
      // console.log(oData);
      return returnValue;

    case 'RECEIVE_ORDER':
      if (action.payload && action.payload.id) {
        oData = {
          ecommerce: {
            currencyCode: 'RUB',
            purchase: {
              actionField: {
                'id': action.payload.id,
                'revenue': action.payload.amount,
                'tax': '',
                'shipping': action.payload.shipment_cost,
                'coupon': action.payload.promocode
              },
            },
          },
          event: 'gtm-ee-event',
          'gtm-ee-event-category': 'Enhanced Ecommerce',
          'gtm-ee-event-action': 'Purchase',
          'gtm-ee-event-non-interaction': 'False',
        };
        const aPurchased = action.payload.products.map(item => {
          return {
            'category': item.category,
            'name': item.title,
            'id': item.id,
            'price': item.price,
            'quantity': item.amount,
          };
        });
        oData.ecommerce.purchase.products = aPurchased;
        _dataLayer.push(oData);
        // console.log(' ');
        // console.log('GTM > RECEIVE_ORDER');
        // console.log(oData);
      }
      return returnValue;

    case actions.CLEAR_BASKET:
      oData = {
        ecommerce: {
          remove: {
            products: [],
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Cleared Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
      };
      // console.log(' ');
      // console.log('GTM > CLEAR_BASKET');
      // console.log(oData);
      if (_gtm) {
        _gtm[DATA_LAYER_CONTAINER].reset();
      } else {
        console.log('gtm not loaded');
      }
      return returnValue;

    default:
      return returnValue;
  }

};
