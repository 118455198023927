/* eslint-disable react/no-danger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Context } from '../../context/providers';

const termsTitle = (
  <h1 className="h-tac">
    ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ<em>(публичная оферта)</em>
  </h1>
);

const termsText = [
    [
      'ОСНОВНЫЕ ПОНЯТИЯ',
      <div className="box">
        <div className="box"><em>Авторизация на&nbsp;Сайте (Авторизация)</em>&nbsp;&mdash; идентификация
        и&nbsp;вход Пользователя на&nbsp;Сайт посредством введения
        в&nbsp;соответствующих полях авторизационной страницы Сайта уникальной
        комбинации номера телефона и&nbsp;Пароля. </div>
        <div className="box"><em>Администрация Сайта
        (Администрация)</em>&nbsp;&mdash; уполномоченные Владельцем Сайта лица, которые
          организуют, поддерживают и&nbsp;контролируют нормальную работу Сайта,
          действуют от&nbsp;имени и&nbsp;в&nbsp;интересах Владельца Сайта,
          а&nbsp;также осуществляют иные необходимые и&nbsp;достаточные действия
          в&nbsp;соответствии с&nbsp;нормами действующего законодательства
        Российской Федерации.</div>
        <div className="box"><em>Акцепт пользовательского соглашения</em>&nbsp;&mdash;
        полное и&nbsp;безоговорочное принятие условий пользовательского
        соглашения. </div>
        <div className="box"><em>Владелец Сайта</em>&nbsp;&mdash; ООО &laquo;Вкусные
        технологии&raquo;. Заказ&nbsp;&mdash; заявка Пользователя
        на&nbsp;получение за&nbsp;плату товаров из&nbsp;меню, выбранных
        на&nbsp;Сайте, содержащий необходимые и&nbsp;достаточные сведения
        и&nbsp;параметры, требуемые для надлежащей передачи товаров Пользователю
        или третьим лицам.</div>
        <div className="box"><em>Корзина</em>&nbsp;&mdash; раздел в&nbsp;Личном кабинете, где
        содержится информация о&nbsp;Заказах.</div>
        <div className="box"><em>Личный кабинет
        (Профиль)</em>&nbsp;&mdash; личная страница Пользователя на&nbsp;Сайте. </div>
        <div className="box"><em>Место
        доставки</em>&nbsp;&mdash; адрес, который указал Пользователь для передачи
          ему/третьему лицу Заказа, при оформлении Заказа в&nbsp;соответствии
        с&nbsp;7.1.3. Соглашения.</div>
        <div className="box"><em>Пароль</em>&nbsp;&mdash; уникальная комбинация
        латинских букв, цифр и&nbsp;символов, привязанная к&nbsp;номеру телефона
        Пользователя на&nbsp;Сайте, необходимая для его Авторизации на&nbsp;Сайте.</div>
        <div className="box"><em>Пользователь</em>&nbsp;&mdash; любое физическое или юридическое лицо,
        использующее сервисы и&nbsp;функционал Сайта. Пользователь вправе пройти
        процедуру регистрации для получения дополнительных возможностей.</div>
        <div className="box"><em>Пользовательское соглашение (Соглашение/Публичная оферта)</em>&nbsp;&mdash;
        предложение ООО &laquo;Вкусные технологии&raquo; в&nbsp;отношении любого
        физического лица, обладающего полной дееспособностью, или юридического
        лица о&nbsp;заключении Договора на&nbsp;приобретение Заказов
        и&nbsp;их&nbsp;доставке, содержащее все его существенные условия,
        действующая редакция опубликована на&nbsp;Сайте в&nbsp;сети Интернет
        по&nbsp;адресу: https://delivery.barashek.ru/terms#b.</div>
        <div className="box"><em>Регистрация</em>&nbsp;&mdash; совокупность действий, выполненных
        в&nbsp;соответствии с&nbsp;разделом 6&nbsp;Соглашения с&nbsp;целью
        формирование учетной записи на&nbsp;Сайте. </div>
        <div className="box"><em>Сайт</em>&nbsp;&mdash;
        информационная площадка, представляющая собой совокупность данных
        и&nbsp;веб-страниц, действующая в&nbsp;сети Интернет и&nbsp;размещенная
        по&nbsp;адресу: https://barashek.ru/, права на&nbsp;которую принадлежат
        Владельцу Сайта, созданная в&nbsp;целях удовлетворения спроса Участников
        в&nbsp;приобретении Заказов с&nbsp;использованием современных
        информационных технологий и&nbsp;сети Интернет.</div>
        <div className="box"><em>Сервис</em>&nbsp;&mdash;
        специальное программное и&nbsp;аппаратное обеспечение, предназначенное для
        автоматизации сбора и&nbsp;обработки Заказов, информационного
        и&nbsp;технологического взаимодействия между Пользователями
        и&nbsp;Администрацией на&nbsp;Сайте.</div>
      </div>,
    ],
    [
      'ОБЩИЕ ПОЛОЖЕНИЯ',
      <>
        <div className="box">Настоящее Соглашение определяет условия использования Пользователями
        материалов и&nbsp;сервисов Сайта, регулирует отношения между
        Администрацией Сайта и&nbsp;Пользователями. Настоящее Соглашение является
        публичной офертой, вступает в&nbsp;силу с&nbsp;момента опубликования
        Пользовательского соглашения на&nbsp;Сайте и&nbsp;действует
        до&nbsp;момента ее&nbsp;отзыва Администрацией Сайта. Пользователь обязан
        полностью ознакомиться с&nbsp;условиями Соглашения до&nbsp;начала
        использования Сайта, в&nbsp;случае если Пользователь не&nbsp;согласен
        с&nbsp;условиями Соглашения, он&nbsp;не&nbsp;имеет права пользоваться
        Сервисом.</div>
        <div className="box">Получая доступ к&nbsp;материалам Сайта и&nbsp;используя Сайт,
        Пользователь считается присоединившимся к&nbsp;настоящему Соглашению
        в&nbsp;соответствии со&nbsp;ст.&nbsp;438&nbsp;ГК РФ, а&nbsp;также иным
        документам, опубликованным на&nbsp;Сайте, и&nbsp;обязуются неукоснительно
        соблюдать&nbsp;их. Администрация Сайта вправе в&nbsp;любое время
        в&nbsp;одностороннем порядке без уведомления Пользователя изменять условия
        настоящего Соглашения. Такие изменения вступают в&nbsp;силу с&nbsp;момента
        размещения новой версии Соглашения на&nbsp;Сайте. При несогласии
        Пользователя с&nbsp;внесёнными изменениями он&nbsp;обязан отказаться
        от&nbsp;доступа к&nbsp;Сайту, прекратить использование материалов
        и&nbsp;сервисов Сайта. Продолжение использования Сайта Пользователем
        означает полное и&nbsp;безоговорочное принятие Соглашения
        и&nbsp;изменений, внесенных в&nbsp;настоящее Соглашение.</div>
        <div className="box">Принятие условий
        Пользовательского соглашения возможно лишь в&nbsp;полном объёме, без
        каких-либо оговорок и&nbsp;исключений. Пользователь несет персональную
        ответственность за&nbsp;проверку настоящего Соглашения на&nbsp;наличие
        изменений в&nbsp;нем. Пользователь принимает положение о&nbsp;том, что все
        материалы и&nbsp;сервисы Сайта или любая их&nbsp;часть может
        сопровождаться рекламой. Пользователь согласен с&nbsp;тем, что
        Администрация Сайта не&nbsp;несёт какой-либо ответственности
        и&nbsp;не&nbsp;имеет каких-либо обязательств в&nbsp;связи с&nbsp;такой
        рекламой.</div>
        <div className="box">Пользователь выражает свое согласие на&nbsp;обработку
        Администрацией (и&nbsp;уполномоченными Администрацией лицами) его
        персональных данных, предоставленных Пользователем при
        регистрации/осуществлении Заказа, а&nbsp;также персональных данных,
        размещённых самостоятельно Пользователем в&nbsp;любой части Сайта.
        Обработка персональных данных Пользователя осуществляется
        в&nbsp;соответствии с&nbsp;действующим законодательством Российской
        Федерации. Администрация принимает все возможные меры для защиты
        персональных данных Пользователя от&nbsp;неправомерного доступа третьих
        лиц.</div>
      </>,
    ],
    [
      'ПРЕДМЕТ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ',
      <>
        Предметом Соглашения является установка правил использования Сайта
        Пользователями, регулирование отношений между Администрацией Сайта
        и&nbsp;Пользователем, а&nbsp;также предоставление Администрацией Сайта
        Пользователю Услуг, перечень которых не&nbsp;является исчерпывающим
        и&nbsp;определен в&nbsp;п.&nbsp;4.1&nbsp;Соглашения, а&nbsp;Пользователь
        обязуется принять исполненное и&nbsp;оплатить Услуги.
      </>,
    ],
    [
      'ПЕРЕЧЕНЬ И&nbsp;УСЛОВИЯ ОКАЗАНИЯ УСЛУГ',
      <>
        <div className="box">
          Администрация Сайта оказывает Пользователю следующий перечень Услуг:
          Ознакомление с&nbsp;информационными материалами Сайта. Использование
          Сервиса. Обеспечение возможности оплаты за&nbsp;приобретаемые
          товары/услуги с&nbsp;использованием Сервиса &laquo;PayAnyWay&raquo;.
          Осуществление доставки Заказа Пользователю или третьему лицу
          в&nbsp;соответствии с&nbsp;тарифами, опубликованными на&nbsp;Сайте
          в&nbsp;сети Интернет по&nbsp;адресу: https://delivery.barashek.ru/payment.
          Иные платные услуги, перечень и&nbsp;тарифы опубликованы на&nbsp;Сайте
          в&nbsp;сети Интернет по&nbsp;адресу:
          https://delivery.barashek.ru/kazanplova.
        </div>
        <div className="box">
          Условия оказания Услуг:
          Обязательным условием оказания Услуг является полное принятие
          и&nbsp;безоговорочное соблюдение Пользователем положений и&nbsp;условий:
          Настоящего соглашения, иной информации, указанной на&nbsp;сайте
          https://barashek.ru/. Иных документов, опубликованных на&nbsp;Сайте
          и&nbsp;на&nbsp;сторонних сайтах при условии наличия действующей
          гиперссылки. Норм действующего законодательства Российской Федерации
          и&nbsp;ратифицированных международных нормативно-правовых актов. Для
          оказания Услуг Администрация Сайта может привлекать соисполнителей
          по&nbsp;своему выбору.
        </div>
      </>,
    ],
    [
      'ПРАВА И&nbsp;ОБЯЗАННОСТИ СТОРОН',
      <>
        <div className="box">
          Права и&nbsp;обязанности Администрации Сайта: Администрации Сайта
          обязуется: Извещать Пользователя о&nbsp;ситуациях, требующих
          дополнительного согласования в&nbsp;процессе оказания Услуг. Обеспечить
          оказание Услуг надлежащего качества в&nbsp;соответствии
          с&nbsp;Соглашением.
        </div>
        <div className="box">
          Администрации Сайта имеет право: В&nbsp;одностороннем
          порядке определять порядок оказания Услуг. Требовать оплаты Пользователем
          оказанных или оказываемых платных Услуг. Приостановить оказание
          Пользователю Услуг по&nbsp;техническим, технологическим или иным причинам,
          препятствующим оказанию Услуг, на&nbsp;время устранения таких причин.
          Приостановить оказание Услуг и/или отказаться от&nbsp;исполнения Договора
          полностью или частично в&nbsp;одностороннем внесудебном порядке путем
          уведомления Пользователя в&nbsp;случаях: Нарушения Участником условий
          Соглашения или иных документов, перечисленных
          в&nbsp;п.&nbsp;4.2.1&nbsp;Соглашения. Нарушения Участником иных
          обязательств или норм действующего законодательства. В&nbsp;любое время
          в&nbsp;любое время в&nbsp;одностороннем порядке без уведомления
          Пользователя изменять условия настоящего Соглашения. Отозвать Оферту
          в&nbsp;любой момент времени по&nbsp;своему усмотрению.
        </div>
        <div className="box">
          Права
          и&nbsp;обязанности Пользователя Пользователь обязуется: Оплачивать Услуги
          в&nbsp;порядке, размере и&nbsp;сроки, предусмотренные Офертой.
          Ознакомиться и&nbsp;добросовестно соблюдать условия настоящего Соглашения.
          Указать полную и&nbsp;достоверную информацию при Регистрации/осуществлении
          Заказа, а&nbsp;также нести ответственность за&nbsp;предоставление
          искаженной или ложной информации. Самостоятельно отслеживать
          и&nbsp;знакомиться со&nbsp;всеми изменениями в&nbsp;Соглашении,
          а&nbsp;также иных документах, перечисленных
          в&nbsp;п.&nbsp;4.2.1&nbsp;Соглашения. Возмещать причиненный ущерб
          и&nbsp;упущенную выгоду Администрации Сайта в&nbsp;соответствии
          с&nbsp;Офертой и&nbsp;действующим законодательством Российской Федерации.
          Не&nbsp;предоставлять третьим лицам доступ к&nbsp;личному кабинету, паролю
          и&nbsp;иным конфиденциальным данным, разглашение сведений о&nbsp;которых
          может привести к&nbsp;ущербу, убыткам и&nbsp;иным негативным последствиям
          для Администрации Сайта и/или третьих лиц.
        </div>
        <div className="box">
          Пользователь имеет право:
          Пользоваться Услугами, указанными в&nbsp;п.4.1. Соглашения. В&nbsp;случае
          несогласия Пользователя с&nbsp;изменениями, внесенными в&nbsp;Соглашение
          или иные документы на&nbsp;Сайте либо сторонних сайтах при наличии
          действующей гиперссылки, отказаться от&nbsp;исполнения Договора.
        </div>
      </>,
    ],
    [
      'ПОРЯДОК РЕГИСТРАЦИИ ПОЛЬЗОВАТЕЛЕЙ НА&nbsp;САЙТЕ',
      <>
        Для регистрации на&nbsp;Сайте Пользователю необходимо заполнить
        регистрационную форму, указав номер мобильного телефона и&nbsp;пароль,
        а&nbsp;также принять условия настоящего Соглашения.
      </>,
    ],
    [
      'ПОРЯДОК ОФОРМЛЕНИЯ И&nbsp;ДОСТАВКИ ЗАКАЗА',
      <>
        <div className="box">
          Для оформления Заказа из&nbsp;вкладки &laquo;Меню&raquo; необходимо: Зайти
          во&nbsp;вкладку &laquo;меню&raquo;, путем нажатия на&nbsp;поле
          &laquo;В&nbsp;корзину&raquo; добавить необходимый товар. Для увеличения
          количества порций необходимо нажать на&nbsp;поле &laquo;+&bdquo;, для
          уменьшения &ldquo;-&raquo;. Во&nbsp;вкладке &laquo;Корзина&raquo; занести
          в&nbsp;соответствующие поля информацию о&nbsp;доставке Имя. Телефон. Адрес
          доставки (улица, дом, квартира). Информация для курьера (в&nbsp;случае,
          указанном в&nbsp;п.7.3. Соглашения).
        </div>
        <div className="box">
          Время доставки: Как можно скорее
          (доставка будет осуществлена в&nbsp;минимально возможное время,
          в&nbsp;зависимости от&nbsp;времени приготовления заказа
          и&nbsp;логистических возможностей). В&nbsp;назначенное время (указать
          конкретное время). Способ оплаты: наличными/банковская карта. Нажать
          на&nbsp;поле &laquo;Оформить заказ&raquo;.
        </div>
        <div className="box">
          Прием Заказов осуществляется
          в&nbsp;понедельник, вторник, среду, четверг и&nbsp;воскресенье
          с&nbsp;11:00 до&nbsp;21:00 и&nbsp;в&nbsp;пятницу и&nbsp;субботу
          с&nbsp;11:00 до&nbsp;22:00. Доставка Заказа осуществляется
          в&nbsp;понедельник, вторник, среду, четверг и&nbsp;воскресенье
          с&nbsp;11:00 до&nbsp;22:00 и&nbsp;в&nbsp;пятницу и&nbsp;субботу
          с&nbsp;11:00 до&nbsp;23:00 по&nbsp;указанному в&nbsp;заявке адресу.
          Доставка Заказа осуществляется до&nbsp;квартиры, офиса, места нахождения
          Пользователя/третьего лица в&nbsp;здании назначения. В&nbsp;случае
          возникновения каких-либо сложностей при доставке Заказа до&nbsp;места
          назначения (сложно найти офис, специальный режим пропуска
          в&nbsp;здание/офис, невозможно попасть в&nbsp;подъезд/ на&nbsp;территорию
          и&nbsp;т.д.), такую информацию необходимо сообщать в&nbsp;соответствии
          с&nbsp;п.&nbsp;7.1.3.4. Соглашения. Доставка Заказа осуществляется
          по&nbsp;г. Йошкар-Ола, за&nbsp;пределы города доставка осуществляется
          по&nbsp;предварительному запросу Пользователя о&nbsp;возможности такой
          доставки.
        </div>
        <div className="box">
          Для заказов на&nbsp;сумму свыше 400 рублей доставка бесплатна.
          Стоимость доставки заказов стоимостью менее 400 рублей составляет
          50&nbsp;рублей.
        </div>
        <div className="box">
          Для оформления Заказа из&nbsp;вкладки &laquo;Плов
          в&nbsp;казане&raquo; необходимо сделать следующее: Заявку необходимо
          отправить за&nbsp;день до&nbsp;осуществления Заказа путем нажатия
          на&nbsp;кнопку отправить &laquo;Отправить заявку&raquo;. В&nbsp;заявке
          необходимо указать: Телефонный номер, на&nbsp;который вам перезвонит
          оператор для уточнения деталей Заказа. Дату и&nbsp;время доставки Заказа.
          Количество казанов и&nbsp;наименование плова для Заказа. За&nbsp;каждый
          казан берется залог. Пользователь может оставить у&nbsp;себя казан, при
          этом теряя залог в&nbsp;размере, указанном в&nbsp;п.7.6.3. Соглашения.
          Если Пользователь планирует вернуть казан, то&nbsp;для этого необходимо
          позвонить по&nbsp;номеру телефона: + 7 (8362) 36 99&nbsp;99,
          и&nbsp;договориться о&nbsp;дате и&nbsp;времени возврата казана
          и&nbsp;суммы залога.
        </div>
        <div className="box">
          Пользователь вправе отказаться от&nbsp;своего Заказа
          в&nbsp;любое время с&nbsp;момента осуществления Заказа
          и&nbsp;до&nbsp;момента доставки Заказа в&nbsp;место доставки. Если
          Пользователь отказался от&nbsp;Заказа надлежащего качества,
          то&nbsp;уплаченные за&nbsp;него денежные средства будут возвращены
          сертификатом на&nbsp;осуществление Заказа в&nbsp;другой раз. Если
          Пользователь отказался от&nbsp;Заказа ненадлежащего качества,
          то&nbsp;полная сумма оплаты будет возвращена Пользователю, либо,
          по&nbsp;желанию Пользователя, также возможен обмен на&nbsp;сертификат
          на&nbsp;осуществление Заказа в&nbsp;другой раз.
        </div>
      </>,
    ],
    [
      'ОТВЕТСТВЕННОСТЬ',
      <>
        <div className="box">
          За&nbsp;неисполнение или ненадлежащее исполнение настоящего Соглашения
          Пользователи несут ответственность, предусмотренную Соглашением, иными
          документами, опубликованными на&nbsp;Сайте и&nbsp;обязательными
          к&nbsp;исполнению, а&nbsp;также действующим законодательством Российской
          Федерации.
        </div>
        <div className="box">
          В&nbsp;случае нарушения Пользователем Соглашения или норм
          действующего законодательства Российской Федерации, Администрация имеет
          право по&nbsp;своему выбору: Блокировать или удалить учетную запись
          Пользователя с&nbsp;Сайта; Иные права, предусмотренные Правилами
          и&nbsp;действующим законодательством Российской Федерации.
        </div>
        <div className="box">
          Администрация
          Сайта не&nbsp;несет ответственности за&nbsp;нанесение любого прямого или
          косвенного ущерба (убытков) Пользователям или третьим лицам
          по&nbsp;причинам использования или невозможности использования Сайта или
          информации на&nbsp;Сайте, за&nbsp;любые действия или бездействие третьих
          лиц, использующих или просматривающих Сайт, за&nbsp;любые ошибки,
          случайное удаление или изменение информации на&nbsp;Сайте, вне зависимости
          от&nbsp;того, мог Владелец Сайта предвидеть данные обстоятельства или нет.
        </div>
        <div className="box">
          Исключительные права на&nbsp;Сервис, а&nbsp;также на&nbsp;информационное
          наполнение Сайта, включая дизайн, графику, текст и&nbsp;прочую информацию,
          принадлежат Администрации Сайта либо их&nbsp;правообладателям. Копирование
          материалов Сайта без письменного разрешения Администрации Сайта или
          правообладателей запрещено и&nbsp;влечет ответственность
          в&nbsp;соответствии с&nbsp;законодательством Российской Федерации.
        </div>
      </>,
    ],
    [
      'ПРОЧИЕ УСЛОВИЯ',
      <>
        <div className="box">
          Все возможные споры, вытекающие из&nbsp;настоящего Соглашения или
          связанные с&nbsp;ним, подлежат разрешению в&nbsp;зависимости
          от&nbsp;подведомственности, определяемой из&nbsp;субъектного состава
          сторон, в&nbsp;Арбитражном суде Республики Марий Эл&nbsp;либо
          в&nbsp;Йошкар-Олинском городском суде Республики Марий&nbsp;Эл, либо
          Мировым судьей судебного участка &#8470;&nbsp;1&nbsp;Йошкар-Олинского
          судебного района.
        </div>
        <div className="box">
          Все претензии по&nbsp;вопросу оказания услуг должны быть
          направлены в&nbsp;письменном виде на&nbsp;юридический адрес Владельца
          Сайта, либо через электронную форму обратной связи Сайта по&nbsp;адресу:
          info@barashek.ru. Признание судом какого-либо положения Соглашения
          недействительным или не&nbsp;подлежащим принудительному исполнению
          не&nbsp;влечёт недействительности иных положений Соглашения. Пользователь
          подтверждает, что ознакомлен со&nbsp;всеми пунктами настоящего Соглашения
          и&nbsp;безусловно принимает&nbsp;их.
        </div>
      </>,
    ],
    [
      'РЕКВИЗИТЫ И&nbsp;КОНТАКТНАЯ ИНФОРМАЦИЯ',
      <>
        <div className="box">
          ООО &laquo;Вкусные технологии&raquo; Адрес местонахождения: 424038,
          Республика Марий&nbsp;Эл, г.&nbsp;Йошкар-Ола, ул.&nbsp;Кирова, д.&nbsp;3,
          корпус&nbsp;А, оф.&nbsp;101.
        </div>
        <div className="box">
          Адрес ресторана: 424033, Республика Марий&nbsp;Эл, г.&nbsp;Йошкар-Ола, б-р&nbsp;Чавайна, д&nbsp;23А, 2&nbsp;этаж.<br />
          ИНН&nbsp;1215210507, КПП&nbsp;121501001, ОГРН&nbsp;1161215055946,<br />
          Р/с&nbsp;40702810711090005244&nbsp;в ФИЛИАЛ &#8470;&nbsp;6318&nbsp;БАНКА ВТБ (ПАО),<br />
          г.&nbsp;Самара К/с&nbsp;30101810422023601968&nbsp;БИК 043601968
        </div>
        <div className="box">
          Тел.&nbsp;+7&nbsp;(8362)&nbsp;
        36&nbsp;99&nbsp;99<br />Эл.адрес: info@barashek.ru
        </div>
      </>,
    ],
  ];

const Terms = () => {
  const { isLoading } = useContext(Context);

  const [state, setState] = useState({
    title: 'Пользовательское соглашение',
    indexes: [],
    sections: [],
  });

  useEffect(() => {
    setState({
      ...state,
      indexes: termsText.map(item => item[0]),
      sections: termsText.map(item => item[1]),
    });
  }, [isLoading]);

  return (
    <>
      <Helmet>
        <title>{`${state.title} – BARAШEK`}</title>
        <meta content={`${state.title} – BARAШEK`} name="title" />
        <meta content={state.title} property="og:title" />
      </Helmet>
      <div className="container content-index">
        {termsTitle}
        <div className="row">
          <div className="col">
            <ul className="index-items">
              {state.indexes.map((item, i) => (
                <li className="index-item" key={`index_title_${i}`}>
                  <a href={`#item${i}`} dangerouslySetInnerHTML={{__html: item}}></a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {state.sections.map((item, i) => (
              <section className="content-section" key={`index_section_${i}`}>
                <em id={`item${i}`} dangerouslySetInnerHTML={{__html: state.indexes[i]}}></em>
                {item}
              </section>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
