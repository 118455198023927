import React from 'react';
import { Helmet } from 'react-helmet';
import AuthForm from '../../components/forms/authform';

const Register = props => {
  const title = 'Регистрация на сайте';
  return (
    <>
      <Helmet>
        <title>{`${title} – BARAШEK`}</title>
        <meta content={`${title} – BARAШEK`} name="title" />
        <meta content={title} property="og:title" />
      </Helmet>
      <div className="container">
        <h2 className="h-tac">Регистрация</h2>
        <AuthForm formType="signup" className="form standalone" />
      </div>
    </>
  );
};

export default Register;
