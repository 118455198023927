/* eslint-disable no-lonely-if */
const StickyHeader = function() {
  let lastScrollTop = 0;
  const elSticky = document.querySelector('#header');
  const elStickyAfter = document.querySelector('#main');
  let stickyTimeout = null;

  const onScroll = () => {
    const headerHeight = elSticky ? elSticky.clientHeight : 0;
    const st = window.pageYOffset;

    // scrolling down
    if (st > lastScrollTop) {
      elStickyAfter.removeAttribute('style');
      elSticky.classList.add('is-sticked');
      elStickyAfter.setAttribute('style', `margin-top: ${headerHeight}px;`);
    } else {
      // scrolling up
      if (st >= headerHeight) {
        elSticky.classList.add('is-sticked');
        elStickyAfter.setAttribute('style', `margin-top: ${headerHeight}px;`);
      } else {
        elSticky.classList.remove('is-sticked');
        elStickyAfter.setAttribute('style', 'margin-top: 0');
      }
    }
    lastScrollTop = st;
  };

  if (elSticky && elStickyAfter) {
    // window.addEventListener('scroll', onScroll);
    window.addEventListener(
      'scroll',
      function(event) {
        if (stickyTimeout) {
          window.cancelAnimationFrame(stickyTimeout);
        }
        stickyTimeout = window.requestAnimationFrame(function() {
          onScroll();
        });
      },
      false
    );
  }
};

export default StickyHeader;
