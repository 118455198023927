import React from 'react';
import './styles.css';

const Tooltip = (props) => {
  return (
    <div className={`tooltip ${props.className}`}>
      <div className="tooltip-container">
        <button className="tooltip-close" onClick={() => props.handleTooltipClose()}>&times;</button>
        <span dangerouslySetInnerHTML={{ __html: props.text }} />
      </div>
    </div>
  );
}

export default Tooltip