/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AppLoader from '../loader';

const IsAuthenticated = ({ isAuthenticated, redirectTo, children }) => {
  if (typeof isAuthenticated === 'undefined') {
    return <AppLoader />;
  }

  if (isAuthenticated) {
    return children;
  }

  if (redirectTo) {
    return <Redirect to={{ pathname: redirectTo }} />;
  }

  return null;
};

IsAuthenticated.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  redirectTo: PropTypes.string,
};

IsAuthenticated.defaultProps = {
  redirectTo: null,
  isAuthenticated: undefined,
};

export default IsAuthenticated;
