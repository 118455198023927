/* eslint-disable no-lonely-if */
import { scroller } from 'react-scroll';
import { history } from '../store';

export const handleURL = (address) => {
  const url = address ? address.split('#') : null;
  const id = url ? url[1] : null;
  const element = id ? document.getElementById(id) : null;

  if (element) {
    // scroll if hash target found
    const elSticky = document.querySelector('#header');
    const headerHeight = elSticky ? elSticky.clientHeight : 0;
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'linear',
      offset: -headerHeight,
    });
  } else {
    // else navigate to link url
    let gotoUrl = '/';
    if (id) {
      gotoUrl = address;
      history.push(gotoUrl);
    } else {
      if (url) {
        gotoUrl = url.reduce(
          (result, _cur, index) => (result += `/${url[index]}`)
        );
      }
      const baseURL =
        process.env.NODE_ENV === 'production' && gotoUrl.match('^/')
          ? process.env.REACT_APP_API_URL
          : window.location.origin;
      const target = new URL(gotoUrl, baseURL);
      if (target.hostname !== window.location.hostname) {
        // redirect to absolute url
        window.location.href = gotoUrl;
      } else {
        // deselect active scrollable element
        const elScroll = document.querySelector('.header_submenu-link.is-active');
        if (elScroll) {
          elScroll.classList.remove('is-active');
        }
        // navigate to relative url
        history.push(gotoUrl);
      }
    }
  }
};

const anchorClick = () => {
  const callback = (e) => {
    if (e && e.target) {
      e.preventDefault();
      // handle a click
      handleURL(e.target.getAttribute('href'));
    } else {
      handleURL(e);
    }
  };

  // handle uri hash
  if (history.location && history.location.hash) {
    const URLhash = decodeURI(history.location.hash);
    callback(URLhash);
  }

  document.body.addEventListener(
    'click',
    function (e) {
      if (
        e.target &&
        e.target.nodeName.toLowerCase() === 'a' &&
        e.target.getAttribute('href') !== '#'
      ) {
        callback(e);
      }
    },
    false
  );
};

export default anchorClick;
