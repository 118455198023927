import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Context } from '../../context/providers';
import './styles.css';

export const Timetable = (props) => {
  const { settings, isLoading } = useContext(Context);
  const { today = true } = props;

  let timeDelivery = '';

  if (today && settings && settings.timetable) {

    const currentDate = new Date();
    const day = currentDate.getDay();
    let currentDay = day; 
    if (day === 0) {
      currentDay = 6;
    } else if (day > 0) {
      currentDay = day-1;
    }

    timeDelivery = settings.timetable[currentDay].match(/([0-2]\d+:[0-5]\d+) - ([0-2]\d+:[0-5]\d+)/);

    timeDelivery = (<span className="timetable">{`с\u00A0${timeDelivery[1]} до\u00A0${timeDelivery[2]}`}</span>);

  } else if (!today && !isLoading && settings && settings.timetable) {

    const weekDays = {
      '0': 'пн',
      '1': 'вт',
      '2': 'ср',
      '3': 'чт',
      '4': 'пт',
      '5': 'сб',
      '6': 'вс',
    };

    const removeDuplicates = (arr) => {
      const set = new Set(arr);
      const unique = set.values();
      return Array.from(unique);
    };

    const timetableGrouped = Object.values(settings.timetable)
      .map(item => item)
      .reduce((total, curVal, curIndex) => {
        (total[curVal] = total[curVal] || []).push(curIndex);
        return total;
      }, {});

    const timetableCount = timetableGrouped ? Object.entries(timetableGrouped).length : 0;

    const timetableParsed = Object.entries(timetableGrouped)
      .map((item) => {
        const time = item[0];
        const days = item[1];
        const daysLength = days.length;
        let daysNames;

        if (timetableCount === 1) { // days in a row with the same timetable

          daysNames = days.reduce((total, curVal, curIndex) => {
            let value;
            switch(true) {
              case curIndex === 0:
                // first item
                value = weekDays[curVal];
                break;
              case curIndex === daysLength-1:
                // last item
                value = `–${weekDays[curVal]}`;
                break;
              default:
                // skip other values
                value = '';
            }
            if (value) {
              total[curVal] = value;
            }
            return total;
          }, {});

        } else if (timetableCount > 1) { // days grouped by custom timetable

          daysNames = days.reduce((total, curVal, curIndex) => {
            // prev item
            const prevIndex = (curIndex-1);
            const prevVal = (curVal-1);
            // resulting value
            let value;
            switch(true) {
              case curIndex === 0:
                // first item
                value = weekDays[curVal];
                break;
              case curIndex > 0:
                // not first item
                if (prevVal !== days[prevIndex]) {
                  // any item in the middle
                  total[prevIndex] = weekDays[days[prevIndex]];
                  value = `, ${weekDays[curVal]}`;
                } else if (curIndex !== daysLength-1) {
                  // any item in ordered range
                  value = '–';
                // } else {
                } else if (curIndex === daysLength-1) {
                  // last item
                  value = `, ${weekDays[curVal]}`;
                }
                break;
              default:
                value = weekDays[curVal];
            }
            if (value) {
              total[curVal] = value;
            }
            return total;
          }, {});

        } else { // no timetable
          return [];
        }

        const dayNamesList = daysNames ? Object.values(daysNames).map(day => day) : [];
        const dayNamesUnique = removeDuplicates(dayNamesList).join('');

        return [dayNamesUnique, time]
      });

    timeDelivery = timetableParsed ? timetableParsed.map((item, index) => {
      const daysRange = item[0];
      const timeRange = item[1];
      return (
        <div className="timetable" key={`timetable-${index}`}>
          <ul key={`timetable-list-${index}`}>
            <li key={`timetable-list-days-${daysRange}`}>{daysRange}</li>
            <li key={`timetable-list-time-${timeRange}`}>{timeRange}</li>
          </ul>
        </div>
      );
    }) : null;

  }

  return timeDelivery;

};

Timetable.propTypes = {
  today: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default Timetable;