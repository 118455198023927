export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';
export const RECEIVE_ADDRESS = 'RECEIVE_ADDRESS';
export const RECEIVE_PROMO = 'RECEIVE_PROMO';
export const RECEIVE_TIME = 'RECEIVE_TIME';
export const FETCH_TIME = 'FETCH_TIME';
export const APPLY_BONUS = 'APPLY_BONUS';
export const RECEIVE_ORDER = 'RECEIVE_ORDER';
export const SEND_ORDERS = 'SEND_ORDERS';
export const SHOW_PAYMENT_ERROR = 'SHOW_PAYMENT_ERROR';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const SUB_FROM_BASKET = 'SUB_FROM_BASKET';
export const REMOVE_FROM_BASKET = 'REMOVE_FROM_BASKET';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const TOGGLE_SMS_CONFIRM_MODAL = 'TOGGLE_SMS_CONFIRM_MODAL';
export const TOGGLE_PAYANYWAY_MODAL = 'TOGGLE_PAYANYWAY_MODAL';
export const RECEIVE_MANUAL_PRODUCTS = 'RECEIVE_MANUAL_PRODUCTS';
export const TOGGLE_LOGIN_MODAL = 'TOGGLE_LOGIN_MODAL';
export const SIGN_IN = 'SIGN_IN';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';
export const CURRENT_USER = 'CURRENT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RECEIVE_KAZAN_ORDER_RESULT = 'RECEIVE_KAZAN_ORDER_RESULT';
export const SAVE_PERSONAL_DATA = 'SAVE_PERSONAL_DATA';
export const SAVE_ADDRESSES = 'SAVE_ADDRESSES';
export const PROFILE_DATA_SAVED = 'PROFILE_DATA_SAVED';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export function toggleLogInModal(data) {
  return {
    type: TOGGLE_LOGIN_MODAL,
    payload: data,
  };
}

export function signIn(data) {
  return {
    type: SIGN_IN,
    payload: data,
  };
}

export function logInError(data = { isError: false, errors: '' }) {
  return {
    type: LOG_IN_ERROR,
    payload: data,
  };
}

export function signUp(data) {
  return {
    type: SIGN_UP,
    payload: data,
  };
}

export function signOut(data) {
  return {
    type: SIGN_OUT,
    payload: data,
  };
}

export function forgotPassword(data) {
  return {
    type: FORGOT_PASSWORD,
    payload: data,
  };
}

export function currentUser(data) {
  return {
    type: CURRENT_USER,
    payload: data,
  };
}

export function receivePromo(promo) {
  return {
    type: RECEIVE_PROMO,
    payload: promo,
  };
}

export function applyBonus(data) {
  return {
    type: APPLY_BONUS,
    payload: data,
  };
}

export function receiveOrder(data) {
  return {
    type: RECEIVE_ORDER,
    payload: data,
  };
}

export function showPaymentError() {
  return {
    type: SHOW_PAYMENT_ERROR,
  };
}

export function addToBasket(product) {
  return {
    type: ADD_TO_BASKET,
    payload: product,
  };
}

export function removeFromBasket(product) {
  return {
    type: REMOVE_FROM_BASKET,
    payload: product,
  };
}

export function subFromBasket(product) {
  return {
    type: SUB_FROM_BASKET,
    payload: product,
  };
}

export function toggleSmsConfirmModal(settings) {
  return {
    type: TOGGLE_SMS_CONFIRM_MODAL,
    payload: settings,
  };
}

export function togglePayanywayModal(settings) {
  return {
    type: TOGGLE_PAYANYWAY_MODAL,
    payload: settings,
  };
}

export function clearBasket() {
  return {
    type: CLEAR_BASKET,
  };
}

export function receiveKazanOrderResult(result) {
  return {
    type: RECEIVE_KAZAN_ORDER_RESULT,
    payload: result,
  };
}

export function receiveProducts(products) {
  return {
    type: RECEIVE_PRODUCTS,
    products,
  };
}

export function receiveSettings(settings) {
  return {
    type: RECEIVE_SETTINGS,
    payload: settings,
  };
}

export function fetchTime() {
  return {
    type: FETCH_TIME,
  };
}

export function receiveTime(time) {
  return {
    type: RECEIVE_TIME,
    payload: time,
  };
}

export function sendOrders(data) {
  return {
    type: SEND_ORDERS,
    payload: data,
  };
}

export function receiveAddress(address) {
  return {
    type: RECEIVE_ADDRESS,
    payload: address,
  };
}

export function receiveManualProducts(products) {
  return {
    type: RECEIVE_MANUAL_PRODUCTS,
    products,
  };
}

export function savePersonalData(data) {
  return {
    type: SAVE_PERSONAL_DATA,
    payload: data,
  };
}

export function saveAddresses(data) {
  return {
    type: SAVE_ADDRESSES,
    payload: data,
  };
}

export function sendNotification(data) {
  return {
    type: SEND_NOTIFICATION,
    payload: {
      id: data.message_id || '',
      data: data.message_data,
      type: data.message_type,
    },
  };
}

export function clearNotification(id) {
  return {
    type: CLEAR_NOTIFICATION,
    payload: id,
  };
}
