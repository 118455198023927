import React, { Component } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { Helmet } from 'react-helmet';
import { patterns, masks } from '../../constants/input';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import AddressCard from '../../components/addressCard';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.messageBlock = null;

    this.state = {
      activeTabId: 0,
      isChanged: false,
      isShowPassword: false,
      phone: props.user.phone || '',
      name: props.user.name || '',
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      streets: props.user.streets || [],
      bonuses: props.user.bonus_balance,
      nameError: false,
      phoneError: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !(this.state.name || this.state.phone) &&
      (nextProps.user.name || nextProps.user.phone)
    ) {
      this.setState({
        name: nextProps.user.name || '',
        phone: nextProps.user.phone,
      });
    }
    if (
      nextProps.user.isSaved &&
      nextProps.user.isSaved !== this.props.user.isSaved &&
      this.messageBlock
    ) {
      this.messageBlock.classList.remove('is-hidden');
      setTimeout(() => {
        this.messageBlock.classList.add('is-hidden');
      }, 3000);
    }
  }

  getStreetsArray = streets => {
    const streetArray = [];
    if (streets) {
      streets.map(item => {
        item.streets.map(streetTitle => {
          streetArray.push({
            value: `${item.city}, ${streetTitle}`,
            label: `${item.city}, ${streetTitle}`,
          });
        });
      });
    }
    return streetArray;
  };

  handleFocus = () => {
    this.setState({
      isChanged: true,
    });
  };

  handleFieldChange = (e, stateName) => {
    e.preventDefault();
    e.persist();

    const { target } = e;
    const inputNormalized = target.value.replace(/^\s{1,}/g, '');

    this.setState({
      [`${stateName}Error`]: false,
      [stateName]: inputNormalized,
    });
  };

  validateField = (val, stateName, e) => {
    if (val && patterns[stateName]) {
      if (val && !patterns[stateName].test(val)) {
        this.setState({
          [`${stateName}Error`]: true,
        });
      } else {
        this.setState({
          [`${stateName}Error`]: false,
        });
      }
    } else if (!val) {
      this.setState({
        [`${stateName}Error`]: true,
      });
    } else {
      if (
        e &&
        e.target.dataset.max &&
        parseInt(val) >= parseInt(e.target.dataset.max)
      ) {
        val = e.target.dataset.max;
      }
      this.setState({
        [stateName]: val,
        [`${stateName}Error`]: false,
      });
    }
    return true;
  };

  toggleShowPassword = () => {
    this.setState(prevState => ({
      isShowPassword: !prevState.isShowPassword,
    }));
  };

  changeTabId = newId => {
    this.setState({
      activeTabId: newId,
    });
  };

  resetForm = () => {
    this.setState({
      isChanged: false,
      nameError: false,
      phoneError: false,
      phone: this.props.user.phone,
      name: this.props.user.name,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    });
  };

  savePersonalData = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { state } = this;
    const { props } = this;
    let phoneFormatted;
    if (state.phone[0] === '+') {
      phoneFormatted = state.phone.replace(/[^0-9]/g, '').slice(1);
    } else {
      phoneFormatted = state.phone.replace(/[^0-9]/g, '');
    }
    const data = {
      email: phoneFormatted,
      name: state.name,
    };
    if (state.oldPassword) {
      data.current_password = state.oldPassword;
    }
    if (state.newPassword) {
      data.password = state.newPassword;
    }
    if (state.newPasswordConfirm) {
      data.password_confirmation = state.newPasswordConfirm;
    }
    if (props.user.addresses) {
      data.addresses = props.user.addresses;
    }
    if (!state.nameError && !state.phoneError) {
      this.props.savePersonalData({ user: data });
      this.setState({
        isChanged: false,
      });
    }
  };

  renderPersonalData = () => {
    const { name, phone, bonuses } = this.state;
    const { errors } = this.props.user;

    const phone_masked = phone || '';
    const inputProps = {
      placeholder: '+7 (___) ___-__-__',
      type: 'tel',
      name: 'userPhone',
      autoComplete: 'off',
    };
    return (
      <div className="form_tab-content">
        {bonuses && parseInt(bonuses) > 0 ? (
          <div className="form_row">
            <div className="form_cell">
              <span>Бонусы:&nbsp;доступно&nbsp;</span>
              <span className="bonuses-text">{bonuses}</span>
            </div>
          </div>
        ) : null}
        <div className="form_row">
          <div
            className={`form_cell cell-l ${
              this.state.nameError ? 'is-invalid' : ''
            }`}
          >
            <label>
              <span className="label-text">Имя</span>
              <input
                value={name}
                placeholder="Имя"
                onChange={e => this.handleFieldChange(e, 'name')}
                onBlur={e => this.validateField(e.target.value, 'name')}
                onFocus={this.handleFocus}
                type="text"
              />
            </label>
            <span className="is-invalid-text">Введите ваше имя</span>
          </div>
        </div>

        <div className="form_row">
          <div
            className={`form_cell cell-m ${
              this.state.phoneError ? 'is-invalid' : ''
            }`}
          >
            <label>
              <span className="label-text">Телефон</span>
              <MaskedInput
                {...inputProps}
                value={phone_masked}
                required="required"
                mask={masks.phone}
                className="form-control"
                guide={false}
                onChange={e => this.handleFieldChange(e, 'phone')}
                onBlur={e => this.validateField(e.target.value, 'phone')}
                onFocus={this.handleFocus}
              />
            </label>
            <span className="is-invalid-text">Укажите номер телефона</span>
          </div>
        </div>
        {errors && errors.length > 0 ? (
          <div className="form_row is-invalid">
            <div className="is-invalid-text">{errors[0]}</div>
          </div>
        ) : null}
        <div className="form_row">
          <div className="form_cell cell-m">
            <label>
              <span className="label-text">Ваш пароль</span>
              <input
                id="userPassword"
                name="userPassword"
                type={this.state.isShowPassword ? 'text' : 'password'}
                value={this.state.oldPassword}
                onChange={e => this.handleFieldChange(e, 'oldPassword')}
                onFocus={this.handleFocus}
                placeholder="Ваш пароль"
              />
            </label>
          </div>
        </div>
        <div className="form_row">
          <div className="form_cell cell-m">
            <label>
              <span className="label-text">Ваш новый пароль</span>
              <input
                id="userPasswordNew"
                name="userPasswordNew"
                type={this.state.isShowPassword ? 'text' : 'password'}
                value={this.state.newPassword}
                onChange={e => this.handleFieldChange(e, 'newPassword')}
                onFocus={this.handleFocus}
                placeholder="Новый пароль"
              />
            </label>
          </div>
          <div className="form_cell cell-m">
            <label>
              <span className="label-text">Ваш пароль еще раз</span>
              <input
                id="userPasswordNewNew"
                name="userPasswordNewNew"
                type={this.state.isShowPassword ? 'text' : 'password'}
                value={this.state.newPasswordConfirm}
                onChange={e => this.handleFieldChange(e, 'newPasswordConfirm')}
                onFocus={this.handleFocus}
                placeholder="Новый пароль еще раз"
              />
            </label>
          </div>
          <div className="form_cell cell-m">
            <label className="label-eye">
              <input
                className="label-eye__checkbox"
                checked={this.state.isShowPassword}
                value={this.state.isShowPassword}
                onChange={this.toggleShowPassword}
                type="checkbox"
              />
              <span className="label-eye__icon" />
              <span className="label-eye__text">
                <span className="label-eye__text-show">Показать пароли</span>
                <span className="label-eye__text-hide">Скрыть пароли</span>
              </span>
            </label>
          </div>
        </div>
        {this.state.isChanged && (
          <div className="form_row align-items-center">
            <div className="form_cell">
              <button
                className="btn is-small"
                type="submit"
                onClick={e => this.savePersonalData(e)}
              >
                Сохранить
              </button>
            </div>
            <div className="form_cell">
              <span className="link-add" onClick={this.resetForm}>
                <span className="text">Отменить изменения</span>
              </span>
            </div>
          </div>
        )}
        <span
          className="text saved_message is-hidden"
          ref={elem => {
            this.messageBlock = elem;
          }}
        >
          Изменения сохранены
        </span>
      </div>
    );
  };

  render() {
    const { activeTabId } = this.state;
    const title = 'Кабинет покупателя';
    const streetsArray = this.props.user
      ? this.getStreetsArray(this.props.user.streets)
      : [];
    return (
      <>
        <Helmet>
          <title>{`${title} – BARAШEK`}</title>
          <meta content={`${title} – BARAШEK`} name="title" />
          <meta content={title} property="og:title" />
        </Helmet>
        <div className="container profile">
          <h2 className="h-tac">Кабинет</h2>
          <form className="form" name="profileForm" noValidate="">
            <ul className="tabs">
              <li
                className={`tabs-item${activeTabId === 0 ? ' active' : ''}`}
                onClick={() => this.changeTabId(0)}
              >
                Личные данные
              </li>
              <li
                className={`tabs-item${activeTabId === 1 ? ' active' : ''}`}
                onClick={() => this.changeTabId(1)}
              >
                Адреса
              </li>
            </ul>
            <div className="section">
              {activeTabId === 0 ? (
                this.renderPersonalData()
              ) : (
                <AddressCard {...this.props} streetsArray={streetsArray} />
              )}
            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.rootReducer.profileReducer,
});

const mapDispatchToProps = dispatch => ({
  savePersonalData: data => {
    dispatch({ type: 'SEND_PERSONAL_DATA', payload: data });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
