import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory as createHistory } from 'history';

import createSagaMiddleware from 'redux-saga';
import analyticsMiddleware from './middleware/analyticsMiddleware';
import rootReducer from './modules';

export const history = createHistory();

const sagaMiddleware = createSagaMiddleware({});

const initialState = {};

const enhancers = [];

const middleware = [
  analyticsMiddleware,
  routerMiddleware(history),
  sagaMiddleware,
];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

export default {
  ...createStore(
    rootReducer,
    initialState,
    composedEnhancers,
  ),
  runSaga: sagaMiddleware.run,
};
