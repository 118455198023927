import React, { Component } from 'react';
import Select, { createFilter } from 'react-select';
import confirmService from '../confirm';

class AddressCard extends Component {
  constructor(props) {
    super(props);

    this.addressInput = null;

    this.state = {
      isShowDefaultAddress: !(
        props.user.addresses && props.user.addresses.length > 0
      ),
      isAddressChanged: false,
      addresses: props.user.addresses || [],
      streetsArray: props.streetsArray,
      defaultAddress: {
        address: '',
        address_other: '',
        address_street: '',
        delivery_comment: '',
        name: '',
      },
    };
  }

  addAddress = () => {
    this.setState({
      defaultAddress: {
        address: '',
        address_other: '',
        address_street: '',
        delivery_comment: '',
        name: '',
      },
    });
    this.toggleDefaultAddress();
  };

  isFirstAddress = () => this.state.addresses.length === 0;

  handleFocus = () => {
    this.setState({
      isAddressChanged: true,
    });
  };

  handleAddressChange = (e, id, stateName, newValue) => {
    const { addresses } = this.state;
    if (e) {
      e.preventDefault();
      e.persist();
      addresses[id] = { ...addresses[id], [stateName]: e.target.value };
    } else {
      const newVal =
        newValue && newValue !== '' && newValue.value
          ? newValue.value
          : newValue;
      if (stateName === 'address_street') {
        const aCityStreet =
          newValue && newValue !== '' && newValue.value
            ? newValue.value.split(',')
            : '';
        const address = {};
        address.address_city = aCityStreet ? aCityStreet[0] : '';
        address.address_street = aCityStreet
          ? aCityStreet
              .slice(1)
              .join()
              .trim()
          : '';
        addresses[id] = {
          ...addresses[id],
          address_city: address.address_city,
          address_street: address.address_street,
        };
      } else {
        addresses[id] = {
          ...addresses[id],
          [stateName]: newVal,
        };
      }
    }
    this.setState({
      addresses,
    });
  };

  handleDefaultAddressChange = (e, stateName, newValue) => {
    if (e) {
      e.preventDefault();
      e.persist();
      this.setState({
        defaultAddress: {
          ...this.state.defaultAddress,
          [stateName]: e.target.value,
        },
      });
    } else {
      const newVal =
        newValue && newValue !== '' && newValue.value
          ? newValue.value
          : newValue;
      if (stateName === 'address_street') {
        const aCityStreet =
          newValue && newValue !== '' && newValue.value
            ? newValue.value.split(',')
            : '';
        const address = {};
        address.address_city = aCityStreet ? aCityStreet[0] : '';
        address.address_street = aCityStreet
          ? aCityStreet
              .slice(1)
              .join()
              .trim()
          : '';
        this.setState({
          defaultAddress: {
            ...this.state.defaultAddress,
            address_city: address.address_city,
            address_street: address.address_street,
          },
        });
      } else {
        this.setState({
          defaultAddress: {
            ...this.state.defaultAddress,
            [stateName]: newVal,
          },
        });
      }
    }
  };

  toggleDefaultAddress = () => {
    this.setState(prevState => ({
      isShowDefaultAddress: !prevState.isShowDefaultAddress,
    }));
  };

  removeAddress = async id => {
    const addressToRemove = this.state.addresses[id];
    const removalMessage =
      addressToRemove && addressToRemove.name
        ? addressToRemove.name
        : `${addressToRemove.address_street}...`;
    const isNeedToRemove = await confirmService.show({
      message: `Удалить адрес «${removalMessage}»?`,
      labels: {
        ok: 'Удалить',
        cancel: 'Отмена',
      },
    });
    if (isNeedToRemove) {
      const { addresses } = this.state;
      const newAddresses = [
        ...addresses.slice(0, id),
        ...addresses.slice(id + 1),
      ];
      this.setState({
        isShowDefaultAddress: false,
        isAddressChanged: false,
      });
      this.sendData(newAddresses);
    }
  };

  resetAddress = () => {
    this.setState({
      isAddressChanged: false,
      addresses: this.props.user.addresses,
      isShowDefaultAddress: false,
      defaultAddress: {
        address: '',
        address_other: '',
        address_street: '',
        delivery_comment: '',
        name: '',
      },
    });
  };

  sendData = addresses => {
    const { props } = this;
    const data = {
      email: props.user.email,
      name: props.user.name,
    };
    const filledAddresses = addresses.filter(val => val.address_street !== '');
    data.addresses = filledAddresses;
    this.props.savePersonalData({ user: data });
  };

  savePersonalData = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const { state } = this;
    const { addresses } = state;
    if (state.isShowDefaultAddress || this.isFirstAddress()) {
      addresses.push(state.defaultAddress);
    }
    this.sendData(addresses);
    this.setState({
      isShowDefaultAddress: false,
      isAddressChanged: false,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.isShowDefaultAddress !== this.state.isShowDefaultAddress &&
      this.state.isShowDefaultAddress === true
    ) {
      this.addressInput.focus();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.streetsArray || props.user.addresses) {
      return {
        streetsArray: props.streetsArray || [],
        addresses: props.user.addresses || [],
      };
    }
    return null;
  }

  renderDefaultAddressCard = () => {
    const item = this.state.defaultAddress;
    return (
      <div className="form_row">
        <div className="profile-well">
          <div className="container">
            <div className="form_row">
              <div className="form_cell cell-xl">
                <label>
                  <span className="label-text">Название адреса</span>
                  <input
                    name="userAddressName"
                    defaultValue={item.name}
                    placeholder="Название"
                    required=""
                    type="text"
                    ref={input => {
                      this.addressInput = input;
                    }}
                    onChange={e => this.handleDefaultAddressChange(e, 'name')}
                    onFocus={this.handleFocus}
                  />
                </label>
                <span className="is-invalid-text">Укажите название адреса</span>
              </div>
            </div>
            <div className="form_row">
              <div className="form_cell cell-l">
                <label>
                  <span className="label-text">Улица</span>
                  <Select
                    className="react-select-container"
                    classNamePrefix="select"
                    name="userAddressStreet"
                    required="required"
                    placeholder="Улица"
                    options={this.state.streetsArray}
                    value={this.state.streetsArray.filter(
                      ({ value }) =>
                        value === `${item.address_city}, ${item.address_street}`
                    )}
                    onChange={newValue =>
                      this.handleDefaultAddressChange(
                        '',
                        'address_street',
                        newValue
                      )
                    }
                    onFocus={this.handleFocus}
                    filterOption={createFilter({ ignoreAccents: false })}
                    noOptionsMessage={() => 'адрес не найден'}
                    isSearchable
                    isClearable
                  />
                </label>
                <span className="is-invalid-text">укажите название улицы</span>
              </div>
              <div className="form_cell cell-s">
                <label>
                  <span className="label-text">Дом</span>
                  <input
                    name="userAddressHouse"
                    defaultValue={item.address}
                    placeholder="Дом"
                    required=""
                    type="text"
                    onChange={e =>
                      this.handleDefaultAddressChange(e, 'address')
                    }
                    onFocus={this.handleFocus}
                  />
                </label>
                <span className="is-invalid-text">Укажите номер дома</span>
              </div>
              <div className="form_cell cell-s">
                <label>
                  <span className="label-text">Квартира</span>
                  <input
                    name="userAddressApt"
                    defaultValue={item.address_other}
                    placeholder="Квартира"
                    required=""
                    type="text"
                    onChange={e =>
                      this.handleDefaultAddressChange(e, 'address_other')
                    }
                    onFocus={this.handleFocus}
                  />
                </label>
              </div>
            </div>
            <div className="form_row">
              <div className="form_cell cell-xl">
                <label>
                  <span className="label-text">Курьеру (необязательно)</span>
                  <input
                    name="userAddressInfo"
                    defaultValue={item.delivery_comment}
                    placeholder=""
                    type="text"
                    onChange={e =>
                      this.handleDefaultAddressChange(e, 'delivery_comment')
                    }
                    onFocus={this.handleFocus}
                  />
                </label>
              </div>
            </div>
            {!this.isFirstAddress() && (
              <button
                type="button"
                className="link-remove"
                onClick={() => this.toggleDefaultAddress()}
              >
                Удалить
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderAddressCards = () => {
    const cards = [];
    this.state.addresses.map((item, id) => {
      cards.push(
        <div className="form_row" key={id}>
          <div className="profile-well">
            <div className="container">
              <div className="form_row">
                <div className="form_cell cell-xl">
                  <label>
                    <span className="label-text">Название адреса</span>
                    <input
                      name="userAddressName"
                      defaultValue={item.name}
                      placeholder="Название"
                      required=""
                      type="text"
                      onChange={e => this.handleAddressChange(e, id, 'name')}
                      onFocus={this.handleFocus}
                    />
                  </label>
                  <span className="is-invalid-text">
                    Укажите название адреса
                  </span>
                </div>
              </div>
              <div className="form_row">
                <div className="form_cell cell-l">
                  <label>
                    <span className="label-text">Улица</span>
                    <Select
                      className="react-select-container"
                      classNamePrefix="select"
                      name="userAddressStreet"
                      placeholder="Улица"
                      options={this.state.streetsArray}
                      value={this.state.streetsArray.filter(
                        ({ value }) =>
                          value ===
                          `${item.address_city}, ${item.address_street}`
                      )}
                      getOptionLabel={({ label }) => label}
                      getOptionValue={({ value }) => value}
                      onChange={newValue =>
                        this.handleAddressChange(
                          '',
                          id,
                          'address_street',
                          newValue
                        )
                      }
                      onFocus={this.handleFocus}
                      filterOption={createFilter({ ignoreAccents: false })}
                      noOptionsMessage={() => 'адрес не найден'}
                      isSearchable
                      isClearable
                    />
                  </label>
                  <span className="is-invalid-text">
                    укажите название улицы
                  </span>
                </div>
                <div className="form_cell cell-s">
                  <label>
                    <span className="label-text">Дом</span>
                    <input
                      name="userAddressHouse"
                      value={item.address}
                      placeholder="Дом"
                      required=""
                      type="text"
                      onChange={e => this.handleAddressChange(e, id, 'address')}
                      onFocus={this.handleFocus}
                    />
                  </label>
                  <span className="is-invalid-text">Укажите номер дома</span>
                </div>
                <div className="form_cell cell-s">
                  <label>
                    <span className="label-text">Квартира</span>
                    <input
                      name="userAddressApt"
                      defaultValue={item.address_other}
                      placeholder="Квартира"
                      required=""
                      type="text"
                      onChange={e =>
                        this.handleAddressChange(e, id, 'address_other')
                      }
                      onFocus={this.handleFocus}
                    />
                  </label>
                </div>
              </div>
              <div className="form_row">
                <div className="form_cell cell-xl">
                  <label>
                    <span className="label-text">Курьеру (необязательно)</span>
                    <input
                      name="userAddressInfo"
                      defaultValue={item.delivery_comment}
                      placeholder=""
                      type="text"
                      onChange={e =>
                        this.handleAddressChange(e, id, 'delivery_comment')
                      }
                      onFocus={this.handleFocus}
                    />
                  </label>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="link-remove"
              onClick={() => this.removeAddress(id)}
            >
              <span>Удалить</span>
            </button>
          </div>
        </div>
      );
    });
    return cards;
  };

  render() {
    return (
      <div className="form_tab-content">
        {this.renderAddressCards()}
        {(this.state.isShowDefaultAddress || this.isFirstAddress()) &&
          this.renderDefaultAddressCard()}
        {!this.isFirstAddress() && !this.state.isAddressChanged && (
          // !this.state.isShowDefaultAddress &&
          <div className="form_row">
            <div className="form_cell">
              <span className="link-add" onClick={this.addAddress}>
                <ins className="icon icon_add" />
                <span className="text">Добавить адрес</span>
              </span>
            </div>
          </div>
        )}
        {this.state.isAddressChanged && (
          <div className="form_row align-items-center">
            <div className="form_cell">
              <button
                className="btn is-small"
                onClick={e => this.savePersonalData(e)}
                type="submit"
              >
                Сохранить
              </button>
            </div>
            <div className="form_cell">
              <span className="link-add" onClick={this.resetAddress}>
                <span className="text">Отменить изменения</span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AddressCard;
