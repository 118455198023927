import React, {Component} from 'react';
import './styles.css';

class PayanywayModal extends Component {

    handleFrameData = (e) => {
        console.log(e);
        let detail = e.detail;
        if(detail) {
            switch (detail.result) {
                case 'success':
                    this.props.payanywayResult(true);
                    break;
                case 'cancel':
                case 'fail':
                default:
                    this.props.payanywayResult(false);
                    break;
            }
        }

    }

    componentDidMount() {
        document.body.classList.add('has-modal');

        let wrapper = document.querySelectorAll('.iframe-wrapper')[0];
        wrapper.classList.add('is-loading');
        let iframe = document.getElementById('payanyway');
        iframe.src = this.props.iframeUrl;
        iframe.onload = () => wrapper.classList.remove('is-loading');

        window.document.addEventListener('payanyway', this.handleFrameData);
    };

    componentWillUnmount() {
        document.body.classList.remove('has-modal');

        window.document.removeEventListener("payanyway", this.handleFrameData);
    }

    render() {
        return (
            <div className="modal modal-compact">
                {/* <div
                    className="modal_close"
                    onClick={this.props.handleClose}
                >×</div> */}
                <div className="modal_wrap payanyway_modal_wrap">
                    <div className="payanyway_modal">
                        <div className="payanyway_modal_close" onClick={this.props.handleClose}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.266997 0.266997C-0.0889989 0.62277 -0.0889989 1.19993 0.266997 1.5557L6.71141 7.99989L0.266997 14.4441C-0.0889989 14.8001 -0.0889989 15.3772 0.266997 15.733C0.622992 16.089 1.20015 16.089 1.5557 15.733L8.00011 9.28859L14.4445 15.733C14.8001 16.089 15.3772 16.089 15.7332 15.733C16.0892 15.3772 16.0892 14.8001 15.7332 14.4441L9.28882 7.99989L15.7332 1.5557C16.0892 1.19993 16.0892 0.62277 15.7332 0.266997C15.3772 -0.0889989 14.8001 -0.0889989 14.4445 0.266997L8.00011 6.71118L1.5557 0.266997C1.20015 -0.0889989 0.622992 -0.0889989 0.266997 0.266997Z"></path>
                            </svg>
                        </div>

                        <div className="iframe-wrapper">
                            <iframe id="payanyway" src="" frameBorder="0">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default PayanywayModal




