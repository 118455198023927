import React, { useEffect } from 'react';
import { useToasts } from './notification-core';

import './styles.css';

export function Notifications({ list }) {
  const { addToast } = useToasts();
  useEffect(() => {
    addToast(list);
  }, [list]);

  return null;
}