import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import ProductGroup from '../../components/productGroup';
import { history } from '../../store';

const Home = ({ basket, products, addToBasket, subFromBasket }) => {
  const renderProductGroup = () =>
    products.map((item, index) => (
      <ProductGroup
        key={`${index}_${item.name}`}
        id={`product_${item.name}`}
        categories={item.categories}
        name={item.name}
        invisible={item.invisible}
        basket={basket}
        addToBasket={addToBasket}
        removeFromBasket={subFromBasket}
      />
    ));

  const title =
    'Доставка еды: шашлык, плов. Заказать доставку бесплатно Йошкар-Ола – BARAШEK';
  const description =
    'Заказывайте доставку на дом блюда восточной кухни: шашлык, плов, самса, лагман, люля-кебаб и др. Бесплатная доставка от 400 руб.';

  useEffect(() => {
    if (history.location && history.location.hash) {
      const URLhash = decodeURI(history.location.hash).replace(/#/g, '');
      const elSticky = document.querySelector('#header');
      const headerHeight = elSticky ? elSticky.clientHeight : 0;
      scroller.scrollTo(URLhash, {
        duration: 800,
        delay: 0,
        smooth: 'linear',
        offset: -headerHeight,
        spy: true,
        hashSpy: true,
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta content={title} name="title" />
        <meta content={title} property="og:title" />
        <meta content={description} name="description" />
      </Helmet>
      <h1 className="h-tac">Меню</h1>
      {products && renderProductGroup()}
    </>
  );
};

const mapStateToProps = state => ({
  products: state.rootReducer.productsReducer.products,
  basket: state.rootReducer.basketReducer.basket,
});

const mapDispatchToProps = dispatch => ({
  addToBasket: data => {
    dispatch({ type: 'ADD_TO_BASKET', payload: data });
  },
  subFromBasket: data => {
    dispatch({ type: 'SUB_FROM_BASKET', payload: data });
  },
});

Home.propTypes = {
  products: PropTypes.array,
  basket: PropTypes.shape({}),
  addToBasket: PropTypes.func.isRequired,
  subFromBasket: PropTypes.func.isRequired,
};

Home.defaultProps = {
  products: [],
  basket: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
