import React from 'react';
import './styles.css';

const Ctx = React.createContext();

// Styled Components
// ==============================

const ToastContainer = props => <div className="b-messages" {...props} />;

const Toast = ({ children, onDismiss, ...rest }) => (
  <div className={`b-message is-${rest.type}`} onClick={onDismiss}>
    <div className="b-message-text">{children}</div>
    <span className="close">&times;</span>
  </div>
);

// Provider
// ==============================

let toastCount = 0;

export function ToastProvider({ children }) {
  const [toasts, setToasts] = React.useState([]);

  const addToast = content => {
    const atoasts = content.map(item => {
      const id = item.data.message_id ? item.data.message_id : toastCount++;
      const toast = {
        content: item.data.message_data,
        id,
        type: item.data.message_type || 'notification',
      };
      return toast;
    });

    // filter toasts with duplicate 'id'
    const aUniqueToasts = atoasts
      .map(e => e.id)
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => atoasts[e])
      .map(e => atoasts[e]);

    setToasts(aUniqueToasts);
  };
  const remove = id => {
    const newToasts = toasts.filter(t => t.id !== id);
    setToasts(newToasts);
  };
  const onDismiss = id => () => remove(id);

  return (
    <Ctx.Provider value={{ addToast, remove }}>
      <ToastContainer>
        {toasts.map(({ content, id, ...rest }) => (
          <Toast key={id} onDismiss={onDismiss(id)} {...rest}>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Toast>
        ))}
      </ToastContainer>
      {children}
    </Ctx.Provider>
  );
}

// Consumer
// ==============================

export const useToasts = () => React.useContext(Ctx);
