/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AuthForm from '../forms/authform';
import './styles.css';

const Modal = (props) => {
  const [state, setState] = useState({
    open: false,
    modalType: props.modalType || 'signin',
  });

  const handleClose = (e) => {
    props.handleModal(e, false);
  };

  const renderTitle = () => {
    switch (state.modalType) {
      case 'signin':
        return (
          <h2>Вход на сайт</h2>
        );
      case 'signup':
        return (
          <h2>Регистрация</h2>
        );
      case 'forgot':
        return (
          <h2>Восстановление пароля</h2>
        );
      default:
        return null;
    }
  };

  const goTo = (e, type) => {
    if (e) {
      e.preventDefault();
    }
    setState({
      ...state,
      modalType: type,
    });
  };

  const renderModalFooter = () => {
    let text = '';
    let link;
    switch (state.modalType) {
      case 'signup':
      case 'forgot':
        text = 'Уже зарегистрированы? ';
        link = (
          <a
            href="#"
            onClick={e => goTo(e, 'signin')}
          >Войдите
          </a>
        );
        break;
      case 'signin':
        text = 'Первый раз на сайте? ';
        link = (
          <a
            href="#"
            onClick={e => goTo(e, 'signup')}
          >Зарегистрируйтесь
          </a>
        );
        break;
      default:
        break;
    }

    return (
      <div className="modal_section">
        <span className="auth_modal-switch">
          {text}
          {link}
        </span>
      </div>
    );
  };

  return (
    <div className="modal">
      <div className="modal_wrap">
        <button
          type="button"
          className="modal_close"
          onClick={e => handleClose(e)}
        >&times;
        </button>
        <div className="auth_modal modal_auth">
          <div className="auth_modal-tab">
            <div className="modal_section">
              {renderTitle()}
              <AuthForm
                className="form"
                formType={state.modalType}
                handleModal={props.handleModal}
                handleGoTo={type => goTo(undefined, type)}
              />
            </div>
            {renderModalFooter()}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  modalType: PropTypes.string,
  handleModal: PropTypes.func,
};

Modal.defaultProps = {
  modalType: 'signin',
  handleModal: () => { },
};

export default Modal;
