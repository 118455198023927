/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './containers/app';
import rootSaga from './sagas';
import './index.css';

const target = document.querySelector('#root');
store.runSaga(rootSaga);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  target
);
