/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import { history } from '../../store';
import WrapRoute from '../../components/route/wrapRoute';
import PrivateRoute from '../../components/route/privateRoute';

import Home from '../home';
import Payment from '../payment';
import Contacts from '../contacts';
import Kazanplova from '../kazanplova';
import OrderComplete from '../order_complete';
import NotFound from '../notFound';
import Profile from '../profile';
import Basket from '../basket';
import Terms from '../terms';
import Login from '../login';
import Register from '../register';
import Agreements from '../agreements';

const App = () => (
  <Router history={history}>
    <Switch>
      <WrapRoute exact path="/" component={Home} />
      <WrapRoute path="/payment" component={Payment} />
      <WrapRoute path="/contacts" component={Contacts} />
      <WrapRoute path="/kazanplova" component={Kazanplova} />
      <WrapRoute path="/basket" component={Basket} />
      <WrapRoute path="/order_complete" component={OrderComplete} />
      <WrapRoute path="/terms" component={Terms} />
      <WrapRoute path="/register" component={Register} />
      <WrapRoute path="/login" component={Login} />
      <WrapRoute path="/agreements" component={Agreements} />
      <PrivateRoute exact path="/profile" component={Profile} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default App;
