import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../context/providers';
// import { patterns } from '../../constants/input';
import * as types from '../../shapes/data';
import { isEmpty } from '../../utils/find';

const RenderPayment = props => {
  // get settings
  const { user, settings, basket } = useContext(Context);

  // state
  const [state, setState] = useState({
    paymentId: props.paymentId || 'cash',
    isBonusesUsed: false,
    bonusesToUse: props.bonus || 0,
    isShowPromo: props.isShowPromo || false,
    promoCodeName: props.promoCodeName || '',
    // isPromoError: props.promoCodeName !== '' && props.promo && props.promo.promo_code_invalid ? true : false,
    isPromoError: !!(props.promo && props.promo.promo_code_invalid),
  });

  const handleFieldChange = (e, stateName) => {
    e.preventDefault();
    e.persist();

    const { target } = e;
    const inputNormalized = target.value.replace(/\D/g, '');

    setState({
      ...state,
      [`${stateName}Error`]: false,
      [stateName]: inputNormalized,
    });
  };

  const handleRadioChoose = (id, stateName) => {
    setState({
      ...state,
      [stateName]: id,
    });
  };

  const toggleField = fieldName => {
    setState({
      ...state,
      [fieldName]: !state[fieldName],
    });
  };

  const rendermaxBonusAllowed = (min, max1, max2) => {
    switch (true) {
      case min === 0:
        return min;
      case min > 0 && max1 >= max2:
        return max2;
      case min > 0 && max1 < max2:
        return max1;
      default:
        return min;
    }
  };

  const handlePromo = e => {
    e.preventDefault();
    const promoFixed = e.target.value.replace(/[^0-9a-zA-Zа-яА-ЯеЁ]/g, '');
    setState({
      ...state,
      promoCodeName: promoFixed,
    });
  };

  // update payment props from props
  useEffect(() => {
    // props.promo received, apply
    if (!isEmpty(props.promo) && props.isShowPromo) {
      setState({
        ...state,
        isShowPromo: props.promo ? !!props.promo.promocode : false,
        promoCodeName: props.promoCodeName,
        isPromoError: props.promo.promo_code_invalid,
      });
    }
    // props.promo is empty, reset and hide
    if (isEmpty(props.promo) && props.isShowPromo) {
      setState({
        ...state,
        isShowPromo: false,
      });
    }
  }, [props.promo]);

  // update payment type from props
  useEffect(() => {
    if (props.addressId && user.account_type !== 'operator') {
      setState({
        ...state,
        paymentId: 'card',
      });
    }
  }, [props.addressId]);

  // clear bonusToUse on isBonusesUsed toggle
  useEffect(() => {
    if (!state.isBonusesUsed) {
      setState({
        ...state,
        bonusesToUse: 0,
      });
    } else if (user && user.bonus_balance > 0) {
      const bonusValue = rendermaxBonusAllowed(
        user.bonus_balance,
        user.bonus_balance,
        parseInt(basket.sum) < parseInt(props.settings.shipment_threshold)
          ? parseInt(basket.sum + props.settings.shipment_cost)
          : Math.ceil(basket.sum)
      );
      props.applyBonus(bonusValue);
      setState({
        ...state,
        bonusesToUse: bonusValue,
      });
    }
  }, [state.isBonusesUsed]);

  // apply bonus
  useEffect(() => {
    const bonusValue = state.bonusesToUse
      ? state.bonusesToUse
          .toString()
          .replace(/^[0]{1,}|\D/g, '')
          .trim()
      : 0;
    props.applyBonus(bonusValue);
  }, [state.bonusesToUse]);

  // recount bonus on basket.sum change
  useEffect(() => {
    if (user && user.bonus_balance > 0) {
      const bonusValue = rendermaxBonusAllowed(
        user.bonus_balance,
        user.bonus_balance,
        parseInt(basket.sum) < parseInt(props.settings.shipment_threshold)
          ? parseInt(basket.sum + props.settings.shipment_cost)
          : Math.ceil(basket.sum)
      );
      if (bonusValue < state.bonusesToUse) {
        props.applyBonus(bonusValue);
        setState({
          ...state,
          bonusesToUse: bonusValue,
        });
      }
    }
  }, [basket.sum]);

  // clear promoCodeName on isShowPromo toggle
  useEffect(() => {
    if (!state.isShowPromo || state.promoCodeName === '') {
      setState({
        ...state,
        promoCodeName: '',
        isPromoError: false,
      });
      props.handleCheckPromo(null, true);
    }
  }, [state.isShowPromo]);

  useEffect(() => {
    // update parent state with local state
    props.handleParentUpdate({
      paymentId: state.paymentId,
      promoCodeName: state.promoCodeName,
      // isShowPromo: state.isShowPromo,
      bonusesToUse: state.bonusesToUse,
    });
  }, [state.paymentId, state.bonusesToUse, state.promoCodeName]);

  return (
    <div className="orderform_content-col orderform_content-col-payment">
      <h4 className="content-subtitle">Оплата</h4>
      {user ? (
        <div
          className={`form_row ${user && user.paymentError ? ' is-invalid' : ''}`}
        >
          {/* card */}
          {user.account_type !== 'operator' && (
            <div className="form_cell col-12">
              <label className="radio">
                <input
                  name="payment"
                  type="radio"
                  value="card"
                  checked={state.paymentId === 'card'}
                  onChange={() => handleRadioChoose('card', 'paymentId')}
                />
                <span>Банковская карта</span>
              </label>
            </div>
          )}
          {/* cash */}
          {(settings.allow_payment_to_courier || state.paymentId === 'cash') && (
            <div className="form_cell col-12">
              <label className="radio">
                <input
                  name="payment"
                  type="radio"
                  value="cash"
                  checked={state.paymentId === 'cash'}
                  disabled={props.addressId === 'self' && (!user || user.account_type !== 'operator')}
                  onChange={() => handleRadioChoose('cash', 'paymentId')}
                />
                <span>Наличными или картой курьеру</span>
              </label>
              <span className="is-invalid-text">
                Отправка сообщений на данный номер заблокирована
              </span>
            </div>
          )}
          {/* use promo */}
          <div className="form_cell col-12">
            <label className="checkbox">
              <input
                name="useBonuses"
                type="checkbox"
                value="1"
                checked={state.isShowPromo}
                onChange={() => toggleField('isShowPromo')}
              />
              <span>Использовать промокод</span>
            </label>
            {state.isShowPromo && (
              <div className={`promocode${state.isPromoError ? ' is-invalid' : ''}`}>
                <span className="inline-input">
                  <div className="input-group">
                    <input
                      className="form-control promocode-input"
                      id="promoCode"
                      name="promoCode"
                      type="text"
                      // value={state.promoCodeName}
                      defaultValue={state.promoCodeName}
                      onChange={handlePromo}
                    />
                    <div className="input-group-append">
                      <input
                        className="promocode-btn-apply btn"
                        onClick={props.handleCheckPromo}
                        type="button"
                        value="Проверить код"
                      />
                    </div>
                  </div>
                  <div className="is-invalid-text">Промокод не существует</div>
                </span>
              </div>
            )}
          </div>
          {/* use bonuses */}
          {user.account_type !== 'operator' && user.bonus_balance > 0 && (
            <div className="form_cell col-12">
              <label className="checkbox">
                <input
                  name="useBonuses"
                  type="checkbox"
                  value="1"
                  checked={state.isBonusesUsed}
                  onChange={() => toggleField('isBonusesUsed')}
                />
                <span>Использовать бонусы (доступно {user.bonus_balance})</span>
              </label>
              {state.isBonusesUsed && (
                <span className="inline-input">
                  <input
                    className="bonuses-input"
                    name="bonusesToUse"
                    type="text"
                    step="1"
                    min="0"
                    max={user.bonus_balance}
                    data-max={rendermaxBonusAllowed(
                      user.bonus_balance,
                      user.bonus_balance,
                      parseInt(basket.sum) <
                        parseInt(settings.shipment_threshold)
                        ? parseInt(basket.sum + settings.shipment_cost)
                        : Math.ceil(basket.sum)
                    )}
                    value={state.bonusesToUse}
                    onChange={e => handleFieldChange(e, 'bonusesToUse')}
                  />
                </span>
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

RenderPayment.propTypes = {
  handleParentUpdate: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  applyBonus: PropTypes.func,
  user: PropTypes.shape(types.User),
  basket: PropTypes.shape(types.Basket),
  settings: PropTypes.shape(types.Settings),
};

RenderPayment.defaultProps = {
  user: {},
  basket: {},
  settings: {},
};

export default RenderPayment;
