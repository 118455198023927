/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { render } from 'react-dom';
import './styles.css';

let resolve;

const defaultProps = {
  title: 'Подтверждение',
  message: 'Вы уверены?',
};

class Confirm extends Component {
  static create = (props = {}) => {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(<Confirm createConfirmProps={props} />, containerElement);
  };

  state = {
    isOpen: false,
    showConfirmProps: {},
    // resolved: true,
  };

  handleCancel = () => {
    this.setState({
      isOpen: false,
    });
    resolve(false);
  };

  handleConfirm = () => {
    this.setState({
      isOpen: false,
    });
    resolve(true);
  };

  show = (props = {}) => {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({
      isOpen: true,
      showConfirmProps,
    });
    return new Promise(res => {
      resolve = res;
    });
  };

  renderModal = props => {
    const { message, labels, title } = props;
    return (
      <div className="confirm is-active">
        <div className="confirm-background" />
        <div className="confirm-card">
          <button
            type="button"
            className="confirm-close"
            aria-label="close"
            onClick={() => this.handleCancel()}
          >
            &times;
          </button>
          <header className="confirm-card-head">
            <p className="confirm-card-title">{title || defaultProps.title}</p>
          </header>
          <section className="confirm-card-body">
            <p>{message || defaultProps.message}</p>
          </section>
          <footer className="confirm-card-foot">
            <button
              type="button"
              className="btn is-small"
              onClick={() => this.handleConfirm()}
            >
              {labels.ok || 'OK'}
            </button>
            <button
              type="button"
              className="btn is-small btn-secondary"
              onClick={() => this.handleCancel()}
            >
              {labels.cancel || 'Отмена'}
            </button>
          </footer>
        </div>
      </div>
    );
  };

  render = () => {
    const { isOpen, showConfirmProps } = this.state;
    return isOpen ? this.renderModal(showConfirmProps) : null;
  };
}

export default Confirm;
