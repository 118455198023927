import React from 'react';
import ProductCard from '../productCard';

const ProductGroup = ({
  id,
  categories,
  name,
  invisible,
  basket,
  addToBasket,
  removeFromBasket,
}) => {
  const renderProducts = () =>
    categories.map((item, index) => (
      <ProductCard
        key={`${index}_${item.name}`}
        product={item}
        addToBasket={addToBasket}
        removeFromBasket={removeFromBasket}
        basket={basket}
      />
    ));

  return (
    <div className="container products">
      {!invisible && (
        <div className="row">
          <div className="col" name={id} id={id}>
            <h4 className="category-title">{name}</h4>
            <div className="card-deck">{renderProducts()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductGroup;
