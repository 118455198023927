/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import usePrevious from '../hooks/usePrevious';

const ScrollToTop = ({ children }) => {
  const prevLocation = usePrevious(window.location.pathname);

  useEffect(() => {
    if (
      window.location &&
      prevLocation &&
      window.location.pathname !== prevLocation
    ) {
      window.scrollTo(0, 0);
    }
  }, [window.location.pathname]);

  return children;
};

export default ScrollToTop;
