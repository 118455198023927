import { patterns } from '../../constants/input';

export const readTerms = (e, props) => {
  if (props && props.handleModal) {
    props.handleModal('', false);
  }
};

export const goTo = (e, type, props, handleSetState) => {
  if (e) {
    e.preventDefault();
  }
  props.setLoginError({ isError: false, errors: '' });
  handleSetState({
    password: '',
    passwordError: false,
    formType: type,
  });
  if (props.handleGoTo) { // handle parent's goTo method (if any)
    props.handleGoTo(type);
  }
};

export const validateField = (val, stateName, e, handleSetState) => {
  if (val && patterns[stateName]) {
    if (val && !patterns[stateName].test(val)) {
      const data = {
        [`${stateName}Error`]: true,
      };
      if (handleSetState) handleSetState(data);
      return data;
    }
    const data = {
      [`${stateName}Error`]: false,
    };
    if (handleSetState) handleSetState(data);
    return data;
  }

  if (!val || val === '') {
    const data = {
      [`${stateName}Error`]: true,
    };
    if (handleSetState) handleSetState(data);
    return data;
  }

  let newVal;
  if (e && e.target.dataset.max && (parseInt(val, 10) >= parseInt(e.target.dataset.max, 10))) {
    newVal = e.target.dataset.max;
  }
  const data = {
    [stateName]: newVal || val,
    [`${stateName}Error`]: false,
  };
  if (handleSetState) handleSetState(data);
  return data;
};

export const handleFieldChange = (e, stateName, handleSetState) => {
  e.preventDefault();
  e.persist();

  const { target } = e;
  const inputNormalized = target.value.replace(/^\s{1,}/g, '');

  handleSetState({
    [`${stateName}Error`]: false,
    [stateName]: inputNormalized,
  });
};

export const handleFormSubmit = (e, type, props, handleSetState, state) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  props.setLoginError({ isError: false, errors: '' });

  const body = { user: {} };
  body.user.email = state.phone.replace(/[^0-9]/g, '').slice(1);

  const required = document.querySelectorAll(`form[name=${type}Form] input.form-control[required]`);

  const requiredList = [];
  let stateErrors = {};
  [...required].forEach((el) => {
    const fieldName = el.getAttribute('name');
    const fieldValue = el.value;
    requiredList.push(fieldName);
    body.user[fieldName] = fieldValue;
    stateErrors = { ...validateField(el.value, fieldName, undefined), ...stateErrors };
  });

  if (stateErrors) {
    handleSetState(stateErrors);
  }

  const isRequiredValid = requiredList.map((item) => {
    const result = !!((state[item] && !state[`${item}Error`]));
    return result;
  });

  // proceed if all required fields have values and do not have errors
  if (isRequiredValid.every(val => val === true)) {
    switch (type) {
      case 'signin':
        props.signIn(body);
        break;
      case 'signup':
        props.signUp(body);
        break;
      case 'forgot':
        props.forgotPassword(body);
        break;
      default:
        break;
    }
  }
};

export default {
  readTerms,
  goTo,
  validateField,
  handleFieldChange,
  handleFormSubmit,
};
