const LABELS = {
    NIGHTS: ['ночь', 'ночи', 'ночей'],
  };
  
  class declOfNum {
    static nights(number) {
      return this.format(number, LABELS.NIGHTS);
    }
  
    static format = (number, titles) => {
      const cases = [2, 0, 1, 1, 1, 2];
      return ` ${number} ${titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]}`;
    }
  }
  
  export default declOfNum;
  