/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';
import { Context } from '../../context/providers';

import RenderContacts from './renderContacts';
import RenderAddress from './renderAddress';
import RenderTime from './renderTime';
import RenderPayment from './renderPayment';
import RenderBuyInfo from './renderBuyInfo';

const ShippingCard = props => {
  // get settings
  const { user, settings, basket } = useContext(Context);

  // Mounted
  useEffect(() => {
    props.fetchTime();
  }, []);

  // set make order error message
  useEffect(() => {
    if (props.errorText) {
      props.clearNotification('make_order_error');
      props.sendNotification({
        data: {
          message_data: `Ваш заказ не отправлен. ${props.errorText}`,
          message_type: 'error',
          message_id: 'make_order_error',
        },
      });
    }
  }, [props.errorText]);

  return user ? (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="form orderform" name="orderForm" noValidate="">
            <h3 className="h-subtitle">Информация для доставки</h3>
            <div className="orderform_content row">
              <div className="col">
                <RenderContacts
                  formErrors={props.formErrors}
                  handleParentUpdate={props.handleParentUpdate}
                />
              </div>
            </div>
            <div className="orderform_content row">
              <div className="col">
                <RenderAddress
                  formErrors={props.formErrors}
                  handleParentUpdate={props.handleParentUpdate}
                />
              </div>
            </div>
            <div className="orderform_content row">
              <div className="col">
                <RenderTime
                  settings={settings}
                  fetchTime={props.fetchTime}
                  clearNotification={props.clearNotification}
                  sendNotification={props.sendNotification}
                  handleParentUpdate={props.handleParentUpdate}
                />
              </div>
            </div>
            <div className="orderform_content row">
              <div className="col">
                <RenderPayment
                  addressId={props.addressId}
                  isShowPromo={props.isShowPromo}
                  promo={props.promo}
                  promoCodeName={props.promoCodeName}
                  handlePromo={props.handlePromo}
                  handleCheckPromo={props.handleCheckPromo}
                  bonus={props.bonus}
                  applyBonus={props.applyBonus}
                  handleParentUpdate={props.handleParentUpdate}
                />
              </div>
            </div>
            <div className="orderform_footer">
              <RenderBuyInfo
                withSubmit
                basket={basket}
                promo={props.promo}
                bonus={props.bonus}
                isSending={props.isSending}
                isNoop={props.isNoop}
                handleSubmitData={props.handleSubmitData}
                errorText={props.errorText}
                addressId={props.addressId}
                settings={settings}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

ShippingCard.propTypes = {
  fetchTime: PropTypes.func,
  handlePromo: PropTypes.func,
  handleCheckPromo: PropTypes.func,
  handleParentUpdate: PropTypes.func,
  handleSubmitData: PropTypes.func,
  applyBonus: PropTypes.func,
  clearNotification: PropTypes.func,
  sendNotification: PropTypes.func,
  isSending: PropTypes.func,
  formErrors: PropTypes.shape({}),
  promo: PropTypes.shape({}),
  bonus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorText: PropTypes.string,
  promoCodeName: PropTypes.string,
  isShowPromo: PropTypes.bool,
  isNoop: PropTypes.bool,
};

ShippingCard.defaultProps = {
  formErrors: {},
};

export default ShippingCard;
