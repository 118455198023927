import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './styles.css';

const NotFound = () => {
  const title = 'BARAШEK – бесплатная доставка плова и самсы в Йошкар-Оле.';
  return (
    <div className="lyt-view page_not_found">
      <Helmet>
        <title>{title}</title>
        <meta content={title} name="title" />
        <meta content={title} property="og:title" />
      </Helmet>
      <div className="lyt-content">
        <h2 className="h-tac">Страница не найдена!</h2>
        <p>
          <Link to="/">Вернуться на главную</Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
