/* eslint-disable no-loop-func */
// check if o is an Object
export const isObject = o => o !== null && typeof o === 'object' && Array.isArray(o) === false;

// check if o is an Array
export const isArray = a =>
  (a !== null && a.constructor === Array) ||
  a instanceof Array ||
  Array.isArray(a) === true;

export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

// find object by key = val in data array
export const findByKeyVal = (data, key, val) => {
  let res;

  for (let i = 0; i < data.length; i++) {
    // parse array items
    if (isObject(data[i])) {
      // parse item's props
      for (let index = 0; index < Object.keys(data[i]).length; index++) {
        const subKey = Object.keys(data[i])[index];
        // parse prop's arrays/objects
        if (isArray(data[i][subKey]) && data[i][subKey].length > 0) {
          const result = findByKeyVal(data[i][subKey], key, val);
          if (result && result[key]) {
            res = result;
            return res;
          }
        } else if (data[i][subKey] === val) {
          return data[i];
        }
      }
    }
  }

  return res;
};
